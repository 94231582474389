import React from 'react';
import {
  Form, Input, Icon, Button, DatePicker, TimePicker, Select, Row, Col, Divider, message, Tooltip, Modal
} from 'antd';
import rest from '../common/network';
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image';
import { AT_EMAIL } from '../common/constants';
import { rejects } from 'assert';


let form_prop = null

const handleFormSuccess = (props, response) => {
	props.toggleLoader(false);
	
	if(response.data.success === true) {
		// props.resetFlightForm(false);
		
		props.flight_form.flight_id = response.data.flight_id
		message.success(response.data.message)
	}
	else {
		message.error(response.data.message);
	}
}

const Option = Select.Option;



const handleOnSubmit = (props, callBack) => {
	form_prop.validateFields((err, values) => {
		if(!err) {
            const date_from = values["date-from"];
            const date_to = values["date-to"];
            const time_from = values["time-from"];
			const time_to = values["time-to"];
			// let formData = new FormData()
            let data = {
                flight_name: values["flight-name"],
                date_from: `${date_from.date()}-${date_from.month()+1}-${date_from.year()}`,
                date_to: `${date_to.date()}-${date_to.month()+1}-${date_to.year()}`,
                max_altitude: `${values["max-altitude"]}`,
                max_range: `${values["max-range"]}`,
                pilot: values["primary-pilot"],
                drone: values["drone"],
                duration: values["duration"],
                time_from: `${time_from.hours()}:${time_from.minutes()}:${time_from.seconds()}`,
                time_to: `${time_to.hours()}:${time_to.minutes()}:${time_to.seconds()}`,
			}
			
			// formData.append('flight_name', values["flight-name"]);
			// formData.append('date_from', `${date_from.date()}-${date_from.month()+1}-${date_from.year()}`);
			// formData.append('date_to', `${date_to.date()}-${date_to.month()+1}-${date_to.year()}`);
			// formData.append('max_altitude', `${values["max-altitude"]}`);
			// formData.append('max_range', `${values["max-range"]}`);
			// formData.append('pilot', values["primary-pilot"]);
			// formData.append('drone', values["drone"]);
			// formData.append('duration', values["duration"]);
			// formData.append('time_from', `${time_from.hours()}:${time_from.minutes()}:${time_from.seconds()}`);
			// formData.append('time_to', `${time_to.hours()}:${time_to.minutes()}:${time_to.seconds()}`);
			
			if(props.flight_form.gps_dms === "") {
                message.error("Choose a location on map to create flight.");
            }
            else {
				const timer = setInterval(() => {
					if(props.flight_form.elevation !== "" && props.flight_form.bearing !== "" && props.flight_form.distance !== "" && props.flight_form.airport !== "") {
						
						props.toggleLoader(true, "Saving flight form");
						clearInterval(timer);
						data.gps = props.flight_form.gps;
						data.gps_dms = props.flight_form.gps_dms;
						data.elevation = props.flight_form.elevation;
						data.bearing = props.flight_form.bearing;
						data.distance = props.flight_form.distance;
						data.airport = props.flight_form.airport;
						// formData.append('gps', props.flight_form.gps);
						// formData.append('gps_dms', props.flight_form.gps_dms);
						// formData.append('elevation', props.flight_form.elevation);
						// formData.append('bearing', props.flight_form.bearing);
						// formData.append('distance', props.flight_form.distance);
						// formData.append('airport', props.flight_form.airport);
						
						callBack(props, data);
					}
				}, 500);
            }
        }
	});
}

const handleSaveFlight = (props, data) => {
	const headers = {};
	if(props.flight_form.flight_id === null) {
		props.toggleLoader(true, "Saving your flight.");
		rest({
			method: 'post',
			endpoint: `/flight/add`,
			headers: headers,
			data: data,
			response_type: "",
			success: handleFormSuccess,
			props: props,
			error: props.handleRequestError
		});
	}
	else {
		props.toggleLoader(true, "Updating your flight.");
		data["flight_id"] = props.flight_form.flight_id;
		rest({
			method: 'post',
			endpoint: `/flight/edit`,
			headers: headers,
			data: data,
			response_type: "",
			success: handleFormSuccess,
			props: props,
			error: props.handleRequestError
		});
	}
}

// const genPilots = (items) => {
// 	const sim = items.map((pilot) => {
// 					return `<Select.Option key=${pilot.key} value='${pilot.key}'>${pilot.pilot_name}</Select.Option>`
// 				});
// 	// return sim;
// }

const getAirTrafficList = (city) => {
	let moreref = React.createRef();
	const non_matched_keys = []
	const matched_keys = []
	Object.keys(AT_EMAIL).map((key) => {
		if(key.includes(city.toLowerCase()))
			matched_keys.push(key);
		else
			non_matched_keys.push(key);
	});
	
	let primary = null;
	let secondary = [];
	if(matched_keys.length > 0) {
		city = matched_keys[0];
		primary = (<p>{AT_EMAIL[city][0]} <a href={`mailto:${AT_EMAIL[city][1]}?subject=SUA Flight in Controlled Airspace Application Form`}>{AT_EMAIL[city][1]}</a></p>);
	}
	non_matched_keys.map(function(city, key) {
		secondary.push(<p key={key}>{AT_EMAIL[city][0]} <a href={`mailto:${AT_EMAIL[city][1]}?subject=SUA Flight in Controlled Airspace Application Form`}>{AT_EMAIL[city][1]}</a></p>);
	})
	if(primary !== null) {
		return(
				<React.Fragment>
					
					{primary}
					
					<a 
					className="moretag" 
					onClick={
						(e) => {
							if(moreref.current.className === "more-hide") {
								e.target.innerText="Less.."
								moreref.current.className="more-show"
							}
							else {
								e.target.innerText="More.."
								moreref.current.className="more-hide"
							}
						}
					} 
					>More...
					</a>
					
					<div ref={moreref} className="more-hide" >
					{secondary}
					</div>
				
				</React.Fragment>
			)
	}
	else
		return secondary;

	// if(city !== "" && city in AT_EMAIL) {
	// 	return (<p>{AT_EMAIL[city][0]} <a href={`mailto:${AT_EMAIL[city][1]}?Subject=SUA Flight in Controlled Airspace Application Form`}>{AT_EMAIL[city][1]}</a></p>);
	// }
	// else {
	// 	let list = [];
	// 	for(const key in AT_EMAIL) {
	// 		list.push(<p key={key} >{AT_EMAIL[key][0]} <a href={`mailto:${AT_EMAIL[key][1]}?Subject=SUA Flight in Controlled Airspace Application Form`}>{AT_EMAIL[key][1]}</a></p>);
	// 	}
	// 	return list;
	// }
}

const form = (props) => {
	const { getFieldDecorator } = props.form;
	form_prop = props.form
	
	const inlineLabelLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
	};

	return (
		<React.Fragment>
        	<Form 
        		id="flight" 
        		className="flight-form" 
        		layout="horizontal"
        	>
        		<Row>
        		<Col xs={{span: 22}} className="flight-name-container" >
                    <Form.Item  hasFeedback >
                        {getFieldDecorator("flight-name", {
                            rules: [{
                                    required: "required",
                                    message: "Name is required."
                                }]
                        })(
						<Input onChange={(e) => {props.handleFlightFormChange("flight_name", e.target.value)}} placeholder="Name of flight" disabled={props.flight_form.is_disabled} 
							   onMouseEnter={(e) => {
								   if (props.flight_form.is_disabled){
									message.info('Please log in to unlock these features, it’s free!');
								   }
								}}/>
                        )}
                    </Form.Item>
                </Col>
                <Col xs={{span: 2}}>
					<Tooltip placement="topLeft" title="Enter a flight name for your own records" arrowPointAtCenter>
						<Icon className="info-circle" type="info-circle" theme="twoTone" />
					</Tooltip>
                </Col>

				<Col xs={{span: 22}} lg={{span:10}} className="date-from-container" >
                    <Form.Item >
                        {getFieldDecorator("date-from", {
                            rules: [{
                                    required: "required",
                                    message: "Date is required.",
                                }]
                        })(
                        <DatePicker onChange={
							(date, dateString) => {
								props.handleFlightFormChange("date_from", date);
							}
						}
						onMouseEnter={(e) => {
								   if (props.flight_form.is_disabled){
									message.info('Please log in to unlock these features, it’s free!');
								   }
								}}
						placeholder="Date from" 
						disabled={props.flight_form.is_disabled} />
                        )}
                    </Form.Item>
				</Col>

				<Col xs={{span: 22}} lg={{span:10, offset: 2}} className="date-to-container" >
				<Form.Item >
					{getFieldDecorator("date-to", {
						rules: [{
								required: "required",
								message: "Date is required.",
							}]
					})(
					<DatePicker 
						onChange={
							(date, dateString) => {
								props.handleFlightFormChange("date_to", date)
							}
						}
						onMouseEnter={(e) => {
								   if (props.flight_form.is_disabled){
									message.info('Please log in to unlock these features, it’s free!');
								   }
								}}
						disabledDate={(currentDate) => {
							return currentDate && currentDate.valueOf() < props.flight_form.date_from
						}}
						placeholder="Date to" 
						disabled={
							props.flight_form.date_from === undefined ? true:false
						} />
					)}
				</Form.Item>
				</Col>
                <Col xs={{span: 2}}>
				<Tooltip placement="topLeft" title="Enter a starting date and completion date. A range is
acceptable to account for weather etc" arrowPointAtCenter>
						<Icon className="info-circle" type="info-circle" theme="twoTone" />
					</Tooltip>
                </Col>

				<Col xs={{span: 22}} lg={{span:10}} className="time-from-container" >
				<Form.Item >
					{getFieldDecorator("time-from", {
						rules: [{
								required: "required",
								message: "Time is required.",
							}]
					})(
					<TimePicker 
						onChange={
							(date, dateString) => {
								props.handleFlightFormChange("time_from", date);
							}
						} 
						onMouseEnter={(e) => {
								   if (props.flight_form.is_disabled){
									message.info('Please log in to unlock these features, it’s free!');
								   }
								}}
						format="HH:mm"
						minuteStep={15}
						placeholder="Time from" 
						disabled={props.flight_form.is_disabled} />
					)}
				</Form.Item>
				</Col>

				<Col xs={{span: 22}} lg={{span:10, offset: 2}} className="time-to-container" >
				<Form.Item >
					{getFieldDecorator("time-to", {
						rules: [{
								required: "required",
								message: "Time is required.",
							}]
					})(
					<TimePicker 
						onChange={
							(date, dateString) => {
								props.handleFlightFormChange("time_to", date)
							}
						}
						onMouseEnter={(e) => {
								   if (props.flight_form.is_disabled){
									message.info('Please log in to unlock these features, it’s free!');
								   }
								}}
						format="HH:mm"
						minuteStep={15}
						placeholder="Time to" 
						disabled={
							props.flight_form.time_from === undefined ? true:false
						} />
					)}
				</Form.Item>
				</Col>
                <Col xs={{span: 2}}>
				<Tooltip placement="topLeft" title="Enter a range during which the flight will take place" arrowPointAtCenter>
						<Icon className="info-circle" type="info-circle" theme="twoTone" />
					</Tooltip>
                </Col>

                <Col xs={{span: 22}} className="duration-container" >
                    <Form.Item  hasFeedback >
                        {getFieldDecorator("duration", {
                            rules: [{
                                    required: "required",
                                    message: "Duration is required."
                                }]
                        })(
							<Select
							placeholder="Duration of flight"
							onChange={(value) => {props.handleFlightFormChange("duration", value)  }}
							onMouseEnter={(e) => {
								   if (props.flight_form.is_disabled){
									message.info('Please log in to unlock these features, it’s free!');
								   }
								}}
							disabled={props.flight_form.is_disabled} 
						  >
								<Option value="0:30 mins">0:30 mins</Option>
								<Option value="1:00 mins">1:00 mins</Option>
								<Option value="1:30 mins">1:30 mins</Option>
								<Option value="2:00 mins">2:00 mins</Option>
								<Option value="2:30 mins">2:30 mins</Option>
								<Option value="3:00 mins">3:00 mins</Option>
								<Option value="3:30 mins">3:30 mins</Option>
								<Option value="4:00 mins">4:00 mins</Option>
								<Option value="4:30 mins">4:30 mins</Option>
								<Option value="5:00 mins">5:00 mins</Option>
								<Option value="5:30 mins">5:30 mins</Option>
								<Option value="6:00 mins">6:00 mins</Option>
						  </Select>
                        // <Input onChange={(e) => {props.handleFlightFormChange("duration", e.target.value)}} placeholder="Duration of flight" disabled={props.flight_form.is_disabled} />
                        )}
                    </Form.Item>
                </Col>
                <Col xs={{span: 2}}>
					<Tooltip placement="topLeft" title="Enter the max flight duration" arrowPointAtCenter>
						<Icon className="info-circle" type="info-circle" theme="twoTone" />
					</Tooltip>
                </Col>

				<Col xs={{span: 22}} lg={{span:10}} className="max-altitude-container" >
				<Form.Item  hasFeedback >
					{getFieldDecorator("max-altitude", {
						rules: [{
								required: "required",
								message: "Altitude is required." 
							}]
					})(
					<Input
					onChange={(e) => {props.handleFlightFormChange("max_altitude", e.target.value)}} placeholder="Max altitude (ft)" disabled={props.flight_form.is_disabled} 
					onMouseEnter={(e) => {
								   if (props.flight_form.is_disabled){
									message.info('Please log in to unlock these features, it’s free!');
								   }
								}}
					/>
					)}
				</Form.Item>
				</Col>

				<Col xs={{span: 22}} lg={{span:10, offset: 2}} className="max-range-container" >
				<Form.Item  hasFeedback >
					{getFieldDecorator("max-range", {
						rules: [{
								required: "required",
								message: "Range is required."
							}]
					})(
					<Input
					onChange={(e) => {props.handleFlightFormChange("max_range", e.target.value)}} placeholder="Max range (m)" disabled={props.flight_form.is_disabled} 
					onMouseEnter={(e) => {
								   if (props.flight_form.is_disabled){
									message.info('Please log in to unlock these features, it’s free!');
								   }
								}}
					/>
					)}
				</Form.Item>
				</Col>
                <Col xs={{span: 2}}>
				<Tooltip placement="topLeft" title="Enter the maximum flight altitude in feet (1 metre =
3.28ft) and range from pilot in metres" arrowPointAtCenter>
						<Icon className="info-circle" type="info-circle" theme="twoTone" />
					</Tooltip>
                </Col>

				<Col xs={{span: 18}} className="primary-pilot-container" >
                    <Form.Item  hasFeedback >
                        {getFieldDecorator("primary-pilot", {
                            rules: [{
                                    required: "required",
                                    message: "Pilot name is required."
                                }]
                        })(
                        <Select 
                        placeholder="Select Primary Pilot" 
                        onChange={(value) => {props.handleFlightFormChange("pilot", value)}} disabled={props.flight_form.is_disabled} 
                        onMouseEnter={(e) => {
								   if (props.flight_form.is_disabled){
									message.info('Please log in to unlock these features, it’s free!');
								   }
								}}
                        >
							{props.flight_form.pilots.options.map((pilot) => (
								<Select.Option key={pilot.key} value={pilot.key}>{pilot.pilot_name}</Select.Option>
							))}
                        </Select>
                        )}
                    </Form.Item>
				</Col>
                <Col xs={{span: 4}}>
					<Icon 
						className="plus-circle" 
						type="plus-circle"  
						onClick={
							() => {
								props.flight_form.is_disabled ? console.log("Login!!") : props.showModal("pilots")
							}
						} 
					/>
                </Col>
                <Col xs={{span: 2}}>
						<Tooltip placement="topLeft" title="Name of the pilot completing the flight, add additional
if required" arrowPointAtCenter>
						<Icon className="info-circle" type="info-circle" theme="twoTone" />
					</Tooltip>
                </Col>

				<Col xs={{span: 18}} className="drone-container" >
				<Form.Item  hasFeedback >
					{getFieldDecorator("drone", {
						rules: [{
								required: "required",
								message: "Drone is required."
							}]
					})(
					<Select placeholder="Select Drone" onChange={(value) => {props.handleFlightFormChange("drone", value)}} disabled={props.flight_form.is_disabled} >
				      	{props.flight_form.drones.options.map((drone) => (
							  <Select.Option key={drone.key} value={drone.key}>{drone.drone_name}</Select.Option>
						  ))}
				    </Select>
				    )}
				</Form.Item>
				</Col>
                <Col xs={{span: 4}}>
					<Icon 
						className="plus-circle" 
						onClick={
							() => {
								props.flight_form.is_disabled ? console.log("Login!!") : props.showModal("drones")
							}
						} 
						type="plus-circle" 
					/>
                </Col>
                <Col xs={{span: 2}}>
					<Tooltip placement="topLeft" title="Drone being flown, add additional if required" arrowPointAtCenter>
						<Icon className="info-circle" type="info-circle" theme="twoTone" />
					</Tooltip>
                </Col>
				</Row>

				<Divider className="form-divider" />

				<Row>
				<Col xs={{span: 22}} className="gps-location-container" >
				<Form.Item className='two-rows-label' label="GPS Location" {...inlineLabelLayout} hasFeedback >
					<Input placeholder="GPS Location" value={props.flight_form.gps_dms} readOnly />
				</Form.Item>
				</Col>
                <Col xs={{span: 2}}>
					<Tooltip placement="topLeft" title="GPS location of the place where flight will be conducted" arrowPointAtCenter>
						<Icon className="info-circle" type="info-circle" theme="twoTone" />
					</Tooltip>
                </Col>

				<Col xs={{span: 22}} className="elevation-container" >
				<Form.Item className='two-rows-label' label="Elevation (above sea level)" {...inlineLabelLayout} hasFeedback >
					<Input placeholder="Elevation" value={props.flight_form.elevation} readOnly />
				</Form.Item>
				</Col>
                <Col xs={{span: 2}}>
					<Tooltip placement="topLeft" title="Elevation of the flight." arrowPointAtCenter>
						<Icon className="info-circle" type="info-circle" theme="twoTone" />
					</Tooltip>
                </Col>
				</Row>
				<Row gutter={5} >
					<Col xs={{span: 8}} className="airport-container" >
					<Form.Item className='two-rows-label' colon={false} label="Nearest airport" hasFeedback >
						<Input placeholder="Nearest airport" value={props.flight_form.airport} readOnly />
					</Form.Item>
					</Col>

					<Col xs={{span: 8}} className="bearing-container" >
					<Form.Item className='two-rows-label' colon={false} label="Bearing from airport" hasFeedback >
						<Input placeholder="Bearing" value={props.flight_form.bearing} readOnly />
					</Form.Item>
					</Col>

					<Col xs={{span: 8}} className="distance-container" >
					<Form.Item className='two-rows-label' colon={false} label="Distance from airport" hasFeedback >
						<Input placeholder="Distance" value={props.flight_form.distance} readOnly />
					</Form.Item>
					</Col>
				</Row>

				<Divider className="form-divider" />

	        	<Row gutter={5} >
                    <Col xs={{span: 24}}>
                        <Col xs={{span: 12}} className="airport-container" >
							<Button 
								className="green-button" 
								disabled={props.flight_form.is_disabled} 
								block 
								type="primary" 
								onClick={() => handleOnSubmit(props, handleSaveFlight)}>
                                Save<br/>Flight
                            </Button>
                        </Col>

                        <Col xs={{span: 12}} className="airport-container" >
                            <Button className="green-button" disabled={props.flight_form.is_disabled} block type="primary" onClick={() => props.resetFlightForm()} >
                                Reset<br/>Form
                            </Button>
                        </Col>
                    </Col>

                    <Col xs={{span: 24}}>
                        <Col xs={{span: 12}} className="bearing-container" >
                            <Button className="green-button" disabled={props.flight_form.is_disabled} block type="primary" onClick={() => handleOnSubmit(props, props.exportToDoc)} >
                                Export to<br/>.docx
                            </Button>
                        </Col>

                        <Col xs={{span: 12}} className="distance-container" >
                            {/* <Button className="green-button" disabled={props.flight_form.is_disabled} block type="primary" onClick={() => handleOnSubmit(props, props.exportToPdf)}> */}
                            <Button className="green-button" disabled={props.flight_form.is_disabled} block type="primary" onClick={() => props.showModal("air_traffic")}>
                                Submit to<br/>Air Traffic Control
                            </Button>
                        </Col>
                    </Col>
				</Row>

      		</Form>
			  <Modal 
					visible={props.flight_form.air_traffic_modal.visible}
					okText="Save form and download as pdf"
					onOk={() => {
						new Promise((resolve, reject) => {
							resolve(props.closeModal("air_traffic"));
						}).then(() => {
							handleOnSubmit(props, props.exportToPdf);
						});
					}}
					onCancel={() => props.closeModal("air_traffic")}
					cancelText="Okay"
					title="Submit to Air traffic control"
				>
					
					{getAirTrafficList(props.flight_form.airport)}
				</Modal>
			</React.Fragment>
		);
}

export default Form.create({
	mapPropsToFields: (props) => ({
		"flight-id": Form.createFormField({
			...props.flight_form.flight_id,
			value: props.flight_form.flight_id
		}),
		"flight-name": Form.createFormField({
			...props.flight_form.flight_name,
			value: props.flight_form.flight_name
		}),
		"date-from": Form.createFormField({
			...props.flight_form.date_from,
			value: props.flight_form.date_from
		}),
		"date-to": Form.createFormField({
			...props.flight_form.date_to,
			value: props.flight_form.date_to
		}),
		"time-from": Form.createFormField({
			...props.flight_form.time_from,
			value: props.flight_form.time_from
		}),
		"time-to": Form.createFormField({
			...props.flight_form.time_to,
			value: props.flight_form.time_to
		}),
		"duration": Form.createFormField({
			...props.flight_form.duration,
			value: props.flight_form.duration
		}),
		"max-altitude": Form.createFormField({
			...props.flight_form.max_altitude,
			value: props.flight_form.max_altitude
		}),
		"max-range": Form.createFormField({
			...props.flight_form.max_range,
			value: props.flight_form.max_range
		}),
		"primary-pilot": Form.createFormField({
			...props.flight_form.pilot,
			value: props.flight_form.pilot
		}),
		"drone": Form.createFormField({
			...props.flight_form.drone,
			value: props.flight_form.drone
		}),
	})
})(form);