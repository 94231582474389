import React, { Component } from 'react';
import './App.css';
import Home from './home/Home.jsx';
import 'antd/dist/antd.css';

class App extends Component {

  	render() {
	    return (
	      	<div className="row App">
	      		<Home />
	      	</div>
	    );
  	}
}

export default App;
