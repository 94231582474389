import React from 'react';
import {
  Form, Icon, Input, Button, DatePicker, TimePicker, Select
} from 'antd';
import { message } from 'antd';
import rest from '../common/network';

let form_prop = null

const handlePilotUpdate = (props, response) => {
	console.log("******")
	console.log(response);
	props.toggleAsyncButton();
	props.form.resetFields();
    if(response.data.success === true) {
        message.success(response.data.message);
        props.close();
        props.updatePilotList(response.data.pilot_data);
    }
    else
        message.error(response.data.message);
}

const handleOnSubmit = (e, props) => {
	e.preventDefault();
	// alert(props.data.pilot_id_value);
	form_prop.validateFields((err, values) => {
		console.log(values);
		if(!err) {
			let headers = {};
			props.toggleAsyncButton();
			const date_pcc = values["pcc"];
			const data = {
				pilot_id: props.data.pilot_id_value,
				pilot_name: values["pilot-name"],
				phone1: values["phone1"],
				phone2: values["phone2"],
				pcc: `${date_pcc.date()}-${date_pcc.month()+1}-${date_pcc.year()}`,
			}
			rest({
				method: 'post',
				endpoint: '/flight/pilot/edit',
				headers: headers,
				data: data,
				response_type: "",
				success: handlePilotUpdate,
				props: props,
                error: props.handleRequestError
			});
		}
	});
}

const comparePassword = (rule, value, callback) => {
    if (value && value !== form_prop.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

const fields = (item, getFieldDecorator) => {
	if(item.type === "date") {
		return (
			<Form.Item key={item.id} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							required: item.required,
							message: item.req_msg
						}]
				})(
				<DatePicker type={item.type} placeholder={item.placeholder} style={{"width": "100%"}} />
				)}
			</Form.Item>
		);
	}
	else if(item.type === "time") {
		return (
			<Form.Item key={item.id} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							pattern: item.val_pattern,
							message: item.val_msg
						},
						{
							required: item.required,
							message: item.req_msg
						},
						{
							validator: item.id==="confirm-password" ? comparePassword : ""
						}]
				})(
				<TimePicker type={item.type} placeholder={item.placeholder} />
				)}
			</Form.Item>
		);
	}
	else if(item.type === "select") {
		return (
			<Form.Item key={item.id} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							pattern: item.val_pattern,
							message: item.val_msg
						},
						{
							required: item.required,
							message: item.req_msg
						},
						{
							validator: item.id==="confirm-password" ? comparePassword : ""
						}]
				})(
				<Select style={{ width: 120 }} >
			      	<Select.Option value="jack">Jack</Select.Option>
			      	<Select.Option value="lucy">Lucy</Select.Option>
			      	<Select.Option value="disabled" disabled>Disabled</Select.Option>
			      	<Select.Option value="Yiminghe">yiminghe</Select.Option>
			    </Select>
			    )}
			</Form.Item>
		);
	}
	else {
		return (
			<Form.Item key={item.id} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							pattern: item.val_pattern,
							message: item.val_msg
						},
						{
							required: item.required,
							message: item.req_msg
						},
						{
							validator: item.id==="confirm-password" ? comparePassword : ""
						}]
				})(
				<Input prefix={item.icon ? <Icon type={item.icon} style={{ color: 'rgba(0,0,0,.25)' }} /> : ""} type={item.type} placeholder={item.placeholder} />
				)}
			</Form.Item>
		);
	}
}

const pilot_form = (props) => {
	const { getFieldDecorator } = props.form;
    form_prop = props.form
	return (
        	<Form 
        		id={props.data.edit_pilots_id} 
        		className={props.data.class} 
        		layout={props.data.layout}
        		onSubmit={(e) => handleOnSubmit(e, props)}
        	>
        		{
        			props.data.fields.map((item) => {
        				return fields(item, getFieldDecorator)			
        			})
        		}
	        	
      		</Form>
		);
}

export default Form.create({
	mapPropsToFields: (props) => ({
		
		"pilot-name": Form.createFormField({
			...props.data.pilot_name_value,
			value: props.data.pilot_name_value
		}),
		"phone1": Form.createFormField({
			...props.data.phone1_value,
			value: props.data.phone1_value
		}),
		"phone2": Form.createFormField({
			...props.data.phone2_value,
			value: props.data.phone2_value
		}),
		"pcc": Form.createFormField({
			...props.data.pcc,
			value: props.data.pcc
		})

	})
})(pilot_form);