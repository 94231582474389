import React, { Component } from 'react';
import Layout from 'antd/lib/layout';
import Menu from 'antd/lib/menu';
import './home.scss';
import { data, options, notamLocations } from '../map/MapData.js';
import Windy from '../map/Map.jsx';
import Modal from 'antd/lib/modal';
import Form from '../form/form.jsx';
import EditDrone from '../form/editDrone.jsx';
import EditPilot from '../form/editPilot.jsx';
import FlightForm from '../form/flight-form.jsx';
import { Button } from 'antd';
import { notams } from '../map/notam';
import MapSearch from '../searchBox/mapSearchBox.jsx';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { message, Col, Spin, Icon } from 'antd';
import { convertDMS, distanceInKmBetweenEarthCoordinates, calculateBearing, formatDate, latFromDms, lngFromDms } from '../common/utils';
import axios from 'axios';
import { SATELLITE, MAP } from '../common/constants';
import rest from '../common/network';
import MyFlights from '../flights/flights.jsx';
import moment from 'moment';
import Settings from '../settings/Settings.jsx';

//destructuring of object with same variables
const { Header, Content, Sider } = Layout

class Home extends Component {
	// global variables for map and marker object
	mapObj = null;
	marker = [];
	actioned = false
	
	state = {
		overlay: "hidden-overlay",
		overlay_msg: "Saving your flight.",
		menu_1: "",
		menu_2: "menu_hidden",
		confirmLoading: false,
		username: "User",
		nav_size: {
			xs: 26,
			xs_offset: 0,
			md: 26,
			md_offset: 0
		},
		selected_nav: "Home",
		content: {
			"map_content": "",
			"flights_content": "hidden",
			"sider_content": "",
			"settings_content": "hidden"
		},
		login: {
			visible: false,
			form: {
				id: "login",
				class: "login-form",
				layout: "horizontal",
				fields: [
					{
						id: "email", 
						type: "email",
						placeholder: "E-mail",
						icon: "mail",
						hasfeedback: true,
						val_pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						val_msg: "Enter a valid email address.",
						required: true,
						requ_msg: "Email is required.",
					},
					{
						id: "password", 
						type: "password",
						placeholder: "Password",
						icon: "lock",
						hasfeedback: true,
						val_pattern: /^\S+$/g,
						val_msg: "Enter a valid password.",
						required: true,
						requ_msg: "Password is required.",
					}
				]
			}
		},
		reset: {
			visible: false,
			form: {
				id: "reset",
				class: "reset-form",
				layout: "horizontal",
				fields: [
					{
						id: "email", 
						type: "email",
						placeholder: "E-mail",
						icon: "mail",
						hasfeedback: true,
						val_pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						val_msg: "Enter a valid email address.",
						required: true,
						requ_msg: "Email is required.",
					}
				]
			}
		},
		signup: {
			visible: false,
			form: {
				id: "signup",
				class: "signup-form",
				layout: "horizontal",
				fields: [
					{
						id: "first-name", 
						type: "text",
						placeholder: "First name",
						icon: "user",
						hasfeedback: true,
						val_pattern: /^[a-zA-Z0-9]+/g,
						val_msg: "Enter a valid name(minimum of 3 characters), no numbers and special characters.",
						required: true,
						requ_msg: "First name is required.",
					},
					{
						id: "last-name", 
						type: "text",
						placeholder: "Last name",
						icon: "user",
						hasfeedback: true,
						val_pattern: /^[a-zA-Z0-9]+/g,
						val_msg: "Enter a valid name, no numbers and special characters.",
						required: false,
						requ_msg: "Enter a valid name.",
					},
					{
						id: "email", 
						type: "email",
						placeholder: "E-mail",
						icon: "mail",
						hasfeedback: true,
						val_pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						val_msg: "Enter a valid email address.",
						required: true,
						requ_msg: "Email is required.",
					},
					{
						id: "password", 
						type: "password",
						placeholder: "Password",
						icon: "lock",
						hasfeedback: true,
						val_pattern: /^\S+$/g,
						val_msg: "Enter a valid password.",
						required: true,
						requ_msg: "Password is required.",
					},
					{
						id: "confirm-password", 
						type: "password",
						placeholder: "Confirm password",
						icon: "lock",
						hasfeedback: true,
						val_pattern: /^\S+$/g,
						val_msg: "Enter a valid password.",
						required: true,
						requ_msg: "Password is required.",
					}
				]
			}
		},
		map: {
			weather: false,
			style: MAP,
			marker: false,
			city: "",
			query: "",
			disabled: false,
			map_btn: "primary",
			sat_btn: "default"
		},
		my_flights: {
			flight_list: [{
				key: 1,
				flight_name: "FlightForm1",
				pilot: "pilot",
				drone: "drone",
				gps_dms: "gps_dms"
			}]
		},
		company: {
			id: "company",
			class: "company-form",
			layout: "horizontal",
			sop_name_value: "",
			sop_number_value: "",
			sop_expiry_value: null,
			fields: [
				{
					id: "sop-name", 
					type: "text",
					label: "SOP Operator Name",
					placeholder: "SOP Operator Name",
					icon: "user",
					hasfeedback: true,
					val_pattern:/^[a-zA-Z0-9]+/g,
					val_msg: "Enter a valid name",
					required: true,
					requ_msg: "Operator name is required.",
				},
				{
					id: "sop-number", 
					type: "text",
					label: "SOP Permission Number",
					placeholder: "SOP Permission Number",
					icon: "user",
					hasfeedback: true,
					val_pattern: /^\S{1,127}$/g,
					val_msg: "Enter a valid permission number.",
					required: true,
					requ_msg: "Permission number is required.",
				},
				{
					id: "sop-expiry", 
					type: "date",
					label: "SOP Expiry Date",
					placeholder: "SOP Expiry Date",
					icon: "user",
					hasfeedback: true,
					val_pattern: "",
					val_msg: "Select expiry date",
					required: true,
					requ_msg: "Expiry date is required",
				}
			]
		},
		flight_form: {
			flight_id: null,
			flight_name: "",
			date_from: undefined,
			date_to: undefined,
			time_from: undefined,
			time_to: undefined,
			duration: "",
			max_altitude: "",
			max_range: "",
			pilot: undefined,
			drone: undefined,
			gps: "",
			gps_dms: "",
			airport: "",
			bearing: "",
			distance: "",
			elevation: "",
			is_disabled: true,
			date_to_disabled: true,
			time_to_disabled: true,
			air_traffic_modal: {
				visible: false
			},
			pilots: {
				visible: false,
				edit_visible: false,
				options: [
					{
						pilot_name: "Jack",
						key: "Jack"
					},
					{
						pilot_name: "Lucy",
						key: "Lucy"
					}
				],
				form: {
					edit_pilots_id: "edit-pilots",
					id: "pilots",
					class: "pilots-form",
					layout: "horizontal",
					pilot_id_value: null,
					pilot_name_value: "",
					fields: [
						{
							id: "pilot-name", 
							type: "text",
							placeholder: "Pilot name",
							icon: "user",
							hasfeedback: true,
							val_pattern: /^[a-zA-Z](\S|\s){0,125}[a-zA-Z0-9]$/g,
							val_msg: "Enter a valid name and it should not be more than 32 characters.",
							required: true,
							requ_msg: "Pilot name is required.",
						},
						{
							id: "phone1", 
							type: "text",
							placeholder: "Primary Phone",
							icon: "user",
							hasfeedback: true,
							val_pattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
							val_msg: "Enter your primary phone number",
							required: true,
							requ_msg: "Primary phone number is required",
						},
						{
							id: "phone2", 
							type: "text",
							placeholder: "Secondary Phone",
							icon: "user",
							hasfeedback: true,
							val_pattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
							val_msg: "Enter an alternative phone number",
							required: true,
							requ_msg: "Secondady phone number is required",
						},
						{
							id: "pcc", 
							type: "date",
							placeholder: "PCC Expiry Date",
							icon: "user",
							hasfeedback: true,
							val_pattern: "",
							val_msg: "Select PCC expiry date",
							required: true,
							requ_msg: "PCC expiry date is required",
						}
					]
				}
			},
			drones: {
				visible: false,
				edit_visible: false,
				options: [
					{
						drone_name: "Jack",
						drone_id: "Jack1234",
						key: "Jack"
					},
					{
						drone_name: "Lucy",
						drone_id: "Lucy1234",
						key: "Lucy"
					}
				],
				form: {
					edit_drones_id: "edit-drones",
					id: "drones",
					class: "drones-form",
					layout: "horizontal",
					drone_key_value: null,
					drone_name_value: "",
					drone_id_value: "",
					fields: [
						{
							id: "drone-name", 
							type: "text",
							placeholder: "Drone Model name eg DJI Mavic Pro 2",
							icon: "user",
							hasfeedback: true,
							val_pattern: /^[a-zA-Z](\S|\s){0,125}[a-zA-Z0-9]$/g,
							val_msg: "Enter a valid Drone model name.",
							required: true,
							requ_msg: "Drone name is required.",
						},
						{
							id: "drone-id", 
							type: "text",
							placeholder: "IAA Drone Registration Number eg IE-1212123",
							icon: "user",
							hasfeedback: true,
							val_pattern: /\S{1,128}/g,
							val_msg: "Enter a valid Drone model number",
							required: true,
							requ_msg: "Drone model number is required.",
						},
					]
				}
			}
		}
	}

	toggleLoader = (show, msg="") => {
		if(show === true) {
			this.setState({
				...this.state, 
				overlay: "active-overlay", 
				overlay_msg: msg
			});
		}
		else {
			this.setState({
				...this.state, 
				overlay: "hidden-overlay",
				overlay_msg: msg
			});
		}
	}

	showModal = (index, callBack=null) => {
		if(index === "login")
			this.setState( {...this.state, login: {...this.state.login, visible: true}}, callBack )
		else if(index === "signup")
			this.setState( {...this.state, signup: {...this.state.signup, visible: true}}, callBack )
		else if(index === "reset")
			this.setState( {...this.state, reset: {...this.state.reset, visible: true}}, callBack )
		else if(index === "pilots")
			this.setState( {...this.state, flight_form: {...this.state.flight_form, pilots: {...this.state.flight_form.pilots, visible: true}}}, callBack )
		else if(index === "drones")
			this.setState( {...this.state, flight_form: {...this.state.flight_form, drones: {...this.state.flight_form.drones, visible: true}}}, callBack )
		else if(index === "air_traffic")
			this.setState( {...this.state, flight_form: {...this.state.flight_form, air_traffic_modal: {...this.state.flight_form.air_traffic_modal, visible: true}}}, callBack )
	}

	showEditModal = (index, data) => {
		if(index === "edit_pilots")
			this.setState( {
				...this.state, 
				flight_form: {
					...this.state.flight_form, 
					pilots: {
						...this.state.flight_form.pilots, 
						edit_visible: true,
						form: {
							...this.state.flight_form.pilots.form,
							pilot_id_value: data.pilot_id,
							pilot_name_value: data.pilot_name,
							phone1_value: data.phone1,
							phone2_value: data.phone2,
							pcc: data.pcc ? moment(data.pcc, "DD-MM-YYYY"):null
						}
					}
				}
			});
		else if(index === "edit_drones")
			this.setState( {
				...this.state, 
				flight_form: {
					...this.state.flight_form, 
					drones: {
						...this.state.flight_form.drones, 
						edit_visible: true,
						form: {
							...this.state.flight_form.drones.form,
							drone_key_value: data.drone_key,
							drone_id_value: data.drone_id,
							drone_name_value: data.drone_name
						}
					}
				}
			});
	}

	closeModal = (index, callBack=null) => {
		
		if(index === "login")
			this.setState( {...this.state, login: {...this.state.login, visible: false}}, callBack )
		else if(index === "signup")
			this.setState( {...this.state, signup: {...this.state.signup, visible: false}}, callBack )
		else if(index === "reset")
			this.setState( {...this.state, reset: {...this.state.reset, visible: false}}, callBack )
		else if(index === "pilots")
			this.setState( {...this.state, flight_form: {...this.state.flight_form, pilots: {...this.state.flight_form.pilots, visible: false}}}, callBack )
		else if(index === "drones")
			this.setState( {...this.state, flight_form: {...this.state.flight_form, drones: {...this.state.flight_form.drones, visible: false}}}, callBack )
		else if(index === "edit_pilots")
			this.setState( {...this.state, flight_form: {...this.state.flight_form, pilots: {...this.state.flight_form.pilots, edit_visible: false}}}, callBack )
		else if(index === "edit_drones")
			this.setState( {...this.state, flight_form: {...this.state.flight_form, drones: {...this.state.flight_form.drones, edit_visible: false}}}, callBack )
		else if(index === "air_traffic")
			this.setState( {...this.state, flight_form: {...this.state.flight_form, air_traffic_modal: {...this.state.flight_form.air_traffic_modal, visible: false}}}, callBack )
	}
	
	toggleAsyncButton = () => {
		if(this.state.confirmLoading === true)
			this.setState({
				...this.state,
				confirmLoading: false
			})
		else
			this.setState({
				...this.state,
				confirmLoading: true
			})
	}

	showCloseModal = (index) => {
		if(index === "login") {
			this.closeModal("signup", () => this.showModal("login"));
		}
		else if(index === "signup") {
			this.closeModal("login", () => this.showModal("signup"));
		}
		else if(index === "reset") {
			this.closeModal("login", () => this.showModal("reset"));
		}
	}

	updatePilotList = (list) => {
		this.setState({
			...this.state, 
			flight_form: {
				...this.state.flight_form, 
				pilots: {
					...this.state.flight_form.pilots, 
					options: list
				}
			}
		});
	}

	updateDroneList = (list) => {
		this.setState({
			...this.state, 
			flight_form: {
				...this.state.flight_form, drones: {
					...this.state.flight_form.drones, 
					options: list
				}
			}
		});
	}

	updateSOPData = (data) => {
		const name = data.sop_operator_name;
        const number = data.sop_permission_number;
        const expiry = data.sop_expiry_date !== ""?moment(data.sop_expiry_date, "DD-MM-YYYY"):null;
        // const expiry = moment();
        // console.log("####")
        
		this.setState({
			...this.state, 
			company: {
				...this.state.company,
				sop_name_value: name,
				sop_number_value: number,
				sop_expiry_value: expiry,
			}
		})
	}

	handlePilotAdd = (props, response) => {
		if(response.data.success === true) {
			this.updatePilotList(response.data.pilot_data);
		}
		else
			message.error(response.data.message);
	}
	
	handleDroneAdd = (props, response) => {
		//console.log(response);
		if(response.data.success === true) {
			this.updateDroneList(response.data.drone_data);
		}
		else
			message.error(response.data.message);
	}

	// enable disabled date-to or disabled time-to
	// enableDateTime = (type) => {
	// 	if(type === "date")
	// 		this.setState({
	// 			...this.state,
	// 			flight_form: {
	// 				...this.state.flight_form,
	// 				date_to_disabled: false
	// 			}
	// 		});
	// 	else if(type === "time")
	// 		this.setState({
	// 			...this.state,
	// 			flight_form: {
	// 				...this.state.flight_form,
	// 				time_to_disabled: false
	// 			}
	// 		});
	// }

	toggleMenu = (login, username) => {
		if(login === true) {
			localStorage.setItem("username", username);
			this.setState({
				...this.state, 
				menu_1: "menu_hidden", 
				menu_2: "",
				username: username,
				flight_form: {
					...this.state.flight_form, 
					is_disabled:false
				}
			}, () => {
				this.fetchInitData();
			});
		}
		else {
			const current_this = this
			Modal.confirm({
				title: 'Are you sure you want to logout?',
				onOk() {
					localStorage.removeItem("auth");
					localStorage.removeItem("username");
					current_this.setState({
						...current_this.state, 
						menu_1: "", 
						menu_2: "menu_hidden", 
						flight_form: {
							...current_this.state.flight_form, 
							is_disabled:true
						}
					}, () => current_this.toggleHome());
				},
				onCancel() {}
			})
		}
	}

	resetFlightForm = (my_flights=false) => {
		if(this.location_marker !== undefined)
			this.mapObj.removeLayer(this.location_marker);
		this.setState({
			...this.state,
			flight_form: {
				...this.state.flight_form,
				flight_id: null,
				flight_name: "",
				date_from: undefined,
				date_to: undefined,
				time_from: undefined,
				time_to: undefined,
				duration: "",
				max_altitude: "",
				max_range: "",
				pilot: undefined,
				drone: undefined,
				gps: "",
				gps_dms: "",
				elevation: "",
				airport: "",
				bearing: "",
				distance: ""
			}
		}, () => {
			if(my_flights===true)
				this.toggleMyflights();
		});
	}

	deleteMyFlights = (props=null, response=null, flight_id=null) => {
		const current_this = this;
		if(flight_id !== null) {
			Modal.confirm({
				title: "Are you sure you want to delete this flight? Once deleted cannot be undone.",
				onOk() {
					const headers = {}
					const data = {
						flight_id: flight_id
					}
					rest({
						method: 'post',
						endpoint: `/flight/delete`,
						headers: headers,
						data: data,
						response_type: "",
						success: current_this.deleteMyFlights,
						props: current_this.state,
						error: current_this.handleRequestError
					});
				},
				onCancel() {}
			});
		}
		else if(response !== null) {
			if(response.data.success === true) {
				this.setState({
					...this.state,
					my_flights: {
						...this.state.my_flights,
						flight_list: response.data.flightData
					}
				});
				message.success(response.data.message);
			}
			else
				message.error(response.data.message);
		}
	}

	editMyFlights = (props=null, response=null, flight_id=null) => {
		if(flight_id !== null) {
			const headers = {}
			const data = {
				flight_id: flight_id
			}
			rest({
				method: 'get', 
				endpoint: `/flight/edit`, 
				headers: headers, 
				data: data, 
				response_type: "", 
				success: this.editMyFlights, 
				props: this.state,
				error: this.handleRequestError
			});
			// rest('get', `/flight/edit`, headers, data, "", this.editMyFlights, this.state);
		}
		else if(response !== null) {
			if(response.data.success === true) {
				const flight_id = response.data.flightData.flight_id;
				const flight_name = response.data.flightData.flight_name;
				const date_from = moment(response.data.flightData.date_from, "YYYY-MM-DD");
				const date_to = moment(response.data.flightData.date_to, "YYYY-MM-DD");
				const time_from = moment(response.data.flightData.time_from, "h:mm:ss");
				const time_to = moment(response.data.flightData.time_to, "h:mm:ss");
				const duration = response.data.flightData.duration;
				const max_altitude = response.data.flightData.max_altitude;
				const max_range = response.data.flightData.max_range;
				const pilot = response.data.flightData.pilot;
				const drone = response.data.flightData.drone;
				const gps_location = response.data.flightData.gps_location;
				const gps_dms = response.data.flightData.gps_dms;
				const elevation = response.data.flightData.elevation;
				const nearest_airport = response.data.flightData.nearest_airport;
				const bearing_airport = response.data.flightData.bearing_airport;
				const distance_airport = response.data.flightData.distance_airport;
				//console.log(gps_location);
				const lat = gps_location.split(",")[0];
				const lng = gps_location.split(",")[1];
				// this.mapObj.fireEvent('click', {latlng: [lat,lng]})
				// const msg = this.location_marker.getPopup().getContent();
				// console.log(msg);
				if(this.location_marker !== undefined)
		  			this.mapObj.removeLayer(this.location_marker);
				this.location_marker = window.L.marker()
													  .setLatLng([lat,lng])
													  .bindPopup(nearest_airport)
													  .addTo(this.mapObj);
				this.clicked_latlng = this.location_marker.getLatLng();
				this.setState({
					...this.state,
					nav_size: {
						xs: 26,
						xs_offset: 0,
						md: 26,
						md_offset: 0
					},
					selected_nav: "Home",
					content: {
						...this.state.content,
						map_content: "",
						flights_content: "hidden",
						sider_content: "",
					},
					flight_form: {
						...this.state.flight_form,
						flight_id: flight_id,
						flight_name: flight_name,
						date_from: date_from,
						date_to: date_to,
						time_from: time_from,
						time_to: time_to,
						duration: duration,
						max_altitude: max_altitude,
						max_range: max_range,
						pilot: pilot,
						drone: drone,
						gps: gps_location,
						gps_dms: gps_dms,
						elevation: elevation,
						airport: nearest_airport,
						bearing: bearing_airport,
						distance: distance_airport
					}
				});
				setTimeout(() => message.info("Selected flight data is loaded for editing."), 400)
			}
		}
	}

	toggleMyflights = (props=null, response=null) => {
		if(response === null) {
			const headers = {};
			const data = {};
			rest({
				method: 'get', 
				endpoint: `/flight/add`, 
				headers: headers, 
				data: data, 
				response_type: "", 
				success: this.toggleMyflights, 
				props: this.state,
				error: this.handleRequestError
			});
			// rest('get', `/flight/add`, headers, data, "", this.toggleMyflights, this.state);
		}
		else {
			if(response.data.success === true) {
				this.setState({
					...this.state,
					nav_size: {
						xs: 26,
						xs_offset: 0,
						md: 16,
						md_offset: 5
					},
					selected_nav: "My Flights",
					content: {
						...this.state.content,
						map_content: "hidden",
						flights_content: "",
						sider_content: "hidden",
						settings_content: "hidden"
					},
					my_flights: {
						...this.state.my_flights,
						flight_list: response.data.flightData
					}
				});
			}
		}
	}

	toggleHome = () => {
		this.setState({
			...this.state,
			nav_size: {
				xs: 26,
				xs_offset: 0,
				md: 26,
				md_offset: 0
			},
			selected_nav: "Home",
			content: {
				...this.state.content,
				map_content: "",
				flights_content: "hidden",
				sider_content: "",
				settings_content: "hidden"
			}
		}, () => {
			this.resetFlightForm()
		});
	}

	toggleSettings = () => {
		this.setState({
			...this.state,
			nav_size: {
				xs: 26,
				xs_offset: 0,
				md: 16,
				md_offset: 5
			},
			selected_nav: "Settings",
			content: {
				...this.state.content,
				map_content: "hidden",
				flights_content: "hidden",
				sider_content: "hidden",
				settings_content: ""
			}
		});
	}

	handleToggleWeather = () => {
		const weather = this.state.map.weather === true ? false : true;
		// add or remove windy weather styles
		if(weather === true) {
			document.getElementById("bottom").setAttribute("style","visibility: visible !important");
		    document.getElementById("mobile-ovr-select").setAttribute("style","visibility: visible !important");
		}
		else {
			document.getElementById("bottom").setAttribute("style","visibility: hidden !important");
		    document.getElementById("mobile-ovr-select").setAttribute("style","visibility: hidden !important");
		}
		this.setState( {...this.state, map: {...this.state.map, weather: weather}}, () => {
			// satellite or street map based
			this.changeMap();
		    // remove windy layer on map by setting opacity
		    // this.mapObj.baseLayer.setOpacity(this.state.map.weather===true ? 0.3 : 1);
		    if(this.state.map.weather === true) {
				// remove windy overlay color
				//console.log(this.mapObj);
			    this.mapObj.addLayer(this.windyColorlayer);
			    // toggle wind animation off
			    this.storeObj.set('particlesAnim', 'on');
			    this.mapObj.baseLayer.setOpacity(0.5)
		    }
		    else {
		    	// remove windy overlay color
			    this.mapObj.removeLayer(this.windyColorlayer);
			    // toggle wind animation off
			    this.storeObj.set('particlesAnim', 'off');
			    this.mapObj.baseLayer.setOpacity(1)
		    }
		});
	}

	handleFlightFormChange = (key, value, callBack=null) => {
		let test = {...this.state.flight_form}
		test[key] = value;
		this.setState({
			...this.state,
			flight_form: test
		}, callBack);
	}

	handleToggleNotams = () => {
		// set or unset markers
		if(this.state.map.marker === true) {
			this.setState({...this.state, map:{...this.state.map, marker: false}});
			this.marker.map(mark => this.mapObj.removeLayer(mark));
		}
		else {
			this.setState({...this.state, map:{...this.state.map, marker: true}});
			this.marker.map(mark => mark.addTo(this.mapObj));
		}
	}

	takeScreenShot = () => {
		this.toggleLoader(true, "Generating screenshot, this will just take a minute!");
		let windy = document.getElementById("windy");
		let screenshot_info = document.createElement("div")
		screenshot_info.setAttribute("id", "screenshot-info");
		windy.appendChild(screenshot_info);

		const lat = this.clicked_latlng.lat;
		const lng = this.clicked_latlng.lng;
		this.mapObj.setView([lat, lng], 12);
		let home_this = this;
		return new Promise((resolve, reject) => {
			setTimeout(function() {
				screenshot_info.innerHTML = (`<div>
									<h2>GPS: ${home_this.state.flight_form.gps_dms}</h2>
									<h2>Elevation: ${home_this.state.flight_form.elevation}</h2>
									<h2>Nearest Airport: ${home_this.state.flight_form.airport}</h2>
									<h2>Bearing from Airport: ${home_this.state.flight_form.bearing}</h2>
									<h2>Distance from Airport: ${home_this.state.flight_form.distance}</h2>
								</div>`);
				domtoimage.toBlob(windy)
				.then(function (blob) {
					// home_this.toggleLoader(true, "Saving screenshot...");
					screenshot_info.remove();
					resolve(blob);
				});
			}, 3000);
		});
	}

	handleScreenShot = () => {
		
		if(this.clicked_latlng === undefined) {
			message.error("Choose a location on the map to take screenshot!");
		}
		else {
			this.takeScreenShot().then((blob) => {
				// get blob as response
				saveAs(blob, 'flyryte-airspace-planner.png');
				this.toggleLoader(false);
			});
		}
	}

	handleFeedback = () => {
		
		window.location.href = 'mailto:info@flyryte.com?subject=Feedback';
	}

	handleRequestError = (props, error) => {
		this.toggleAsyncButton();
		this.toggleLoader(false);
		if((error.response !== undefined) && (error.response.status === 500) && (error.response.data.type === "application/json")) {
			const reader = new FileReader();
			new Promise((resolve, reject) => {
				reader.addEventListener('loadend', () => {
					resolve(reader.result);
				});
				reader.readAsText(error.response.data);
			}).then((msg) => {
				message.error(msg);
			});
		}
		else
			message.error("Network error.");
	}

	downloadDoc = (props, response) => {
		//console.log(response);
		//console.log('success' in response);
		if('success' in response) {
			message.error(response.data.message);
		}
		else {
			const blob = new Blob([response.data], {type: "text/json;charset=utf-8"})
			saveAs(blob, 'U.F.101.docx');
			//console.log("success");
		}
		this.toggleLoader(false);
	}

	exportToDoc = (props, data) => {
		let popup = this.location_marker.getPopup();
		const orig_content = popup.getContent();
		popup.setContent(`<h2>${orig_content}</h2>`);
		this.takeScreenShot().then((blob) => {
			popup.setContent(orig_content);
			this.toggleLoader(true, "Exporting to docx...");
			const headers = {'Content-Type': 'multipart/form-data' }
			data['map'] = blob;
			if(this.state.flight_form.flight_id !== null)
				data['flight_id'] = this.state.flight_form.flight_id;
			rest({
				method: 'post', 
				endpoint: `/flight/save/docx`, 
				headers: headers, 
				data: data, 
				response_type: "blob", 
				success: this.downloadDoc, 
				props: this.state,
				error: this.handleRequestError
			});
		});
	}

	downloadPdf = (props, response) => {
		if( ('success' in response) && (response.success === false) ) {
			message.error(response.data.message)
		}
		else {
			const blob = new Blob([response.data], {type: "text/json;charset=utf-8"})
			saveAs(blob, 'U.F.101.pdf');
			//console.log("success");
		}
		this.toggleLoader(false);
	}

	exportToPdf = (props, data) => {
		let popup = this.location_marker.getPopup();
		const orig_content = popup.getContent();
		popup.setContent(`<h2>${orig_content}</h2>`);
		this.takeScreenShot().then((blob) => {
			popup.setContent(orig_content);
			this.toggleLoader(true, "Exporting to PDF...");
			const headers = {'Content-Type': 'multipart/form-data' }
			data['map'] = blob;
			if(this.state.flight_form.flight_id !== null)
				data['flight_id'] = this.state.flight_form.flight_id;
			rest({
				method: 'post', 
				endpoint: `/flight/save/pdf`, 
				headers: headers, 
				data: data, 
				response_type: "blob", 
				success: this.downloadPdf, 
				props: this.state,
				error: this.handleRequestError
			});
		});
	}

	updateChekBox = (index) => {
		const my_flights = this.state.my_flights.flight_list[index];
	  	const headers = {}
	  	const data = {
	    	flight_id: my_flights.key,
	    	risk_assesment: my_flights.risk_assesment,
	    	sent_to_atc: my_flights.sent_to_atc,
	    	approved_by_atc: my_flights.approved_by_atc,
	    	completed: my_flights.completed,
	  	}
	  	rest({
	    	method: 'post',
	    	endpoint: `/flight/update/data`,
	    	headers: headers,
	    	data: data,
	    	response_type: "",
	    	success: (props, response) => {
	      	//console.log("Done")
	    	},
	    	props: this.state,
	    	error: this.handleRequestError
	  	});
	}

	handleCheckBox = (e, index, key) => {
		let flight_list = {...this.state.my_flights.flight_list};
		flight_list[index][key] = flight_list[index][key] === true ? false:true;
		const current_this = this;
		this.setState({
			...this.state,
			my_flights: {
				...this.state.my_flights,
				...flight_list
			}
		}, current_this.updateChekBox(index));
	}

	// set baselayer of map
	changeMap = () => {
		this.mapObj.baseLayer.setUrl(this.state.map.style);
		this.backupLayer.setUrl(this.state.map.style);
	}

	// switch between satellite and street map
	handleMapType = (type) => {
		if(type === 1)
			// satellite
			this.setState({...this.state, map:{...this.state.map, style: SATELLITE, map_btn: "default", sat_btn: "primary"}}, this.changeMap);
		else
			// street
			this.setState({...this.state, map:{...this.state.map, style: MAP, map_btn: "primary", sat_btn: "default"}}, this.changeMap);
	}

	// handle list of autocomplete items selected in searchbox
	handleSearchSelect = (address, addressObject) => {
		this.setState({...this.state, map:{...this.state.map, city: address[0].long_name, query: addressObject.formatted_address}});
		const lat = addressObject.geometry.location.lat();
		const long = addressObject.geometry.location.lng();
		this.mapObj.setView([lat, long], 12, {animate: true});
	}

	// handle what is typed inside searchbox.
	handleSearchChange = (val) => {
		this.setState({...this.state, map:{...this.state.map, query: val}});
	}

	// handleFormSuccess = (props, response) => {
	// 	console.log(response);
	// }

	// handleFlightForm = (props=null, response=null, data) => {
	// 	if(response === null) {
	// 		const headers = {};
	// 		if(this.state.flight_form.flight_id === null)
	// 			rest('post', `/flight/add`, headers, data, this.handleFormSuccess, this.state);
	// 		else {
	// 			data["flight_id"] = this.flight_form.flight_id;
	// 			rest('post', `/flight/edit`, headers, data, this.handleFormSuccess, this.state);
	// 		}
	// 	}
	// }

	calculateMapData = (lat, long, tar_lat=null, tar_lng=null, tar_city=null) => {
		const from_lat = parseFloat(lat);
		const from_long = parseFloat(long);
		let short_distance;
		let bearing;
		let airport;
		if(tar_lat !== null) {
			//console.log("*****");
			const to_lat = parseFloat(tar_lat); 
			const to_long = parseFloat(tar_lng);
			const distance = distanceInKmBetweenEarthCoordinates(from_lat, from_long, to_lat, to_long);
			short_distance = distance;
			bearing = calculateBearing(from_lat, from_long, to_lat, to_long);
			airport = tar_city;
		}
		else {
			notamLocations.map(place => {
				const to_lat = parseFloat(place.lat); 
				const to_long = parseFloat(place.long);
				const distance = distanceInKmBetweenEarthCoordinates(from_lat, from_long, to_lat, to_long);
				if(short_distance === undefined) { 
					short_distance = distance;
					bearing = calculateBearing(from_lat, from_long, to_lat, to_long);
					airport = place.name;
				}
				else
					if(distance < short_distance) {
						short_distance = distance;
						bearing = calculateBearing(from_lat, from_long, to_lat, to_long);
						airport = place.name;
					}
				return true;
			});
		}

		// elevation object {"elevations":[{"lat":58.81374171570782,"lon":-97.3828125,"elevation":295.5}],"resolution":"1000m"}
		let elevation = axios.get(`https://elevation-api.io/api/elevation?key=2vQ8p0-kNBySAXmzgbnb63eGTa5-e2&resolution=90&points=(${lat},${long})`);
		elevation.then((response) => {
			//console.log(response);
			this.setState({...this.state, flight_form: {...this.state.flight_form, 
				airport: airport, 
				distance: short_distance.toFixed(0).toString()+'km', 
				bearing: bearing,
				elevation: (response.data.elevations[0].elevation * 3.28084).toFixed(0).toString()+'ft'
			}});
		});
		// return [airport, short_distance, bearing];
	}

	drawMap = () => {
		// assign home referenct to home_this variable as this will point to map object in map.on method
		let home_this = this
		

		window.windyInit( options, windyAPI => {
			
		    const { store, picker, map } = windyAPI;
		    //console.log(windyAPI);
		    this.mapObj = map;
		    this.storeObj = store;

		    // check if overlays are loaded
		    new Promise((resolve, reject) => {
		    	setTimeout(() => {
		    		// if(map.hasLayer(map._layers[88]) === true){
					// 	resolve(true);
					// }
					var layers = []
					map.eachLayer(function (layer) {
						if (layer.fcstUrl != null) {
							layers.push(layer);
						}
						else if (layer.latestParams != null && layer.latestParams.acTime != null && layer.latestParams.acTime == "next3d"){
							layers.push(layer);
							home_this.windyColorlayer = layer;
						}
					});
					resolve(layers)
		    			
		    	}, 500)
		    }).then((layers) => {
	    		// store the windy color layer and remove it add only during wind overlay
			    // home_this.windyColorlayer = map._layers[67];
				// map.removeLayer(map._layers[67]);
				//console.log(layers);
				layers.forEach(function(layer) {
					map.removeLayer(layer);
				  });
				  
				// map.removeLayer(layer);
				
			    // toggle wind animation off
			    store.set('particlesAnim', 'off');
	    	});

		    const icon = window.L.icon({
			    iconUrl: 'static/images/marker.png',
				iconSize: [24, 38], //size of the icon in pixels
				iconAnchor: [12, 35], //point of the icon which will correspond to marker's x,y axis
				popupAnchor: [3, -20] //where the popup should appear x,y axis
		    })

		    map.options.minZoom = 4;
		    map.options.maxZoom = 18;

		    this.backupLayer = window.L.tileLayer(this.state.map.style).addTo(map);
		    this.backupLayer.setOpacity('0');

		    map.on('zoomend', function() {
		        if (map.getZoom() >= 12) {
		        	home_this.backupLayer.setOpacity('1');
		        } else {
		        	home_this.backupLayer.setOpacity('0');
		        }
		    });
		    map.setZoom(7);

	      	// change baselayer of windy to mapbox
	      	map.baseLayer.setUrl(this.state.map.style);
	      	// remove windy layer by setting opacity to 1
	      	map.baseLayer.setOpacity(this.state.map.weather===true ? 0.5 : 1);

			//   const circlea = window.L.circle([51.507674, -0.107803], 1500, {
			// 	color: 'red',
			// 	fillColor: '#f03',
			// 	fillOpacity: 0.5
			// }).on('click', function(e) {
			// 	  console.log("aaaa");
			//   }).addTo(map);

			map.on('click', function(e) {
				//console.log(home_this.actioned);
				if (home_this.actioned)
				{
					home_this.actioned = false;
					return;
				}
				home_this.actioned = false;
				//console.log("map");
	  			//console.log(e.latlng);
	  			home_this.calculateMapData(e.latlng.lat, e.latlng.lng)
	  			home_this.setState({
												...home_this.state,
											  	flight_form: {
														...home_this.state.flight_form,
														gps: `${e.latlng.lat},${e.latlng.lng}`,
														gps_dms: convertDMS(e.latlng.lat, e.latlng.lng)
													}
											});
	  			home_this.clicked_latlng = e.latlng
	  			if(home_this.location_marker !== undefined)
	  				map.removeLayer(home_this.location_marker);
	  			home_this.location_marker = window.L.marker()
								      		.setLatLng(e.latlng)
								      		.bindPopup("<p><strong>Class G Airspace.</strong><br> No airspace restrictions. Drone should be operated in accordance with the aicraft category and never higher than 120m/ 400ft without permission. For category restrictions please see FlyRyte Category Overview video. Fly safe!</p>")
								      		.addTo(map);
				home_this.location_marker.openPopup();
	      	});
		    // draw polygon and circles on map
		    data.map((place) => {
		    	const city = place.city;
		    	const city_latlng = place.city_latlng;
		    	if('circle' in place) {
			      	const circle = window.L.circle(place.circle.latlng, place.circle.radius, place.circle.options)
			      	.on('click', function(e) {
						home_this.actioned = true;
						//console.log("circle");
			      		home_this.calculateMapData(e.latlng.lat, e.latlng.lng, city_latlng[0], city_latlng[1], city);
			      		home_this.setState({
												...home_this.state,
											  	flight_form: {
														...home_this.state.flight_form,
														gps: `${e.latlng.lat},${e.latlng.lng}`,
														gps_dms: convertDMS(e.latlng.lat, e.latlng.lng)
													}
											});
			      		home_this.clicked_latlng = e.latlng;
			      		if(home_this.location_marker !== undefined)
		  					map.removeLayer(home_this.location_marker);
			  			home_this.location_marker = window.L.marker()
										      		.setLatLng(e.latlng)
										      		.bindPopup(place.info)
										      		.addTo(map)
						home_this.location_marker.openPopup();
			      	})
			      	.addTo(map)
			      	if(place.circle.top === true)
	            		circle.bringToFront();
	            	else
	            		circle.bringToBack();
				}
	        	if('polygon' in place) {
					
	        		const poly = window.L.polygon(place.polygon.latlng, place.polygon.options)
	            	.on('click', function(e) {
						home_this.actioned = true;
	            		//console.log("polygon");
	            		//console.log(city_latlng);
	            		//console.log(city);
	            		home_this.calculateMapData(e.latlng.lat, e.latlng.lng, city_latlng[0], city_latlng[1], city);
	            		home_this.setState({
												...home_this.state,
											  	flight_form: {
														...home_this.state.flight_form,
														gps: `${e.latlng.lat},${e.latlng.lng}`,
														gps_dms: convertDMS(e.latlng.lat, e.latlng.lng)
													}
											});
	            		home_this.clicked_latlng = e.latlng;
			      		if(home_this.location_marker !== undefined)
		  					map.removeLayer(home_this.location_marker);
			  			home_this.location_marker = window.L.marker()
										      		.setLatLng(e.latlng)
										      		.bindPopup(place.info)
										      		.addTo(map)
						home_this.location_marker.openPopup();
			      	})
	            	.addTo(map)
	            	if(place.polygon.top === true)
	            		poly.bringToFront();
	            	else
	            		poly.bringToBack();
	        	}
		        return true;
		    });

		    picker.on('pickerOpened', function() {
	      		picker.close();
	      	});



		    // get notam markers
		      // notamLocations.map((location) => {
		      	const notam_data = notams.filter((data) => {
		      		// filter notams if icao(location in notam.js) matches and
		      		// todays date is in between start and end date
		      		const start_date = new Date(data.startdate);
					const end_date = new Date(data.enddate);
					const today = new Date();
					return (data.Area.toLowerCase() === "navigation warnings") && (start_date < today && today < end_date)
		      	});
		      	if(notam_data.length > 0) {
		      		// const marker = window.L.marker([location.lat, location.long], {icon: icon});
		      		// let msg = "";
		      		notam_data.map(data => {
		      			let msg = ""
		      			let location = data.all.split("\n")[1].split("/");
		      			location = location[location.length-1];
		      			const lat = latFromDms(location);
		      			const lng = lngFromDms(location);
		      			const marker = window.L.marker([lat, lng], {icon: icon});
		      			msg+=`<div style='white-space: pre-line'><h3><b>Subject: </b>${data.Subject}</h3>`;
			      		msg+=`<p><b>Message: </b>${data.message}</p>`;
			      		msg+=`<p>FROM: ${formatDate(data.startdate)}&nbsp;&nbsp;To: ${formatDate(data.enddate)}</p></div>`;
						msg+="<hr/>";
						// return true;
				      	// bind the message popup to notam marker
				      	marker.bindPopup(msg, {maxHeight: 300, minWidth: 300});
				      	// store all markers in global variable
				      	this.marker.push(marker);
			      	});
		      	}
				return true;
			// });
	    });
	}

	fetchInitData() {
		if(localStorage.getItem("auth")) {
			const headers = {}
			const data = {}
			rest({
				method: 'get', 
				endpoint: `/flight/pilot/add`, 
				headers: headers, 
				data: data, 
				response_type: "", 
				success: this.handlePilotAdd, 
				props: this.state,
				error: this.handleRequestError
			});
			
			rest({
				method: 'get', 
				endpoint: `/flight/drone/add`, 
				headers: headers, 
				data: data, 
				response_type: "", 
				success: this.handleDroneAdd, 
				props: this.state,
				error: this.handleRequestError
			});

			const current_this = this;
			rest({
				method: 'get', 
				endpoint: `/user/edit?type=sop`, 
				headers: headers, 
				data: data, 
				response_type: "", 
				success: (props, response) => {
					current_this.updateSOPData(response.data.sopData)
				}, 
				props: this.state,
				error: this.handleRequestError
			});
			// rest('get', `/flight/drone/add`, headers, data, "", this.handleDroneAdd, this.state);
		}
		else {
			// this.toggleMenu(false);
		}
	}

	componentDidMount() {
		//console.log(localStorage.getItem("auth"));
		//console.log("*****");
		const url_params = window.location.search.substr(1).split("=");
		if(url_params[0] === "reset") {
			message.success("Password has been reset successfully. Try login.");
			window.history.replaceState({}, document.title, window.location.origin);
			localStorage.removeItem("auth");
			localStorage.removeItem("username");
			this.setState({
				...this.state, 
				menu_1: "", 
				menu_2: "menu_hidden", 
				flight_form: {
					...this.state.flight_form, 
					is_disabled:true
				}
			});
		}
		else {
			if(localStorage.getItem("auth")) {
				if(localStorage.getItem("username") === null || localStorage.getItem("username") === "null" || localStorage.getItem("username") === undefined) {
					localStorage.removeItem("auth");
					localStorage.removeItem("username");
					this.setState({
						...this.state, 
						menu_1: "", 
						menu_2: "menu_hidden", 
						flight_form: {
							...this.state.flight_form, 
							is_disabled:true
						}
					});
				}
				else
					this.toggleMenu(true, localStorage.getItem("username"));
			}
			else {
				// this.toggleMenu(false);
			}
		}
		this.drawMap();
		
	}

	Menu(items, classname, login) {
		return (
			<Menu id="top-menu" selectedKeys={[this.state.selected_nav]} className={classname} mode="horizontal" theme="light" >
				{items.map(val => (<Menu.Item className="menu-items" onClick={val.onclick} key={val.title}>{val.title}</Menu.Item>))}
				{login === true ? (
						<Menu.SubMenu title={<span><Icon type="user" /><span>{this.state.username}</span></span>}>
							<Menu.Item className="menu-items" onClick={() => this.toggleMenu(false)} key="logout">Log out</Menu.Item>
						</Menu.SubMenu>
					): ''}
			</Menu>
		);
	}

	render() {
		return (
				<Layout className="outer-layout" >
					<div className={this.state.overlay} >
						<Spin 
							style={{marginTop:"20%", fontSize: "25px", color: "black"}} 
							indicator={<Icon type="loading" style={{ fontSize: 40 }} spin />}
							size="large" 
							tip={this.state.overlay_msg} />
					</div>
				  	<Layout className="content-layout" >
				  		<Header className="header" >
							  	<Col 
									xs={{span: this.state.nav_size.xs, offset: this.state.nav_size.xs_offset}} 
								  	md={{span: this.state.nav_size.md, offset: this.state.nav_size.md_offset}} 
								>
								<img className="brand" src="static/images/FlyRyteLogo.png" alt="logo" />
								{
									this.Menu([
										{title: 'Log in', onclick: () => this.showModal("login")},
										{title: 'Register', onclick: () => this.showModal("signup")},
										{title: 'My Flights', onclick: () => this.showModal("login")},
										{title: 'Settings', onclick: () => this.showModal("login")}
									],
									this.state.menu_1, false)
								}

								{
									this.Menu([
										{title: 'Home', onclick: () => this.toggleHome()},
										{title: 'My Flights', onclick: () => this.toggleMyflights()},
										{title: 'Settings', onclick: () => this.toggleSettings()},
										// {title: 'Log out', onclick: () => this.toggleMenu(false)},
									], this.state.menu_2, true)
								}
							</Col>
					  	</Header>
				    	<Content className={`map-content ${this.state.content.map_content}`} >
				    		<Modal
				    			visible={this.state.login.visible} 
				    			onOk={() => this.closeModal("login")} 
				    			onCancel={() => this.closeModal("login")}
				    			title="Log in"
				    			footer={null}
				    		>
				    		<Form data={this.state.login.form} 
								signup={() => this.showCloseModal("signup")}
								reset={() => this.showCloseModal("reset")}
				    			close={() => this.closeModal("login")}
								toggleMenu={this.toggleMenu}
								handleRequestError={this.handleRequestError}
				    			/>
				    		</Modal>

				    		<Modal 
				    			visible={this.state.signup.visible} 
				    			onOk={() => this.closeModal("signup")} 
				    			onCancel={() => this.closeModal("signup")}
				    			title="Sign up"
				    			footer={null}
				    		>
				    		<Form data={this.state.signup.form} 
				    			login={() => this.showCloseModal("login")}
								close={() => this.closeModal("signup")}
								toggleMenu={this.toggleMenu}
								handleRequestError={this.handleRequestError}
				    			/>
				    		</Modal>

							<Modal 
				    			visible={this.state.reset.visible} 
				    			onOk={() => this.closeModal("reset")} 
				    			onCancel={() => this.closeModal("reset")}
				    			title="Reset Password"
				    			footer={null}
				    		>
				    		<Form data={this.state.reset.form} 
				    			login={() => this.closeModal("reset", () => this.showModal("login"))} 
								close={() => this.closeModal("reset")}
								handleRequestError={this.handleRequestError}
				    			/>
				    		</Modal>

							<Modal 
				    			visible={this.state.flight_form.pilots.visible} 
								okText="Add"
								okButtonProps={{htmlType: "submit", form: this.state.flight_form.pilots.form.id}}
								confirmLoading={this.state.confirmLoading}
								onCancel={() => this.closeModal("pilots")}
				    			title="Add Pilot"
				    		>
				    		<Form data={this.state.flight_form.pilots.form} 
								close={() => this.closeModal("pilots")}
								updatePilotList={this.updatePilotList}
								toggleAsyncButton={this.toggleAsyncButton}
								handleRequestError={this.handleRequestError}
				    			/>
				    		</Modal>

							<Modal 
				    			visible={this.state.flight_form.drones.visible} 
								okButtonProps={{htmlType: "submit", form: this.state.flight_form.drones.form.id}}
								confirmLoading={this.state.confirmLoading}
								okText="Add"
				    			onCancel={() => this.closeModal("drones")}
				    			title="Add Drones"
				    		>
				    		<Form data={this.state.flight_form.drones.form}
								close={() => this.closeModal("drones")}
								updateDroneList={this.updateDroneList}
								toggleAsyncButton={this.toggleAsyncButton}
								handleRequestError={this.handleRequestError}
				    			/>
				    		</Modal>

							<Modal 
				    			visible={this.state.flight_form.pilots.edit_visible} 
								okButtonProps={{htmlType: "submit", form: this.state.flight_form.pilots.form.edit_pilots_id}}
								confirmLoading={this.state.confirmLoading}
								okText="Update"
				    			onCancel={() => this.closeModal("edit_pilots")}
				    			title="Edit Pilot"
				    		>
					    		<EditPilot data={this.state.flight_form.pilots.form}
									close={() => this.closeModal("edit_pilots")}
									updateDroneList={this.updatePilotList}
									toggleAsyncButton={this.toggleAsyncButton}
									handleRequestError={this.handleRequestError}
									updatePilotList={this.updatePilotList}
				    			/>
				    		</Modal>

							<Modal 
				    			visible={this.state.flight_form.drones.edit_visible} 
								okButtonProps={{htmlType: "submit", form: this.state.flight_form.drones.form.edit_drones_id}}
								confirmLoading={this.state.confirmLoading}
								okText="Update"
				    			onCancel={() => this.closeModal("edit_drones")}
				    			title="Edit Drone"
				    		>
					    		<EditDrone data={this.state.flight_form.drones.form}
									close={() => this.closeModal("edit_drones")}
									updateDroneList={this.updateDroneList}
									toggleAsyncButton={this.toggleAsyncButton}
									handleRequestError={this.handleRequestError}
									updateDroneList={this.updateDroneList}
				    			/>
				    		</Modal>

							{/* <Modal 
				    			visible={this.state.flight_form.air_traffic_modal.visible} 
								okButtonProps=""
								confirmLoading=""
								okText="Save form and download as pdf"
								onCancel={() => this.closeModal("air_traffic")}
								cancelText="Okay"
				    			title="Air traffic control"
				    		>
							 	<p>
									 For Dublin, Cork and Shannon Completed Form must be sent to: <a href="mainto:suaairspace@iaa.ie?Subject=SUA Flight in Controlled Airspace Application Form">suaairspace@iaa.ie</a>
								</p>
								<p>
									For Sligo Airport (EISG) Completed Form must be sent to: <a href="mainto:safetymanager@sligoairport.com?Subject=SUA Flight in Controlled Airspace Application Form">safetymanager@sligoairport.com</a>
								</p>
								<p>
									For Kerry Airport (EIKY) Completed Form must be sent to: <a href="mainto:atc@kerryairport.ie?Subject=SUA Flight in Controlled Airspace Application Form">atc@kerryairport.ie</a>
								</p>
								<p>
									For Waterford Airport (EIWF) Completed Form must be sent to: <a href="mainto:sua@waterfordairport.net?Subject=SUA Flight in Controlled Airspace Application Form">sua@waterfordairport.net</a>
								</p>
								<p>
									For Donegal Airport (EIDL) Completed Form must be sent to: <a href="mainto:sua@donegalairport.ie?Subject=SUA Flight in Controlled Airspace Application Form">sua@donegalairport.ie</a>
								</p>
								<p>
									For Ireland West Airport (EIKN) Completed Form must be sent to: <a href="mainto:michaelconnolly@irelandwestairport.com?Subject=SUA Flight in Controlled Airspace Application Form">michaelconnolly@irelandwestairport.com</a>
								</p>
				    		</Modal> */}

				    		<Windy />
				    		<MapSearch 
				    			handleSearchChange={this.handleSearchChange} 
				    			mapState={this.state.map} 
				    			handleSearchSelect={this.handleSearchSelect} 
								handleMapType={this.handleMapType}
				    		/>
				    		
				    		<div className="toggle-btn-container" >
					    		<Button onClick={this.handleToggleWeather} block type="primary">
									Toggle<br/>Weather
								</Button>
								<Button onClick={(e) => {e.preventDefault(); window.location.href="https:\/\/notaminfo.com/irelandmap"}} block type="primary">
									Toggle<br/>NOTAMS
								</Button>
								<Button onClick={this.handleScreenShot} block disabled={this.state.map.disabled} type="primary">
									Take map<br/>screenshot
								</Button>
								<Button onClick={this.handleFeedback} block type="primary">
									Give us<br/> feedback
								</Button>
							</div>
				    	</Content>
						<Content className={`flights-content ${this.state.content.flights_content}`} >
							<MyFlights 
								my_flights={this.state.my_flights}
								editMyFlights={this.editMyFlights}
								deleteMyFlights={this.deleteMyFlights}
								handleCheckBox={this.handleCheckBox}
								handleRequestError={this.handleRequestError}
							/>
						</Content>
						<Content className={`flights-content ${this.state.content.settings_content}`} >
							<Settings 
								flight_form={this.state.flight_form} 
								showModal={this.showModal} 
								showEditModal={this.showEditModal} 
								handleRequestError={this.handleRequestError}
								updatePilotList={this.updatePilotList}
								updateDroneList={this.updateDroneList} 
								company={this.state.company}
								confirmLoading={this.state.confirmLoading}
								toggleAsyncButton={this.toggleAsyncButton} 
								handleRequestError={this.handleRequestError} 
								updateSOPData={this.updateSOPData} />
						</Content>
				  	</Layout>
					  <Sider 
						id="side-panel" 
						className={this.state.content.sider_content} 
						theme="light" 
						breakpoint="lg"
      					collapsedWidth="0"
						width="300">
			    		<Layout>
			    			<content className="side-content" >
			    				<img className="side-brand" src="static/images/FlyRyteLogo.png" alt="logo" />
			    				<p className="title" >Select your flight<br/>location on the map</p>
			    				<FlightForm 
									showModal={this.showModal}
									closeModal={this.closeModal}
									flight_form={this.state.flight_form} 
									toggleLoader={this.toggleLoader} 
									resetFlightForm={this.resetFlightForm} 
									handleFlightFormChange={this.handleFlightFormChange}
									exportToDoc={this.exportToDoc}
									exportToPdf={this.exportToPdf}
									handleRequestError={this.handleRequestError} />
			    			</content>
			    		</Layout>
			    	</Sider>
				</Layout>	
			);
	}
}

export default Home
