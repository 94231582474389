export const options = {
  key: 'JBDqHOCHcgz7F1qPKiM0Ly7DltAnTJmh',
  lat: 53.327864,
  lon: -7.926275
}

export const notamLocations = [
  {
    name: "Cork",
    lat: "51.8413889",
    long: "-8.4911111",
    icao: "EICK"
  },
  {
    name: "Dublin",
    lat: "53.4213889",
    long: "-6.2700000",
    icao: "EIDW"
  },
  {
    name: "Shannon",
    lat: "52.7019444",
    long: "-8.9247222",
    icao: "EINN"
  },
  {
    name: "Casement",
    lat: "53.3030556",
    long: "-6.4552778",
    icao: "EIME"
  },
  {
    name: "Donegal",
    lat: "55.0441667",
    long: "-8.3411111",
    icao: "EIDL"
  },
  {
    name: "Knock",
    lat: "53.9102778",
    long: "-8.8186111",
    icao: "EIKN"
  },
  {
    name: "Kerry",
    lat: "52.1808333",
    long: "-9.5238889",
    icao: "EIKY"
  },
  {
    name: "Sligo",
    lat: "54.2802778",
    long: "-8.5991667",
    icao: "EISG"
  },
  {
    name: "Waterford",
    lat: "52.1872222",
    long: "-7.0869444",
    icao: "EIWF"
  },
  {
    name: "Weston",
    lat: "53.3522222",
    long: "-6.4883333",
    icao: "EIWT"
  }
]

// const triangleCoords = [
//       {lat: 53.154, lng: -6.879},
//       {lat: 53.161, lng: -6.824},
//       {lat: 53.150, lng: -6.804},
//       {lat: 53.130, lng: -6.799},
//       {lat: 53.147, lng: -6.879},
//       {lat: 53.154, lng: -6.873},
//       {lat: 53.154, lng: -6.879}// repeat
// ];

const gormanstown = [
  {lat: 53.6405, lng: -6.224667},

  {lat: 53.625667, lng: -6.142833},
  {lat: 53.592833, lng: -5.956667},

  {lat: 53.6405, lng: -6.224667},
  {lat: 53.803667, lng: -6.154333},
  {lat: 53.6895, lng: -6.204833},

  {lat: 53.625667, lng: -6.142833},
]

const imal = [
  {lat: 53.041333, lng: -6.403167},
  {lat: 52.918, lng: -6.406},
  {lat: 52.950167, lng: -6.575667},
  {lat: 53.0045, lng: -6.556667},
  {lat: 53.020667, lng: -6.533833},
  {lat: 53.041333, lng: -6.403167},
]

const kilworth = [
  {lat: 52.233333, lng: -8.250833},
  {lat: 52.238333, lng: -8.2},
  {lat: 52.2175, lng: -8.19},
  {lat: 52.209167, lng: -8.236667},
]

const sea = [
  {lat: 51.568667, lng: -8.706},
  {lat: 51.335333, lng: -8.572667},
  {lat: 51.289333, lng: -8.808},
  {lat: 51.523667, lng: -8.951},
  {lat: 51.568667, lng: -8.706},
]

const seaKer1 = [
  {lat: 51.768056, lng: -10.300278},
  {lat: 51.591667, lng: -10.300278},
  {lat: 51.377222, lng: -10.711944},
  {lat: 51.554722, lng: -10.95},
  {lat: 51.768056, lng: -10.300278},
]

const seaKer = [
  {lat: 51.7675, lng: -10.537833},
  {lat: 51.588333, lng: -10.300167},
  {lat: 51.373, lng: -10.707167},
  {lat: 51.554722, lng: -10.95},
  {lat: 51.7675, lng: -10.537833},
]


const triangleCoords2 = [
  { lat: 53.333, lng: -6.358 },
  { lat: 53.244, lng: -6.358 },//bottom
  { lat: 53.243, lng: -6.618 },//
  { lat: 53.367, lng: -6.710 },//
  { lat: 53.342, lng: -6.515 },
  { lat: 53.333, lng: -6.358 },
];

const triangleCoords3 = [ //KERRY
  { lat: 52.101, lng: -9.761 },
  { lat: 52.098, lng: -9.745 },
  { lat: 52.101, lng: -9.725 },
  { lat: 52.100, lng: -9.711 },
  { lat: 52.075, lng: -9.646 },
  { lat: 52.067, lng: -9.522 },
  { lat: 52.069, lng: -9.510 },
  { lat: 52.068, lng: -9.500 },
  { lat: 52.063, lng: -9.486 },
  { lat: 52.050, lng: -9.431 },
  { lat: 52.059, lng: -9.338 },//BOTTOM CIRCLE

  { lat: 52.07471844359866, lng: -9.313778651751017 },
  { lat: 52.08759465932904, lng: -9.298545694617955 },
  { lat: 52.10243611158393, lng: -9.28252172183627 },
  { lat: 52.11972137271081, lng: -9.270511016478531 },
  { lat: 52.13743902652432, lng: -9.260029414728885 },
  { lat: 52.15961692013197, lng: -9.252798111488868 },
  { lat: 52.17931950515025, lng: -9.2511654916478 },
  { lat: 52.19902039108543, lng: -9.251935129890915 },
  { lat: 52.22213645040686, lng: -9.258440494780201 },
  { lat: 52.24275421717096, lng: -9.269741142701402 },

  { lat: 52.25949729930048, lng: -9.281727836423451 },
  { lat: 52.27685989698013, lng: -9.299274842820321 },
  { lat: 52.29417642789581, lng: -9.321777169191245 },
  { lat: 52.31005641100715, lng: -9.346745925302463 },
  { lat: 52.32495439394159, lng: -9.381469246183046 },
  { lat: 52.3346484599025, lng: -9.411317311944863 },
  { lat: 52.3409312314246, lng: -9.438716026526601 },
  { lat: 52.34592877682961, lng: -9.46621693405875 },
  { lat: 52.34860287229024, lng: -9.495487180536497 },
  { lat: 52.34932715261969, lng: -9.533265476210257 },

  { lat: 52.34694533340484, lng: -9.571404171243636 },
  { lat: 52.34155022095061, lng: -9.605485144316914 },

  { lat: 52.332, lng: -9.634 },//TOP CIRCLE
  { lat: 52.243, lng: -9.643 },
  { lat: 52.244, lng: -9.774 },//top side

  { lat: 52.22578136941173, lng: -9.786736173849752 },
  { lat: 52.20795689764478, lng: -9.791922678991664 },
  { lat: 52.18980883594735, lng: -9.796043362869872 },
  { lat: 52.17694544471554, lng: -9.79722745301059 },
  { lat: 52.15762239034156, lng: -9.793646614080727 },
  { lat: 52.14082256318605, lng: -9.786971819877932 },
  { lat: 52.12057060502995, lng: -9.778649761336924 },

  { lat: 52.101, lng: -9.761 }//bottom side
];

const triangleCoords5 = [
  { lat: 52.50315073981575, lng: -8.678985857430519 },//
  { lat: 52.63555353578602, lng: -8.689195413753767 },//
  { lat: 52.63926785029923, lng: -8.707976979216275 },//

  { lat: 52.6501095928513, lng: -8.723086985522917 },//
  { lat: 52.66517293968288, lng: -8.728686238126208 },//
  { lat: 52.68012620692875, lng: -8.718960220013141 },//
  { lat: 52.68783173675282, lng: -8.697599462630636 },//
  { lat: 52.68549979755501, lng: -8.672868290692653 },//
  { lat: 52.68108208914074, lng: -8.656438203211819 },//
  { lat: 52.75859070506176, lng: -8.524922185931395 },//
  { lat: 52.77791015119946, lng: -8.535226064666816 },//
  { lat: 52.80793472545295, lng: -8.553589172214833 },//
  { lat: 52.8412100890796, lng: -8.586883626597576 },//
  { lat: 52.8746799589504, lng: -8.630941417221511 },//
  { lat: 52.89722671278355, lng: -8.673108886582465 },//
  { lat: 52.91824806394636, lng: -8.726050869074713 },//

  { lat: 52.9327564811665, lng: -8.775557716129951 },//
  { lat: 52.94810920690066, lng: -8.879850815483771 },//
  { lat: 52.94918794329266, lng: -8.927409460490702 },//
  { lat: 52.94661346614105, lng: -8.980914130423445 },//
  { lat: 52.93832882004636, lng: -9.04280464504154 },//
  { lat: 52.92204557497507, lng: -9.109333627691127 },//
  { lat: 52.9028741515891, lng: -9.16164226170967 },//
  { lat: 52.88442077378502, lng: -9.197447115357019 },//
  { lat: 52.8659270797616, lng: -9.228459185862826 },//

  { lat: 52.84658230377009, lng: -9.254344591597889 },//
  { lat: 52.82307583458755, lng: -9.279465989457687 },//
  { lat: 52.80093865643448, lng: -9.29730562641813 },//

  { lat: 52.77235963292918, lng: -9.315101751275119 },//

  { lat: 52.74097888579386, lng: -9.325364821156413 },//

  { lat: 52.71237321425406, lng: -9.33053613435866 },//
  { lat: 52.68301345566501, lng: -9.329651546950037 },//
  { lat: 52.65434881449917, lng: -9.324014537290564 },//
  { lat: 52.61636347164827, lng: -9.307610756688234 },//
  { lat: 52.5941360711731, lng: -9.29117544460545 },//
  { lat: 52.5719227386573, lng: -9.271135448848952 },//
  { lat: 52.54836831804419, lng: -9.244901500230792 },//
  { lat: 52.5304588827971, lng: -9.219040482074277 },//
  { lat: 52.50738831083155, lng: -9.177085048702908 },//

  { lat: 52.49110435842333, lng: -9.137859897763361 },//
  { lat: 52.47645791035886, lng: -9.093781528675253 },//
  { lat: 52.46599821884799, lng: -9.049689695771733 },//
  { lat: 52.45725029721109, lng: -8.999217181383619 },//
  { lat: 52.45420957475667, lng: -8.952250515631032 },//
  { lat: 52.4538585902662, lng: -8.890064935072555 },//
  { lat: 52.45862177349281, lng: -8.838320562678268 },//
  { lat: 52.46700908333717, lng: -8.791213033719492 },//
  { lat: 52.48285791205252, lng: -8.73460865806782 },//
  { lat: 52.4947111430418, lng: -8.699131001070786 },//

  { lat: 52.50315073981575, lng: -8.678985857430519 }//repeat

];

const triangleCoords4 = [

  { lat: 53.5807175297655, lng: -5.904854716470979 },
  { lat: 53.5686439358343, lng: -5.890539632077031 },
  { lat: 53.55707670856486, lng: -5.88045740208515 },
  { lat: 53.54601032078326, lng: -5.868684353922734 },
  { lat: 53.53495129831663, lng: -5.862837766309418 },
  { lat: 53.52489630513161, lng: -5.856141713527597 },
  { lat: 53.51534719600003, lng: -5.851133345413673 },
  { lat: 53.50579871553384, lng: -5.846972269707743 },
  { lat: 53.49323874306686, lng: -5.842833415257918 },
  { lat: 53.48419635088383, lng: -5.84035985447268 },
  { lat: 53.4756547739992, lng: -5.837040156356011 },
  { lat: 53.4636008324483, lng: -5.83374231496488 },
  { lat: 53.4530573196361, lng: -5.832970019536932 },
  { lat: 53.44452233663757, lng: -5.832181343818359 },
  { lat: 53.43398400094682, lng: -5.833938155008932 },
  { lat: 53.42344456374175, lng: -5.834848686209949 },
  { lat: 53.41240510243315, lng: -5.835761759815813 },
  { lat: 53.40086706661516, lng: -5.838359719920478 },
  { lat: 53.39033130496961, lng: -5.840107633078389 },
  { lat: 53.37879841134459, lng: -5.845227009430905 },
  { lat: 53.36876977467533, lng: -5.84949312042832 },
  { lat: 53.35723913775293, lng: -5.855449487665526 },
  { lat: 53.34771459088083, lng: -5.860549993199774 },
  { lat: 53.33768908135924, lng: -5.866488713530694 },
  { lat: 53.32716655156955, lng: -5.874950522088921 },
  { lat: 53.31814709443955, lng: -5.881717798330413 },
  { lat: 53.30863072889436, lng: -5.89184565551286 },
  { lat: 53.3001147797049, lng: -5.901125118496333 },
  { lat: 53.2890934187726, lng: -5.9129267633678 },
  { lat: 53.28007607427427, lng: -5.92303556821223 },
  { lat: 53.27106145023543, lng: -5.935657804270357 },
  { lat: 53.26405095122818, lng: -5.946591215710153 },
  { lat: 53.25653608080741, lng: -5.957517561022926 },
  { lat: 53.24802141344241, lng: -5.971800340336802 },
  { lat: 53.24102881383679, lng: -5.984432449907297 },
  { lat: 53.23551893313422, lng: -5.997016099621066 },
  { lat: 53.22750068887325, lng: -6.013789563020888 },
  { lat: 53.22249111419909, lng: -6.028043505547437 },
  { lat: 53.21798115220783, lng: -6.041453661121866 },
  { lat: 53.21397173850366, lng: -6.054024281752052 },
  { lat: 53.21046355572361, lng: -6.066593521332702 },
  { lat: 53.20594936095492, lng: -6.079158810230423 },
  { lat: 53.20243788684323, lng: -6.091722511661205 },
  { lat: 53.19747938654034, lng: -6.110188919806738 },
  { lat: 53.19553191577414, lng: -6.124459291212484 },
  { lat: 53.19205887067821, lng: -6.14037747105545 },
  { lat: 53.19013179022784, lng: -6.156308764710611 },
  { lat: 53.18822184336206, lng: -6.172231398586288 },
  { lat: 53.18578105478691, lng: -6.189796165337386 },
  { lat: 53.18545845611349, lng: -6.207374475326214 },
  { lat: 53.18360402924515, lng: -6.224075630982804 },
  { lat: 53.18403536297415, lng: -6.245754779454752 },
  { lat: 53.18412358588011, lng: -6.259924404118069 },
  { lat: 53.18474568880863, lng: -6.277406683636372 },
  { lat: 53.18504018389061, lng: -6.292325572456877 },
  { lat: 53.18590739392501, lng: -6.304687868098792 },
  { lat: 53.18613250366422, lng: -6.316220576951295 },
  { lat: 53.18672037183039, lng: -6.328608699392989 },
  { lat: 53.18763852464934, lng: -6.337752223344342 },
  { lat: 53.18811630859888, lng: -6.347697394868438 },
  { lat: 53.18916785658926, lng: -6.356774466526272 },
  { lat: 53.19221091932673, lng: -6.363372339334773 },//start
  { lat: 53.244, lng: -6.358 },//bottom
  { lat: 53.243, lng: -6.618 },//
  { lat: 53.367, lng: -6.710 },//


  { lat: 53.360, lng: -6.644 },//
  //    {lat:53.36465752019105,lng:-6.638070073710455},
  { lat: 53.37620986084443, lng: -6.638070073710455 },
  { lat: 53.38576063995152, lng: -6.6323089652838 },
  { lat: 53.39634373215419, lng: -6.619819309940349 },
  { lat: 53.39266990902789, lng: -6.538964393430949 },
  { lat: 53.4051864711603, lng: -6.540750436585681 },
  { lat: 53.41619813573922, lng: -6.542525212980149 },
  { lat: 53.43071543353798, lng: -6.543487248599337 },//end
  { lat: 53.44323954831517, lng: -6.542782219070618 },
  { lat: 53.45476767967212, lng: -6.541235838976464 },
  { lat: 53.46780285084145, lng: -6.538865198320134 },
  { lat: 53.48033842698649, lng: -6.533934394203275 },
  { lat: 53.49337702350842, lng: -6.529016300740085 },
  { lat: 53.50642952229287, lng: -6.519882930207378 },
  { lat: 53.51847734081867, lng: -6.51157998783265 },
  { lat: 53.53205079672607, lng: -6.498243622835229 },
  { lat: 53.54061495120909, lng: -6.486543282760604 },
  { lat: 53.55369972701601, lng: -6.472356201132763 },
  { lat: 53.56228929899791, lng: -6.453899664192752 },
  { lat: 53.568343129616, lng: -6.439611553978705 },
  { lat: 53.57897081525918, lng: -6.419495649265377 },//top curve left
  { lat: 53.5807175297655, lng: -5.904854716470979 }
];

const curragh = [
  { lat: 53.15444444, lng: -6.87972222 },
  { lat: 53.16194444, lng: -6.82416667 },
  { lat: 53.15000000, lng: -6.80444444 },
  { lat: 53.13027778, lng: -6.79972222 },
  { lat: 53.14750000, lng: -6.87916667 },
  { lat: 53.15444444, lng: -6.87972222 }
]

const kerryCoords = [
  {"lat":52.10155555555556,"lng":-9.76175},
  {"lat":52.09908333333333,"lng":-9.745861111111111},
  {"lat":52.10177777777778,"lng":-9.725277777777778},
  {"lat":52.10080555555556,"lng":-9.711944444444443},
  {"lat":52.07508333333334,"lng":-9.6465},
  {"lat":52.06769444444445,"lng":-9.52288888888889},
  {"lat":52.069972222222226,"lng":-9.510277777777778},
  {"lat":52.06855555555556,"lng":-9.500277777777777},
  {"lat":52.063138888888886,"lng":-9.486638888888889},
  {"lat":52.05072222222222,"lng":-9.431333333333333},
  {"lat":52.05997222222222,"lng":-9.338111111111111},

  {"lat":52.180878,"lng":-9.52378463888889},

  {"lat":52.33255555555556,"lng":-9.634555555555554},
  {"lat":52.24327777777778,"lng":-9.643833333333333},
  {"lat":52.24433333333334,"lng":-9.774472222222222},

  {"lat":52.180878,"lng":-9.52378463888889},

  {"lat":52.10155555555556,"lng":-9.76175},

]

export const data = [ 
  {
      "city": "Currency Centre Sandyford",
      "city_latlng": [53.273555555555554, -6.231777777777778],
      "circle": {
        "latlng": [53.273555555555554, -6.231777777777778],
        "radius": 500,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Currency Centre Sandyford</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    }, 
    {
      "city": "Mountjoy Prison",
      "city_latlng": [
        53.360762,
        -6.268347
      ],
      "circle": {
        "latlng": [
          53.360762,
          -6.268347
        ],
        "radius": 900,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Mountjoy Prison</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Irish Prison Service permission required.</td></tr><tr><td>SOP / PCC Holders</td><td>Irish Prison Service permission required.</td></tr></table>"
    },
    {
      "city": "Leinster Model Flying Club",
      "city_latlng": [53.35638888888889, -6.3405555555555555],
      "top":true,
      "circle": {
        "latlng": [53.35638888888889, -6.3405555555555555],
        "radius": 300,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Leinster Model Flying Club</h3></strong></td></tr><tr><tr><td colspan='2'>Leinster Model Flying Club. Model Aircraft are exempt from the restrictions of the UAS Geographical Zone within which the exemption zone resides.</td></tr></table>"
    },
    {
      "city": "Phoenix Park",
      "city_latlng": [
        53.355581,
        -6.329760
      ],
      "circle": {
        "latlng": [
          53.355581,
          -6.329760
        ],
        "radius": 1852,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Phoenix Park</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Dept of Justice permission required. </td></tr><tr><td>SOP / PCC Holders</td><td>Dept of Justice permission required. </td></tr></table>"
    },
    {
      "city": "Kilmainham",
      "city_latlng": [53.343, -6.3021666666666665],
      "circle": {
        "latlng": [53.343, -6.3021666666666665],
        "radius": 800,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Royal Hospital Kilmainham</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    }, 
    {
      "city": "Tallaght University Hospital",
      "city_latlng": [53.2907, -6.3782],
      "circle": {
        "latlng": [53.2907, -6.3782],
        "radius": 800,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Tallaght University Hospital</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "IPS BSD",
      "city_latlng": [53.4069, -6.2368194444444445],
      "circle": {
        "latlng": [53.4069, -6.2368194444444445],
        "radius": 500,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>IPS BSD</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "IPS HQ",
      "city_latlng": [53.73328611111111, -7.774977777777778],
      "circle": {
        "latlng": [53.73328611111111, -7.774977777777778],
        "radius": 500,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>IPS HQ</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    // {
    //   "city": "Casement Aerodrome",
    //   "city_latlng": [53.30299194444444, -6.455406138888889],
    //   "circle": {
    //     "latlng": [53.30299194444444, -6.455406138888889],
    //     "radius": 5000,
    //     "options": {
    //       "stroke": false,
    //       "strokeOpacity": 0.8,
    //       "strokeWeight": 0,
    //       "fillColor": "#FF0000",
    //       "fillOpacity": 0.5
    //     }
    //   },
    //   "info": "<table border='3'><tr><td colspan='2'><strong><h3>Casement Aerodrome</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    // }, 
    {
      "city": "Newcastle Aerodrome",
      "city_latlng": [53.07277777777778, -6.036388888888888],
      "circle": {
        "latlng": [53.07277777777778, -6.036388888888888],
        "radius": 2778,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Newcastle Aerodrome</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS G - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by contacting John Nugent at Newcastle Aerodome on 0862394417</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by contacting John Nugent at Newcastle Aerodome on 0862394417</td></tr></table>"
    }, 
    {
      "city": "Custume Barracks",
      "city_latlng": [53.42444444444444, -7.947777777777778],
      "circle": {
        "latlng": [53.42444444444444, -7.947777777777778],
        "radius": 3704,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Custume Barracks</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS G - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by contacting the IAA</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by contacting the IAA</td></tr></table>"
    },
    {
      "city": "Cathal Brugha Barracks",
      "city_latlng": [53.327939, -6.270469],
      "circle": {
        "latlng": [53.327939, -6.270469],
        "radius": 371,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Cathal Brugha Barracks</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Beaumont Hospital",
      "city_latlng": [53.3904, -6.2233],
      "circle": {
        "latlng": [53.3904, -6.2233],
        "radius": 800,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Beaumont Hospital</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Weston Airport",
      "city_latlng": [53.352222222222224, -6.488333333333333],
      "circle": {
        "latlng": [53.352222222222224, -6.488333333333333],
        "radius": 3000,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Weston Airport</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Dublin Airport Terrain - Ward Upper, Dublin",
      "city_latlng": [53.43947813888889, -6.342306027777777],
      "circle": {
        "latlng": [53.43947813888889, -6.342306027777777],
        "radius": 650,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Dublin Airport Terrain - Ward Upper, Dublin</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    }, 
    {
      "city": "Dublin Airport Terrain - Cloghran, Dublin",
      "city_latlng": [53.412841, -6.336483416666667],
      "circle": {
        "latlng": [53.412841, -6.336483416666667],
        "radius": 1500,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Dublin Airport Terrain - Cloghran, Dublin</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Dublin Airport Terrain - Dunsink, Dublin",
      "city_latlng": [53.39054619444445, -6.337846],
      "circle": {
        "latlng": [53.39054619444445, -6.337846],
        "radius": 400,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Dublin Airport Terrain - Dunsink, Dublin</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Dublin Airport - Red Zone",
      "city_latlng": [53.42892358333333, -6.265767194444445],
      "circle": {
        "latlng": [53.42892358333333, -6.265767194444445],
        "radius": 5000,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Dublin Airport - Red Zone</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Operational Authorisation and Air Traffic Control permission required</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Waterford Control Zone (CTR)",
      "city_latlng": [52.18719997222222, -7.08696275],
      "circle": {
        "latlng": [52.18719997222222, -7.08696275],
        "radius": 18520,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Waterford Control Zone (CTR)</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Shannon Control Zone (CTR)",
      "city_latlng": [52.70197641666667, -8.924815666666666],
      "circle": {
        "latlng": [52.70197641666667, -8.924815666666666],
        "radius": 6000,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Shannon Control Zone (CTR)</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Connaught Control Zone (CTR)",
      "city_latlng": [53.91029688888889, -8.818491],
      "circle": {
        "latlng": [53.91029688888889, -8.818491],
        "radius": 4500,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Connaught Control Zone (CTR)</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Cork Control Zone (CTR)",
      "city_latlng": [51.84126866666667, -8.491112472222222],
      "circle": {
        "latlng": [51.84126866666667, -8.491112472222222],
        "radius": 4500,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Cork Control Zone (CTR) - Red Zone</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Kerry Control Zone (CTR)",
      "city_latlng": [52.180833, -9.523888],
      "circle": {
        "latlng": [52.180833, -9.523888],
        "radius": 0,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FFFF00",
          "fillOpacity": 0.30
        }
      },
      "polygon": {
        "latlng": triangleCoords3,
        "options": {
          "stroke": false,
          "strokeColor": "#FF0000",
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Kerry Control Zone (CTR)</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Arbour Hill Prison",
      "city_latlng": [53.35015833333333, -6.288102777777778],
      "circle": {
        "latlng": [53.35015833333333, -6.288102777777778],
        "radius": 800,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Arbour Hill Prison</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Castlerea Prison",
      "city_latlng": [53.754108333333335, -8.487151944444443],
      "circle": {
        "latlng": [53.754108333333335, -8.487151944444443],
        "radius": 800,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Castlerea Prison</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Cloverhill & Wheatfield Prison",
      "city_latlng": [53.34109166666667, -6.383033333333333],
      "circle": {
        "latlng": [53.34109166666667, -6.383033333333333],
        "radius": 800,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Cloverhill & Wheatfield Prison</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Cork Prison",
      "city_latlng": [51.909277777777774, -8.459991666666665],
      "circle": {
        "latlng": [51.909277777777774, -8.459991666666665],
        "radius": 800,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Cork Prison</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Loughan House",
      "city_latlng": [54.28870555555555, -7.91565],
      "circle": {
        "latlng": [54.28870555555555, -7.91565],
        "radius": 800,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Loughan House</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS G - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by contacting the IAA</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by contacting the IAA</td></tr></table>"
    },
    {
      "city": "Shelton Abbey",
      "city_latlng": [52.815725, -6.190419444444444],
      "circle": {
        "latlng": [52.815725, -6.190419444444444],
        "radius": 800,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Shelton Abbey</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS G - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by contacting the IAA</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by contacting the IAA</td></tr></table>"
    },
    {
      "city": "Balheary Model Flying Club",
      "city_latlng": [53.50611111111111, -6.235277777777778],
      "circle": {
        "latlng": [53.50611111111111, -6.235277777777778],
        "radius": 800,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Balheary Model Flying Club</h3></strong></td></tr><tr><tr><td colspan='2'>Balheary Model Flying Club. Model Aircraft are exempt from the restrictions of the UAS Geographical Zone within which the exemption zone resides.</td></tr></table>"
    },
    {
      "city": "Fingal Model Flying Club",
      "city_latlng": [53.5375, -6.0841666666666665],
      "circle": {
        "latlng": [53.5375, -6.0841666666666665],
        "radius": 800,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Fingal Model Flying Club</h3></strong></td></tr><tr><tr><td colspan='2'>Fingal Model Flying Club. Model Aircraft are exempt from the restrictions of the UAS Geographical Zone within which the exemption zone resides.</td></tr></table>"
    },
    {
      "city": "Island Slope Rebels Club",
      "city_latlng": [53.225, -6.318333333333333],
      "circle": {
        "latlng": [53.225, -6.318333333333333],
        "radius": 800,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Island Slope Rebels Club</h3></strong></td></tr><tr><tr><td colspan='2'>Island Slope Rebels Club. Model Aircraft are exempt from the restrictions of the UAS Geographical Zone within which the exemption zone resides. </td></tr></table>"
    },
    {
      "city": "Blacksod Refuelling Base",
      "city_latlng": [54.098333333333336, -10.063333333333334],
      "circle": {
        "latlng": [54.098333333333336, -10.063333333333334],
        "radius": 1000,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Blacksod Refuelling Base</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS G - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by contacting the IAA</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by contacting the IAA</td></tr></table>"
    },
    {
      "city": "Castletown Refuelling Base",
      "city_latlng": [51.651666666666664, -9.895833333333332],
      "circle": {
        "latlng": [51.651666666666664, -9.895833333333332],
        "radius": 1000,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Castletown Refuelling Base</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS G - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by contacting the IAA</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by contacting the IAA</td></tr></table>"
    },
    {
      "city": "Galway University Hospital",
      "city_latlng": [53.276666666666664, -9.069166666666666],
      "circle": {
        "latlng": [53.276666666666664, -9.069166666666666],
        "radius": 1000,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Galway University Hospital</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS G - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by contacting the IAA</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by contacting the IAA</td></tr></table>"
    },
    {
      "city": "Kerry University Hospital",
      "city_latlng": [52.26444444444444, -9.686111111111112],
      "circle": {
        "latlng": [52.26444444444444, -9.686111111111112],
        "radius": 1000,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Kerry University Hospital</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS G - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by contacting the IAA</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by contacting the IAA</td></tr></table>"
    },
    {
      "city": "Letterkenny University Hospital",
      "city_latlng": [54.96194444444445, -7.7347222222222225],
      "circle": {
        "latlng": [54.96194444444445, -7.7347222222222225],
        "radius": 1000,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Letterkenny University Hospital</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS G - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by contacting the IAA</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by contacting the IAA</td></tr></table>"
    },
    {
      "city": "Limerick University Hospital",
      "city_latlng": [52.632777777777775, -8.651388888888889],
      "circle": {
        "latlng": [52.632777777777775, -8.651388888888889],
        "radius": 1000,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Limerick University Hospital</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS G - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by contacting the IAA</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by contacting the IAA</td></tr></table>"
    },
    {
      "city": "Mayo University Hospital",
      "city_latlng": [53.85, -9.301944444444445],
      "circle": {
        "latlng": [53.85, -9.301944444444445],
        "radius": 1000,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Mayo University Hospital</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS G - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by contacting the IAA</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by contacting the IAA</td></tr></table>"
    },
    {
      "city": "Cork Control Zone (CTR) - Amber Zone",
      "city_latlng": [51.841269, -8.491112],
      "circle": {
        "latlng": [51.841269, -8.491112],
        "radius": 27780,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FFBF00",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Cork Control Zone (CTR) - Amber Zone</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Height Constraint - Amber Zone</td></tr><tr><td>Open Category Pilot</td><td>Height restriction of 15m agl.</td></tr><tr><td>Specific / Certified Pilots</td><td>As per the Open catgeory. In all other cases, Operational Authorisation and Air Traffic Control permission required (submit form UF101).</td></tr><tr><td>SOP / PCC Holders</td><td>As per the Open catgeory. In all other cases Air Traffic Control permission required (submit form UF101).</td></tr><tr><td colspan='2'>15m excluding Cork Control Zone (CTR) - Red Zone</td></tr></table>"
    },
    {
      "city": "Kerry Control Zone (CTR) - Amber Zone",
      "city_latlng": [52.180833, -9.523888],
      "circle": {
        "latlng": [52.180833, -9.523888],
        "radius": 18520,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FFBF00",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Kerry Control Zone (CTR) - Amber Zone</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Height Constraint - Amber Zone</td></tr><tr><td>Open Category Pilot</td><td>Height restriction of 15m agl.</td></tr><tr><td>Specific / Certified Pilots</td><td>As per the Open catgeory. In all other cases, Operational Authorisation and Air Traffic Control permission required (submit form UF101).</td></tr><tr><td>SOP / PCC Holders</td><td>As per the Open catgeory. In all other cases Air Traffic Control permission required (submit form UF101). </td></tr><tr><td colspan='2'>15m excluding Kerry Control Zone (CTR) - Red Zone</td></tr></table>"
    },
    {
      "city": "Connaught Control Zone (CTR) - Amber Zone",
      "city_latlng": [53.910297, -8.818491],
      "circle": {
        "latlng": [53.910297, -8.818491],
        "radius": 18520,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FFBF00",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Connaught Control Zone (CTR) - Amber Zone</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - Connaught Control Zone (CTR) - Amber Zone</td></tr><tr><td>Open Category Pilot</td><td>Height restriction of 15m agl</td></tr><tr><td>Specific / Certified Pilots</td><td>As per the Open catgeory. In all other cases, Operational Authorisation and Air Traffic Control permission required (submit form UF101)</td></tr><tr><td>SOP / PCC Holders</td><td>As per the Open catgeory. In all other cases Air Traffic Control permission required (submit form UF101)</td></tr><tr><td colspan='2'>15m excluding Connaught Control Zone (CTR) - Red Zone</td></tr></table>"
    },
    {
      "city": "Shannon Control",
      "city_latlng": [52.701976, -8.924816],
      "circle": {
        "latlng": [52.701976, -8.924816],
        "radius": 27780,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FFBF00",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Shannon Control Zone (CTR) - Amber Zone</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - Shannon Control</td></tr><tr><td>Open Category Pilot</td><td>Height restriction of 15m agl</td></tr><tr><td>Specific / Certified Pilots</td><td>As per the Open catgeory. In all other cases, Operational Authorisation and Air Traffic Control permission required (submit form UF101)</td></tr><tr><td>SOP / PCC Holders</td><td>As per the Open catgeory. In all other cases  Air Traffic Control permission required (submit form UF101)</td></tr><tr><td colspan='2'>15m excluding Shanno Control Zone (CTR) - Red Zone</td></tr></table>"
    },
    {
      "city": "Dublin Airport - Amber Zone",
      "city_latlng": [53.42892358333333, -6.265767194444445],
      "circle": {
        "latlng": [53.42892358333333, -6.265767194444445],
        "radius": 12100,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FFBF00",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Dublin Airport - Amber Zone</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Height Constraint</td></tr><tr><td>Open Category Pilots</td><td>Height Restriction of 30m or the height of the tallest obstacle within 50m of the obstacle where the maximum height may be increased up to 15M above the height of the obstacle at the request of the entity responsible for the obstacle.</td></tr><tr><td>Specific / Certified Pilots</td><td>As per Open category unless otherwise authorised. In all other cases, Operational Authorisation and Air Traffic Control permission required (submit form UF101).</td></tr><tr><td>SOP / PCC Holders</td><td> As per Open category unless otherwise authorised. In all other cases Air Traffic Control permission required (submit form UF101).</td></tr><tr><td colspan='2'>Excluding UAS Prohibited Zones. The greater of 30M AGL or the height of the tallest obstacle within 50M. The maximum height may be increased up to 15M above the height of the obstacle at the request of the entity responsible for the obstacle.</td></tr></table>"
    },
    {
      "city": "Donegal Control Zone (CTR)",
      "city_latlng": [
        55.044166,
        -8.341111
      ],
      "circle": {
        "latlng": [
          55.044166,
          -8.341111
        ],
        "radius": 18000,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Donegal Control Zone (CTR)</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Casement",
      "city_latlng": [53.303055, -6.455277],
      "circle": {
        "latlng": [53.303055, -6.455277],
        "radius": 5000,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": '#FF0000',
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Restricted Area 23</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Prohibited</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Military & IAA permission required</td></tr><tr><td>SOP / PCC Holders</td><td>Military & IAA permission required</td></tr></table>"
    },
    {
      "city": "CasementEIR",
      "city_latlng": [53.303055, -6.455277],
      "polygon": {
        "latlng": triangleCoords2,
  
        "options": {
          "stroke": false,
          "strokeColor": "#FF0000",
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#ff8c00",
          "fillOpacity": 0.50
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Restricted Area EIR15</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Height Constraint</td></tr><tr><td>Open Category Pilots</td><td>Flying is permitted with a height restriction of 8m</td></tr><tr><td>Specific / Certified Pilots</td><td>As per the Open catgeory. In all other cases, Operational Authorisation and Air Traffic Control permission required (submit form UF101).</td></tr><tr><td>SOP / PCC Holders</td><td>As per the Open catgeory. In all other cases, Operational Authorisation and Air Traffic Control permission required (submit form UF101).</td></tr><tr><td colspan='2'>When EIR 15 is active.</td></tr></table>"
    },
  
    {
      "city": "County Sligo",
      "city_latlng": [
        54.280277,
        -8.599166
      ],
      "circle": {
        "latlng": [
          54.280277,
          -8.599166
        ],
        "radius": 18000,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Sligo Control Zone - County Sligo</h3></strong></td></tr><tr><td>Airspace Type</td><td>CLASS C - UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by submitting a UF101 to ATC</td></tr></table>"
    },
    {
      "city": "Curragh Military Base",
      "city_latlng": [
        53.16194444,
        -6.82416667
      ],
      "polygon": {
        "latlng": curragh,
        "top": true,
        "options": {
          "stroke": false,
          "strokeColor": "#FF0000",
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.50
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Curragh Military Base</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Military permission and permission from the Irish Aviation Authority required.</td></tr><tr><td>SOP / PCC Holders</td><td>Military permission and permission from the Irish Aviation Authority required.</td></tr></table>"
    },
    {
      "city": "Limerick Prison",
      "city_latlng": [
        52.659582,
        -8.617937
      ],
      "circle": {
        "latlng": [
          52.659582,
          -8.617937
        ],
        "radius": 1852,
        "top":true,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Limerick Prison</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Irish Prison Service permission and permission from the Irish Aviation Authority required.</td></tr><tr><td>SOP / PCC Holders</td><td>Irish Prison Service permission and permission from the Irish Aviation Authority required.</td></tr></table>"
    },
    {
      "city": "Portlaoise Prison EUI8",
      "city_latlng": [
        53.037289,
        -7.288355
      ],
      "circle": {
        "latlng": [
          53.037289,
          -7.288355
        ],
        "radius": 3704,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Portlaoise Prison, Laois</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Permission can be obtained by contacting the prison</td></tr><tr><td>SOP / PCC Holders</td><td>Permission can be obtained by contacting the prison</td></tr></table>"
    },
    {
      "city": "Dublin",
      "city_latlng": [
        53.421388,
        -6.270000
      ],
      "polygon": {
        "latlng": triangleCoords4,
        "options": {
          "stroke": false,
          "strokeColor": "#00FF00",
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#00FF00",
          "fillOpacity": 0.30
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Dublin Airport - Green Zone</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Height Constraint - Dublin Airport - Green Zone</td></tr><tr><td>Open Category Pilots</td><td>Height restriction of 90m agl.</td></tr><tr><td>Specific / Certified Pilots</td><td> As per the Open catgeory. In all other cases, Operational Authorisation and Air Traffic Control permission required (submit form UF101)</td></tr><tr><td>SOP / PCC Holders</td><td>As per the Open catgeory. In all other cases Air Traffic Control permission required (submit form UF101)</td></tr><tr><td colspan='2'>Excluding Dublin Airport Amber Zone.</td></tr></table>"
    },
    // {
    //   "city": "Danger Area 1",
    //   "city_latlng": [53.6895,-6.203817],
    //   "circle": {
    //     "latlng": [53.6895, -6.203817],
    //     "radius": 500,
    //     "options": {
    //       "stroke": false,
    //       "strokeOpacity": 0.8,
    //       "strokeWeight": 0,
    //       "fillColor": "#FFFF00",
    //       "fillOpacity": 0.5
    //     }
    //   },
    //   "polygon": {
    //     "latlng": gormanstown,
    //     "options": {
    //       "stroke": false,
    //       "strokeColor": "#00FF00",
    //       "strokeOpacity": 0.8,
    //       "strokeWeight": 0,
    //       "fillColor": "#00FF00",
    //       "fillOpacity": 0.5
    //     }
    //   },
    //   "info": "<table border='3'><tr><td colspan='2'><strong><h3>Danger Area EID1, Gormanston, Meath</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is permitted when area is not active</td></tr><tr><td>Specific / Certified Pilots</td><td>Flying is permitted when area is not active</td></tr><tr><td>SOP / PCC Holders</td><td>Flying is permitted when area is not active</td></tr></table>"
    // }, 
    {
      "city": "Danger Area 5",
      "city_latlng": [53.041333, -6.403167],
      "polygon": {
        "latlng": imal,
        "options": {
          "stroke": false,
          "strokeColor": "#FF0000",
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Danger Area EID5, Glen of Imaal, Wicklow</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is permitted when area is not active</td></tr><tr><td>Specific / Certified Pilots</td><td>Flying is permitted when area is not active</td></tr><tr><td>SOP / PCC Holders</td><td>Flying is permitted when area is not active</td></tr></table>"
    }, 
    {
      "city": "Danger Area 6",
      "city_latlng": [52.233333,-8.250833],
      "polygon": {
        "latlng": kilworth,
        "options": {
          "stroke": false,
          "strokeColor": "#FF0000",
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Danger Area EID6, Kilworth, Cork</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is permitted when area is not active</td></tr><tr><td>Specific / Certified Pilots</td><td>Flying is permitted when area is not active</td></tr><tr><td>SOP / PCC Holders</td><td>Flying is permitted when area is not active</td></tr></table>"
    }, 
    {
      "city": "Sea/Coastal Area SSW of Cork",
      "city_latlng": [51.568667, -8.706],
      "polygon": {
        "latlng": sea,
        "options": {
          "stroke": false,
          "strokeColor": "#FF0000",
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Sea/Coastal Area SSW of Cork EID13</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is permitted when area is not active</td></tr><tr><td>Specific / Certified Pilots</td><td>Flying is permitted when area is not active</td></tr><tr><td>SOP / PCC Holders</td><td>Flying is permitted when area is not active</td></tr><tr><td colspan='2'>Excluding High Seas (Waters greater than 12nm from coastline)</td></tr></table>"
    }, 
    {
      "city": "Sea/Coastal Area SSW of Cork",
      "city_latlng": [52.233333,-8.250833],
      "polygon": {
        "latlng": seaKer,
        "options": {
          "stroke": false,
          "strokeColor": "#FF0000",
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Sea Area SW of Kerry EID14</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Prohibited Zone</td></tr><tr><td>Open Category Pilots</td><td>Flying is permitted when area is not active</td></tr><tr><td>Specific / Certified Pilots</td><td>Flying is permitted when area is not active</td></tr><tr><td>SOP / PCC Holders</td><td>Flying is permitted when area is not active</td></tr><tr><td colspan='2'>Excluding High Seas (Waters greater than 12nm from coastline)</td></tr></table>"
    }, 
    {
      "city": "Finner Camp",
      "city_latlng": [54.492222, -8.244444],
      "circle": {
        "latlng": [54.492222, -8.244444],
        "radius": 1800,
        "options": {
          "stroke": false,
          "strokeOpacity": 0.8,
          "strokeWeight": 0,
          "fillColor": "#FF0000",
          "fillOpacity": 0.5
        }
      },
      "info": "<table border='3'><tr><td colspan='2'><strong><h3>Restricted Area EIR22 - Finner Camp</h3></strong></td></tr><tr><td>Airspace Type</td><td>UAS Prohibited - Finner Camp</td></tr><tr><td>Open Category Pilots</td><td>Flying is Prohibited</td></tr><tr><td>Specific / Certified Pilots</td><td>Flying is permitted when area is not active - Contact Military</td></tr><tr><td>SOP / PCC Holders</td><td>Flying is permitted when area is not active - Contact Military</td></tr></table>"
    }
  

]
