export const notams = [
  {
    "_id": "5ce4d484ab233e8499cdb6ea",
    "id": "B0057/19",
    "entity": "FA",
    "status": "XX",
    "Qcode": "FAXX",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Other",
    "Subject": "Aerodrome",
    "Modifier": "Plain language",
    "message": "AD UNLICENCED\nCREATED: 22 Jan 2019 10:51:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-01-22T10:50:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B0057/19 NOTAMN\nQ) EISN/QFAXX/IV/NBO/A /000/999/5335N00644W005\nA) EITM B) 1901221050 C) PERM\nE) AD UNLICENCED\nCREATED: 22 Jan 2019 10:51:00 \nSOURCE: EUECYIYN",
    "location": "EITM",
    "isICAO": true,
    "Created": "2019-01-22T10:51:00.000Z",
    "key": "B0057/19-EITM",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6eb",
    "id": "A1510/19",
    "entity": "FA",
    "status": "HW",
    "Qcode": "FAHW",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Hazard Conditions",
    "Subject": "Aerodrome",
    "Modifier": "Work in progress",
    "message": "EIME WIP. REF AIP SUPPLEMENT NR 12/18\nPIPE LAYING WORKS ON AND ADJACENT TO CASEMENT AERODROME (EIME). \nCLOSE IN OBSTACLES MAY EXIST FOR RWY 29, RWY 23 DEPARTURES AND \nAPPROACHES TO RWY 11\nREF AIP AD EIME\nCREATED: 29 Apr 2019 09:26:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-29T09:24:00.000Z",
    "enddate": "2019-07-29T19:00:00.000Z",
    "all": "A1510/19 NOTAMR A0289/19\nQ) EISN/QFAHW/IV/NBO/A /000/999/5318N00627W005\nA) EIME B) 1904290924 C) 1907291900 EST\nD) DAILY 0700-1900\nE) EIME WIP. REF AIP SUPPLEMENT NR 12/18\nPIPE LAYING WORKS ON AND ADJACENT TO CASEMENT AERODROME (EIME). \nCLOSE IN OBSTACLES MAY EXIST FOR RWY 29, RWY 23 DEPARTURES AND \nAPPROACHES TO RWY 11\nREF AIP AD EIME\nCREATED: 29 Apr 2019 09:26:00 \nSOURCE: EUECYIYN",
    "location": "EIME",
    "isICAO": true,
    "Created": "2019-04-29T09:26:00.000Z",
    "key": "A1510/19-EIME",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6ec",
    "id": "N0055/19",
    "entity": "OB",
    "status": "CE",
    "Qcode": "OBCE",
    "Area": "Other Information",
    "SubArea": "Other Information",
    "Condition": "Changes",
    "Subject": "Obstacle",
    "Modifier": "Erected",
    "message": "OBSTACLE ERECTED. 2 TEMPORARY MOBILE CRANES OPERATING 1.5NM NORTH \nOF CASEMENT AERODROME, POSITION 531940.5N 0062704.6W. MAX HEIGHT 55M \nAGL. RADIUS 16M, OBSTACLES LIGHTED\nCREATED: 07 May 2019 18:21:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-08T06:00:00.000Z",
    "enddate": "2019-08-08T18:00:00.000Z",
    "all": "N0055/19 NOTAMN\nQ) EISN/QOBCE/IV/M  /AE/000/002/5319N00627W001\nA) EIME B) 1905080600 C) 1908081800\nD) DAILY 0600-1800\nE) OBSTACLE ERECTED. 2 TEMPORARY MOBILE CRANES OPERATING 1.5NM NORTH \nOF CASEMENT AERODROME, POSITION 531940.5N 0062704.6W. MAX HEIGHT 55M \nAGL. RADIUS 16M, OBSTACLES LIGHTED\nCREATED: 07 May 2019 18:21:00 \nSOURCE: EUECYIYN",
    "location": "EIME",
    "isICAO": true,
    "Created": "2019-05-07T18:21:00.000Z",
    "key": "N0055/19-EIME",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6ed",
    "id": "N0018/19",
    "entity": "OB",
    "status": "CE",
    "Qcode": "OBCE",
    "Area": "Other Information",
    "SubArea": "Other Information",
    "Condition": "Changes",
    "Subject": "Obstacle",
    "Modifier": "Erected",
    "message": "OBSTACLE ERECTED. 4 MOBILE CRANES OPERATING AT POSITION \n531741N 0062218W 3NM EAST OF CASEMENT AERODROME. MAX HEIGHT 78M AGL. \nRADIUS 16M. OBSTACLES LIGHTED\nCREATED: 18 Feb 2019 12:23:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-02-25T07:00:00.000Z",
    "enddate": "2019-05-25T19:00:00.000Z",
    "all": "N0018/19 NOTAMN\nQ) EISN/QOBCE/IV/M  /AE/000/003/5317N00622W001\nA) EIME B) 1902250700 C) 1905251900 EST\nD) DAILY 0700-1900\nE) OBSTACLE ERECTED. 4 MOBILE CRANES OPERATING AT POSITION \n531741N 0062218W 3NM EAST OF CASEMENT AERODROME. MAX HEIGHT 78M AGL. \nRADIUS 16M. OBSTACLES LIGHTED\nCREATED: 18 Feb 2019 12:23:00 \nSOURCE: EUECYIYN",
    "location": "EIME",
    "isICAO": true,
    "Created": "2019-02-18T12:23:00.000Z",
    "key": "N0018/19-EIME",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6ee",
    "id": "N0005/19",
    "entity": "OB",
    "status": "CE",
    "Qcode": "OBCE",
    "Area": "Other Information",
    "SubArea": "Other Information",
    "Condition": "Changes",
    "Subject": "Obstacle",
    "Modifier": "Erected",
    "message": "OBST ERECTED FOUR MOBILE CRANES OPERATING 200M EAST OF CASEMENT \nAERODROME AT POSITION 531738.557N 0062653.60W. MAX HEIGHT 45M \nAGL. RADIUS 16M. OBSTACLE LIT, OPERATING DURING DAYLIGHT HOURS\nCREATED: 09 Jan 2019 14:59:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-02-04T07:00:00.000Z",
    "enddate": "2019-05-31T17:00:00.000Z",
    "all": "N0005/19 NOTAMN\nQ) EISN/QOBCE/IV/M  /AE/000/002/5317N00627W001\nA) EIME B) 1902040700 C) 1905311700\nD) DAILY 0700-1700\nE) OBST ERECTED FOUR MOBILE CRANES OPERATING 200M EAST OF CASEMENT \nAERODROME AT POSITION 531738.557N 0062653.60W. MAX HEIGHT 45M \nAGL. RADIUS 16M. OBSTACLE LIT, OPERATING DURING DAYLIGHT HOURS\nCREATED: 09 Jan 2019 14:59:00 \nSOURCE: EUECYIYN",
    "location": "EIME",
    "isICAO": true,
    "Created": "2019-01-09T14:59:00.000Z",
    "key": "N0005/19-EIME",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6ef",
    "id": "A1837/19",
    "entity": "MT",
    "status": "XX",
    "Qcode": "MTXX",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Other",
    "Subject": "Threshold",
    "Modifier": "Plain language",
    "message": "RWY THR COORDINATES CHANGED TO:\nRWY 07 - 532101.47N 0062940.17W \nRWY 25 - 532115.04N 0062855.67W\nDATA WHOSE ACCURACY HAS NOT BEEN QUALITY CHECKED.\nREF EIWT AD 2.12 RUNWAY PHYSICAL CHARACTERISTICS.\nCREATED: 21 May 2019 16:52:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-21T16:51:00.000Z",
    "enddate": "2019-08-23T19:00:00.000Z",
    "all": "A1837/19 NOTAMR A0541/19\nQ) EISN/QMTXX/IV/NBO/A /000/999/5321N00629W005\nA) EIWT B) 1905211651 C) 1908231900\nE) RWY THR COORDINATES CHANGED TO:\nRWY 07 - 532101.47N 0062940.17W \nRWY 25 - 532115.04N 0062855.67W\nDATA WHOSE ACCURACY HAS NOT BEEN QUALITY CHECKED.\nREF EIWT AD 2.12 RUNWAY PHYSICAL CHARACTERISTICS.\nCREATED: 21 May 2019 16:52:00 \nSOURCE: EUECYIYN",
    "location": "EIWT",
    "isICAO": true,
    "Created": "2019-05-21T16:52:00.000Z",
    "key": "A1837/19-EIWT",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6f0",
    "id": "A1737/19",
    "entity": "FA",
    "status": "AH",
    "Qcode": "FAAH",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Availability",
    "Subject": "Aerodrome",
    "Modifier": "Hours of service",
    "message": "AERODROME HOURS OF OPS/SERVICE \nMON 0730-1700\nTUE 0730-1700\nWED 0730-1800\nTHU 0730-1800\nFRI 0730-1800\nSAT 0730-1700\nSUN 0800-1700\nCREATED: 15 May 2019 11:49:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-16T07:30:00.000Z",
    "enddate": "2019-05-31T18:00:00.000Z",
    "all": "A1737/19 NOTAMN\nQ) EISN/QFAAH/IV/NBO/A /000/999/5321N00629W005\nA) EIWT B) 1905160730 C) 1905311800\nE) AERODROME HOURS OF OPS/SERVICE \nMON 0730-1700\nTUE 0730-1700\nWED 0730-1800\nTHU 0730-1800\nFRI 0730-1800\nSAT 0730-1700\nSUN 0800-1700\nCREATED: 15 May 2019 11:49:00 \nSOURCE: EUECYIYN",
    "location": "EIWT",
    "isICAO": true,
    "Created": "2019-05-15T11:49:00.000Z",
    "key": "A1737/19-EIWT",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6f1",
    "id": "A1540/19",
    "entity": "MX",
    "status": "LC",
    "Qcode": "MXLC",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Limitations",
    "Subject": "Taxiway(s)",
    "Modifier": "Closed",
    "message": "TWY C2 CLSD\nCREATED: 30 Apr 2019 16:30:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-01T07:30:00.000Z",
    "enddate": "2019-06-18T20:00:00.000Z",
    "all": "A1540/19 NOTAMN\nQ) EISN/QMXLC/IV/M  /A /000/999/5321N00629W005\nA) EIWT B) 1905010730 C) 1906182000\nE) TWY C2 CLSD\nCREATED: 30 Apr 2019 16:30:00 \nSOURCE: EUECYIYN",
    "location": "EIWT",
    "isICAO": true,
    "Created": "2019-04-30T16:30:00.000Z",
    "key": "A1540/19-EIWT",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6f2",
    "id": "A1539/19",
    "entity": "FA",
    "status": "HW",
    "Qcode": "FAHW",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Hazard Conditions",
    "Subject": "Aerodrome",
    "Modifier": "Work in progress",
    "message": "TEMPORARY BOLLARDS FRANGIBLE 20 METRES WEST OF STOPWAY RWY 25 DUE \nMAINTENANCE\nCREATED: 30 Apr 2019 16:27:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-30T16:26:00.000Z",
    "enddate": "2019-07-30T20:00:00.000Z",
    "all": "A1539/19 NOTAMR A0333/19\nQ) EISN/QFAHW/IV/NBO/A /000/999/5321N00629W005\nA) EIWT B) 1904301626 C) 1907302000\nE) TEMPORARY BOLLARDS FRANGIBLE 20 METRES WEST OF STOPWAY RWY 25 DUE \nMAINTENANCE\nCREATED: 30 Apr 2019 16:27:00 \nSOURCE: EUECYIYN",
    "location": "EIWT",
    "isICAO": true,
    "Created": "2019-04-30T16:27:00.000Z",
    "key": "A1539/19-EIWT",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6f3",
    "id": "A1469/19",
    "entity": "MX",
    "status": "LC",
    "Qcode": "MXLC",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Limitations",
    "Subject": "Taxiway(s)",
    "Modifier": "Closed",
    "message": "TWY C4 CLSD\nCREATED: 26 Apr 2019 08:36:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-26T08:36:00.000Z",
    "enddate": "2019-07-25T20:00:00.000Z",
    "all": "A1469/19 NOTAMN\nQ) EISN/QMXLC/IV/M  /A /000/999/5321N00629W005\nA) EIWT B) 1904260836 C) 1907252000\nE) TWY C4 CLSD\nCREATED: 26 Apr 2019 08:36:00 \nSOURCE: EUECYIYN",
    "location": "EIWT",
    "isICAO": true,
    "Created": "2019-04-26T08:36:00.000Z",
    "key": "A1469/19-EIWT",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6f4",
    "id": "A0540/19",
    "entity": "MR",
    "status": "XX",
    "Qcode": "MRXX",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Other",
    "Subject": "Runway",
    "Modifier": "Plain language",
    "message": "RWY END COORDINATES CHANGED TO:\nRWY 07 - 532115.04N 0062855.67W\nRWY 25 - 532101.47N 0062940.17W\nDATA WHOSE ACCURACY HAS NOT BEEN QUALITY CHECKED.\nREF EIWT AD 2.12 RUNWAY PHYSICAL CHARACTERISTICS.\nCREATED: 20 Feb 2019 10:46:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-02-20T10:45:00.000Z",
    "enddate": "2019-05-25T18:00:00.000Z",
    "all": "A0540/19 NOTAMR A2936/18\nQ) EISN/QMRXX/IV/NBO/A /000/999/5321N00629W005\nA) EIWT B) 1902201045 C) 1905251800\nE) RWY END COORDINATES CHANGED TO:\nRWY 07 - 532115.04N 0062855.67W\nRWY 25 - 532101.47N 0062940.17W\nDATA WHOSE ACCURACY HAS NOT BEEN QUALITY CHECKED.\nREF EIWT AD 2.12 RUNWAY PHYSICAL CHARACTERISTICS.\nCREATED: 20 Feb 2019 10:46:00 \nSOURCE: EUECYIYN",
    "location": "EIWT",
    "isICAO": true,
    "Created": "2019-02-20T10:46:00.000Z",
    "key": "A0540/19-EIWT",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6f5",
    "id": "A1417/19",
    "entity": "PI",
    "status": "XX",
    "Qcode": "PIXX",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Other",
    "Subject": "Instrument approach procedure",
    "Modifier": "Plain language",
    "message": "EIWF INSTRUMENT APPROACH PROCEDURE NDB RWY 03, INSTRUMENT \nAPPROACH \nONLY AVAILABLE WHEN AIR TRAFFIC CONTROL ZONE IS ACTIVE UNLESS \nAUTHORISED BY FLIGHT OPERATIONS DEPARTMENT OF THE IRISH AVIATION \nAUTHORITY. \nREF AIP EIWF AD 2.24-6.1\nCREATED: 23 Apr 2019 14:27:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-23T14:26:00.000Z",
    "enddate": "2019-07-22T20:45:00.000Z",
    "all": "A1417/19 NOTAMR A0231/19\nQ) EISN/QPIXX/I /NBO/A /000/999/5211N00705W005\nA) EIWF B) 1904231426 C) 1907222045 EST\nE) EIWF INSTRUMENT APPROACH PROCEDURE NDB RWY 03, INSTRUMENT \nAPPROACH \nONLY AVAILABLE WHEN AIR TRAFFIC CONTROL ZONE IS ACTIVE UNLESS \nAUTHORISED BY FLIGHT OPERATIONS DEPARTMENT OF THE IRISH AVIATION \nAUTHORITY. \nREF AIP EIWF AD 2.24-6.1\nCREATED: 23 Apr 2019 14:27:00 \nSOURCE: EUECYIYN",
    "location": "EIWF",
    "isICAO": true,
    "Created": "2019-04-23T14:27:00.000Z",
    "key": "A1417/19-EIWF",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6f6",
    "id": "A1416/19",
    "entity": "PI",
    "status": "XX",
    "Qcode": "PIXX",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Other",
    "Subject": "Instrument approach procedure",
    "Modifier": "Plain language",
    "message": "EIWF INSTRUMENT APPROACH PROCEDURES ILS CAT 1 OR LOC RWY 21, \nINSTRUMENT APPROACH ONLY AVAILABLE WHEN AIR TRAFFIC CONTROL ZONE IS \nACTIVE UNLESS AUTHORISED BY FLIGHT OPERATIONS DEPARTMENT OF THE \nIRISH AVIATION AUTHORITY. \nREF AIP EIWF AD 2.24-3.1\nCREATED: 23 Apr 2019 14:25:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-23T14:24:00.000Z",
    "enddate": "2019-07-22T20:45:00.000Z",
    "all": "A1416/19 NOTAMR A0230/19\nQ) EISN/QPIXX/I /NBO/A /000/999/5211N00705W005\nA) EIWF B) 1904231424 C) 1907222045 EST\nE) EIWF INSTRUMENT APPROACH PROCEDURES ILS CAT 1 OR LOC RWY 21, \nINSTRUMENT APPROACH ONLY AVAILABLE WHEN AIR TRAFFIC CONTROL ZONE IS \nACTIVE UNLESS AUTHORISED BY FLIGHT OPERATIONS DEPARTMENT OF THE \nIRISH AVIATION AUTHORITY. \nREF AIP EIWF AD 2.24-3.1\nCREATED: 23 Apr 2019 14:25:00 \nSOURCE: EUECYIYN",
    "location": "EIWF",
    "isICAO": true,
    "Created": "2019-04-23T14:25:00.000Z",
    "key": "A1416/19-EIWF",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6f7",
    "id": "A1415/19",
    "entity": "PH",
    "status": "CH",
    "Qcode": "PHCH",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Changes",
    "Subject": "Holding procedure",
    "Modifier": "Changed",
    "message": "HOLDING IDENTIFICATION TABLE THE MAXIMUM LEVEL IN THE NDB WTD \nHOLD \nIS FL070 FOR RWY 03.\nREF AIP EIWF CHART AD 2.24-6.2\nCREATED: 23 Apr 2019 14:23:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-23T14:23:00.000Z",
    "enddate": "2019-07-22T20:45:00.000Z",
    "all": "A1415/19 NOTAMR A0229/19\nQ) EISN/QPHCH/IV/BO /AE/000/999/5211N00705W025\nA) EIWF B) 1904231423 C) 1907222045 EST\nE) HOLDING IDENTIFICATION TABLE THE MAXIMUM LEVEL IN THE NDB WTD \nHOLD \nIS FL070 FOR RWY 03.\nREF AIP EIWF CHART AD 2.24-6.2\nCREATED: 23 Apr 2019 14:23:00 \nSOURCE: EUECYIYN",
    "location": "EIWF",
    "isICAO": true,
    "Created": "2019-04-23T14:23:00.000Z",
    "key": "A1415/19-EIWF",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6f8",
    "id": "A1414/19",
    "entity": "PH",
    "status": "CH",
    "Qcode": "PHCH",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Changes",
    "Subject": "Holding procedure",
    "Modifier": "Changed",
    "message": "HOLDING IDENTIFICATION TABLE THE MAXIMUM LEVEL IN THE NDB WTD \nHOLD \nIS FL070 FOR RWY 21.\nREF AIP EIWF CHART AD 2.24-3.2\nCREATED: 23 Apr 2019 14:21:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-23T14:21:00.000Z",
    "enddate": "2019-07-22T20:45:00.000Z",
    "all": "A1414/19 NOTAMR A0228/19\nQ) EISN/QPHCH/IV/BO /AE/000/999/5211N00705W025\nA) EIWF B) 1904231421 C) 1907222045 EST\nE) HOLDING IDENTIFICATION TABLE THE MAXIMUM LEVEL IN THE NDB WTD \nHOLD \nIS FL070 FOR RWY 21.\nREF AIP EIWF CHART AD 2.24-3.2\nCREATED: 23 Apr 2019 14:21:00 \nSOURCE: EUECYIYN",
    "location": "EIWF",
    "isICAO": true,
    "Created": "2019-04-23T14:21:00.000Z",
    "key": "A1414/19-EIWF",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6f9",
    "id": "B0428/19",
    "entity": "LP",
    "status": "XX",
    "Qcode": "LPXX",
    "Area": "AGA",
    "SubArea": "Lighting facilities",
    "Condition": "Other",
    "Subject": "Precision approach path indicator",
    "Modifier": "Plain language",
    "message": "PAPI RWY 21 OPERATIONAL BUT NOT TO BE USED FOR APPROACH SLOPE \nGUIDANCE UNTIL THE AIRCRAFT IS ALIGNED WITH THE RUNWAY, AS NORMAL \nOBSTACLE CLEARANCE IS NOT PROVIDED TO THE WEST OF THE RUNWAY \nEXTENDED CENTRE-LINE.\nCREATED: 23 Apr 2019 15:10:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-23T15:08:00.000Z",
    "enddate": "2019-07-22T19:45:00.000Z",
    "all": "B0428/19 NOTAMR B0062/19\nQ) EISN/QLPXX/IV/NBO/A /000/999/5211N00705W005\nA) EIWF B) 1904231508 C) 1907221945 EST\nE) PAPI RWY 21 OPERATIONAL BUT NOT TO BE USED FOR APPROACH SLOPE \nGUIDANCE UNTIL THE AIRCRAFT IS ALIGNED WITH THE RUNWAY, AS NORMAL \nOBSTACLE CLEARANCE IS NOT PROVIDED TO THE WEST OF THE RUNWAY \nEXTENDED CENTRE-LINE.\nCREATED: 23 Apr 2019 15:10:00 \nSOURCE: EUECYIYN",
    "location": "EIWF",
    "isICAO": true,
    "Created": "2019-04-23T15:10:00.000Z",
    "key": "B0428/19-EIWF",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6fa",
    "id": "B0227/19",
    "entity": "XX",
    "status": "XX",
    "Qcode": "XXXX",
    "Area": "Other Information",
    "SubArea": "Other Information",
    "Condition": "Other",
    "Subject": "Not listed",
    "Modifier": "Plain language",
    "message": "MANDATORY RADIO AND TRANSPONDER ZONES.\nWHEN AFISO SERVICE IN PLACE AT EIWF CIRCLE RADIUS 10 NM CENTRED ON \n521114N 0070513W, ALL FLIGHTS WITHIN TRANSPONDER MANDATORY ZONE \nSHALL OPERATE TRANSPONDER AND RADIOS.\nFURTHER INFO WWW.IAA.IE\nCREATED: 08 Mar 2019 20:15:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-03-08T20:14:00.000Z",
    "enddate": "2019-06-08T18:00:00.000Z",
    "all": "B0227/19 NOTAMN\nQ) EISN/QXXXX/IV/NBO/AE/000/050/5211N00705W010\nA) EIWF B) 1903082014 C) 1906081800 EST\nE) MANDATORY RADIO AND TRANSPONDER ZONES.\nWHEN AFISO SERVICE IN PLACE AT EIWF CIRCLE RADIUS 10 NM CENTRED ON \n521114N 0070513W, ALL FLIGHTS WITHIN TRANSPONDER MANDATORY ZONE \nSHALL OPERATE TRANSPONDER AND RADIOS.\nFURTHER INFO WWW.IAA.IE\nCREATED: 08 Mar 2019 20:15:00 \nSOURCE: EUECYIYN",
    "location": "EIWF",
    "isICAO": true,
    "Created": "2019-03-08T20:15:00.000Z",
    "key": "B0227/19-EIWF",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6fb",
    "id": "B0335/17",
    "entity": "PI",
    "status": "CH",
    "Qcode": "PICH",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Changes",
    "Subject": "Instrument approach procedure",
    "Modifier": "Changed",
    "message": "REVISED MIN SECTOR ALITIUDE'S (MSA'S) 25NM FROM WTD NDB ARE AS \nFOLLOWS: \nSECTOR 1 - 204 DEG QDM TO 294 DEG QDM IS 4,100FT\nSECTOR 2 - 294 DEG QDM TO 024 DEG QDM IS 1,600FT\nSECTOR 3 - 024 DEG QDM TO 114 DEG QDM IS 3,700FT\nSECTOR 4 - 114 DEG QDM TO 204 DEG QDM IS 4,100FT\nEIWF INSTRUMENT APPROACH PROCEDURES, NDB/DME RWY 03.\nREF AIP IRELAND EIWF AD 2.24-6.1\nCREATED: 24 May 2017 15:23:00 \nSOURCE: EUECYIYN",
    "startdate": "2017-05-25T00:00:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B0335/17 NOTAMN\nQ) EISN/QPICH/I /NBO/A /000/999/5211N00705W005\nA) EIWF B) 1705250000 C) PERM\nE) REVISED MIN SECTOR ALITIUDE'S (MSA'S) 25NM FROM WTD NDB ARE AS \nFOLLOWS: \nSECTOR 1 - 204 DEG QDM TO 294 DEG QDM IS 4,100FT\nSECTOR 2 - 294 DEG QDM TO 024 DEG QDM IS 1,600FT\nSECTOR 3 - 024 DEG QDM TO 114 DEG QDM IS 3,700FT\nSECTOR 4 - 114 DEG QDM TO 204 DEG QDM IS 4,100FT\nEIWF INSTRUMENT APPROACH PROCEDURES, NDB/DME RWY 03.\nREF AIP IRELAND EIWF AD 2.24-6.1\nCREATED: 24 May 2017 15:23:00 \nSOURCE: EUECYIYN",
    "location": "EIWF",
    "isICAO": true,
    "Created": "2017-05-24T15:23:00.000Z",
    "key": "B0335/17-EIWF",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6fc",
    "id": "B0334/17",
    "entity": "PI",
    "status": "CH",
    "Qcode": "PICH",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Changes",
    "Subject": "Instrument approach procedure",
    "Modifier": "Changed",
    "message": "REVISED MIN SECTOR ALITIUDE'S (MSA'S) 25NM FROM WTD NDB ARE AS \nFOLLOWS: \nSECTOR 1 - 204 DEG QDM TO 294 DEG QDM IS 4,100FT\nSECTOR 2 - 294 DEG QDM TO 024 DEG QDM IS 1,600FT\nSECTOR 3 - 024 DEG QDM TO 114 DEG QDM IS 3,700FT\nSECTOR 4 - 114 DEG QDM TO 204 DEG QDM IS 4,100FT\nEIWF INSTRUMENT APPROACH PROCEDURES, NDB/DME RWY 21.\nREF AIP IRELAND EIWF AD 2.24-5\nCREATED: 24 May 2017 15:22:00 \nSOURCE: EUECYIYN",
    "startdate": "2017-05-25T00:00:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B0334/17 NOTAMN\nQ) EISN/QPICH/I /NBO/A /000/999/5211N00705W005\nA) EIWF B) 1705250000 C) PERM\nE) REVISED MIN SECTOR ALITIUDE'S (MSA'S) 25NM FROM WTD NDB ARE AS \nFOLLOWS: \nSECTOR 1 - 204 DEG QDM TO 294 DEG QDM IS 4,100FT\nSECTOR 2 - 294 DEG QDM TO 024 DEG QDM IS 1,600FT\nSECTOR 3 - 024 DEG QDM TO 114 DEG QDM IS 3,700FT\nSECTOR 4 - 114 DEG QDM TO 204 DEG QDM IS 4,100FT\nEIWF INSTRUMENT APPROACH PROCEDURES, NDB/DME RWY 21.\nREF AIP IRELAND EIWF AD 2.24-5\nCREATED: 24 May 2017 15:22:00 \nSOURCE: EUECYIYN",
    "location": "EIWF",
    "isICAO": true,
    "Created": "2017-05-24T15:22:00.000Z",
    "key": "B0334/17-EIWF",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6fd",
    "id": "A1246/19",
    "entity": "FA",
    "status": "AH",
    "Qcode": "FAAH",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Availability",
    "Subject": "Aerodrome",
    "Modifier": "Hours of service",
    "message": "EISG AERODROME HOURS OF SERVICE \nDAILY 0630-2000\nCREATED: 05 Apr 2019 17:44:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-06T06:30:00.000Z",
    "enddate": "2019-05-31T20:00:00.000Z",
    "all": "A1246/19 NOTAMN\nQ) EISN/QFAAH/IV/NBO/A /000/999/5417N00836W005\nA) EISG B) 1904060630 C) 1905312000\nE) EISG AERODROME HOURS OF SERVICE \nDAILY 0630-2000\nCREATED: 05 Apr 2019 17:44:00 \nSOURCE: EUECYIYN",
    "location": "EISG",
    "isICAO": true,
    "Created": "2019-04-05T17:44:00.000Z",
    "key": "A1246/19-EISG",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6fe",
    "id": "A1107/19",
    "entity": "FA",
    "status": "HX",
    "Qcode": "FAHX",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Hazard Conditions",
    "Subject": "Aerodrome",
    "Modifier": "Concentration of birds",
    "message": "SLIGO AERODROME BIRD ACTIVITY. WORKS TAKING PLACE AT STRANDHILL \nWASTE WATER PLANT AT POSITION 541642.856N 0083619.57W, ADJACENT TO \nSLIGO AIRPORT MAY LEAD TO AN INCREASE IN BIRD ACTIVITY IN THE \nVICINITY.\nCREATED: 30 Mar 2019 08:43:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-03-30T08:41:00.000Z",
    "enddate": "2019-06-25T17:00:00.000Z",
    "all": "A1107/19 NOTAMR A3190/18\nQ) EISN/QFAHX/IV/NBO/A /000/999/5417N00836W005\nA) EISG B) 1903300841 C) 1906251700\nE) SLIGO AERODROME BIRD ACTIVITY. WORKS TAKING PLACE AT STRANDHILL \nWASTE WATER PLANT AT POSITION 541642.856N 0083619.57W, ADJACENT TO \nSLIGO AIRPORT MAY LEAD TO AN INCREASE IN BIRD ACTIVITY IN THE \nVICINITY.\nCREATED: 30 Mar 2019 08:43:00 \nSOURCE: EUECYIYN",
    "location": "EISG",
    "isICAO": true,
    "Created": "2019-03-30T08:43:00.000Z",
    "key": "A1107/19-EISG",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb6ff",
    "id": "B0551/19",
    "entity": "SF",
    "status": "AH",
    "Qcode": "SFAH",
    "Area": "ATM",
    "SubArea": "Air traffic and VOLMET services",
    "Condition": "Availability",
    "Subject": "Aerodrome flight information service",
    "Modifier": "Hours of service",
    "message": "AFIS HOURS OF OPS/SERVICE  \nMAY 22 0630-2000\nMAY 23 1930-2000\nMAY 24 1700-2000\nCREATED: 21 May 2019 20:07:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-22T06:30:00.000Z",
    "enddate": "2019-05-24T20:00:00.000Z",
    "all": "B0551/19 NOTAMN\nQ) EISN/QSFAH/IV/B  /A /000/999/5417N00836W005\nA) EISG B) 1905220630 C) 1905242000\nE) AFIS HOURS OF OPS/SERVICE  \nMAY 22 0630-2000\nMAY 23 1930-2000\nMAY 24 1700-2000\nCREATED: 21 May 2019 20:07:00 \nSOURCE: EUECYIYN",
    "location": "EISG",
    "isICAO": true,
    "Created": "2019-05-21T20:07:00.000Z",
    "key": "B0551/19-EISG",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb700",
    "id": "B0550/19",
    "entity": "AC",
    "status": "AH",
    "Qcode": "ACAH",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Availability",
    "Subject": "Control zone",
    "Modifier": "Hours of service",
    "message": "SLIGO CTR HOURS OF OPS/SERVICE \nMAY 23 0630-1930\nMAY 24 0630-1700\nCREATED: 21 May 2019 20:06:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-23T06:30:00.000Z",
    "enddate": "2019-05-24T17:00:00.000Z",
    "all": "B0550/19 NOTAMN\nQ) EISN/QACAH/IV/NBO/AE/000/050/5417N00836W011\nA) EISG B) 1905230630 C) 1905241700\nE) SLIGO CTR HOURS OF OPS/SERVICE \nMAY 23 0630-1930\nMAY 24 0630-1700\nCREATED: 21 May 2019 20:06:00 \nSOURCE: EUECYIYN",
    "location": "EISG",
    "isICAO": true,
    "Created": "2019-05-21T20:06:00.000Z",
    "key": "B0550/19-EISG",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb701",
    "id": "B0228/19",
    "entity": "XX",
    "status": "XX",
    "Qcode": "XXXX",
    "Area": "Other Information",
    "SubArea": "Other Information",
    "Condition": "Other",
    "Subject": "Not listed",
    "Modifier": "Plain language",
    "message": "MANDATORY RADIO AND TRANSPONDER ZONES.\nWHEN AFISO SERVICE IN PLACE AT EISG CIRCLE RADIUS 10 NM CENTRED ON \n541649N 0083557W, ALL FLIGHTS WITHIN TRANSPONDER MANDATORY ZONE \nSHALL OPERATE TRANSPONDER AND RADIOS.\nFURTHER INFO WWW.IAA.IE\nCREATED: 08 Mar 2019 20:17:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-03-08T20:17:00.000Z",
    "enddate": "2019-06-08T18:00:00.000Z",
    "all": "B0228/19 NOTAMN\nQ) EISN/QXXXX/IV/NBO/AE/000/050/5417N00836W010\nA) EISG B) 1903082017 C) 1906081800 EST\nE) MANDATORY RADIO AND TRANSPONDER ZONES.\nWHEN AFISO SERVICE IN PLACE AT EISG CIRCLE RADIUS 10 NM CENTRED ON \n541649N 0083557W, ALL FLIGHTS WITHIN TRANSPONDER MANDATORY ZONE \nSHALL OPERATE TRANSPONDER AND RADIOS.\nFURTHER INFO WWW.IAA.IE\nCREATED: 08 Mar 2019 20:17:00 \nSOURCE: EUECYIYN",
    "location": "EISG",
    "isICAO": true,
    "Created": "2019-03-08T20:17:00.000Z",
    "key": "B0228/19-EISG",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb702",
    "id": "B0022/13",
    "entity": "PI",
    "status": "XX",
    "Qcode": "PIXX",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Other",
    "Subject": "Instrument approach procedure",
    "Modifier": "Plain language",
    "message": "IAP NDB DME RWY 29 EISG, OUTBOUND LEG (115 DEGREES) OF APPROACH \nLIMITED TO D8.5 ON PLAN VIEW OF AIP EISG AD 2.24-3\nORIGINAL NOTAM A0063/13\nCREATED: 27 Jun 2013 14:00:00 \nSOURCE: EUECYIYN",
    "startdate": "2013-06-27T00:39:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B0022/13 NOTAMN\nQ) EISN/QPIXX/I /NBO/A /000/999/5417N00836W005\nA) EISG B) 1306270039 C) PERM\nE) IAP NDB DME RWY 29 EISG, OUTBOUND LEG (115 DEGREES) OF APPROACH \nLIMITED TO D8.5 ON PLAN VIEW OF AIP EISG AD 2.24-3\nORIGINAL NOTAM A0063/13\nCREATED: 27 Jun 2013 14:00:00 \nSOURCE: EUECYIYN",
    "location": "EISG",
    "isICAO": true,
    "Created": "2013-06-27T14:00:00.000Z",
    "key": "B0022/13-EISG",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb703",
    "id": "N0034/19",
    "entity": "OB",
    "status": "CE",
    "Qcode": "OBCE",
    "Area": "Other Information",
    "SubArea": "Other Information",
    "Condition": "Changes",
    "Subject": "Obstacle",
    "Modifier": "Erected",
    "message": "OBSTACLE ERECTED. TEMPORARY MOBILE TELESCOPIC CRANE OPERATING AT \nPOSITION 541642.856N 0083619.57W, 0.1NM SOUTH OF TOUCHDOWN ZONE \nMARKINGS RUNWAY 11 AT EISG. MAX HEIGHT 52M AGL, JIB RADIUS 17M. \nOBSTACLE LIGHTED.\nCREATED: 01 Apr 2019 09:30:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-01T09:19:00.000Z",
    "enddate": "2019-05-31T17:00:00.000Z",
    "all": "N0034/19 NOTAMN\nQ) EISN/QOBCE/IV/M  /AE/000/002/5417N00836W001\nA) EISG B) 1904010919 C) 1905311700\nD) MON-FRI 0630-1700\nE) OBSTACLE ERECTED. TEMPORARY MOBILE TELESCOPIC CRANE OPERATING AT \nPOSITION 541642.856N 0083619.57W, 0.1NM SOUTH OF TOUCHDOWN ZONE \nMARKINGS RUNWAY 11 AT EISG. MAX HEIGHT 52M AGL, JIB RADIUS 17M. \nOBSTACLE LIGHTED.\nCREATED: 01 Apr 2019 09:30:00 \nSOURCE: EUECYIYN",
    "location": "EISG",
    "isICAO": true,
    "Created": "2019-04-01T09:30:00.000Z",
    "key": "N0034/19-EISG",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb704",
    "id": "A1798/19",
    "entity": "FA",
    "status": "AH",
    "Qcode": "FAAH",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Availability",
    "Subject": "Aerodrome",
    "Modifier": "Hours of service",
    "message": "AERODROME HOURS OF SERVICE ARE NOW\nMON 0640-1910\nTUE 0640-1910\nWED 0640-1910\nTHU 0640-1910\nFRI 0640-1910\nSAT 0640-1910\nSUN 0910-1910\nCREATED: 18 May 2019 08:47:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T06:40:00.000Z",
    "enddate": "2019-05-26T19:10:00.000Z",
    "all": "A1798/19 NOTAMN\nQ) EISN/QFAAH/IV/NBO/A /000/999/5503N00820W005\nA) EIDL B) 1905200640 C) 1905261910\nE) AERODROME HOURS OF SERVICE ARE NOW\nMON 0640-1910\nTUE 0640-1910\nWED 0640-1910\nTHU 0640-1910\nFRI 0640-1910\nSAT 0640-1910\nSUN 0910-1910\nCREATED: 18 May 2019 08:47:00 \nSOURCE: EUECYIYN",
    "location": "EIDL",
    "isICAO": true,
    "Created": "2019-05-18T08:47:00.000Z",
    "key": "A1798/19-EIDL",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb705",
    "id": "A1654/19",
    "entity": "MP",
    "status": "LC",
    "Qcode": "MPLC",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Limitations",
    "Subject": "Aircraft stands",
    "Modifier": "Closed",
    "message": "ACFT STANDS 3E AND 3W CLOSED\nCREATED: 09 May 2019 09:36:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-09T09:36:00.000Z",
    "enddate": "2019-05-31T18:00:00.000Z",
    "all": "A1654/19 NOTAMR A1450/19\nQ) EISN/QMPLC/IV/BO /A /000/999/5503N00820W005\nA) EIDL B) 1905090936 C) 1905311800\nE) ACFT STANDS 3E AND 3W CLOSED\nCREATED: 09 May 2019 09:36:00 \nSOURCE: EUECYIYN",
    "location": "EIDL",
    "isICAO": true,
    "Created": "2019-05-09T09:36:00.000Z",
    "key": "A1654/19-EIDL",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb706",
    "id": "A1569/19",
    "entity": "MN",
    "status": "HW",
    "Qcode": "MNHW",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Hazard Conditions",
    "Subject": "Apron",
    "Modifier": "Work in progress",
    "message": "DONEGAL APRON EXTENSION WORKS ACTIVE.\nCONSTRUCTION PHASE 3 ACTIVE NORTH SIDE OF APRON AND TAXIWAY A.\nREF AIP SUPPLEMENT NR 17/18.\nCREATED: 02 May 2019 07:59:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-02T07:58:00.000Z",
    "enddate": "2019-05-31T17:00:00.000Z",
    "all": "A1569/19 NOTAMR A1305/19\nQ) EISN/QMNHW/IV/BO /A /000/999/5503N00820W005\nA) EIDL B) 1905020758 C) 1905311700\nD) DAILY 0700-1700\nE) DONEGAL APRON EXTENSION WORKS ACTIVE.\nCONSTRUCTION PHASE 3 ACTIVE NORTH SIDE OF APRON AND TAXIWAY A.\nREF AIP SUPPLEMENT NR 17/18.\nCREATED: 02 May 2019 07:59:00 \nSOURCE: EUECYIYN",
    "location": "EIDL",
    "isICAO": true,
    "Created": "2019-05-02T07:59:00.000Z",
    "key": "A1569/19-EIDL",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb707",
    "id": "A1345/19",
    "entity": "FA",
    "status": "TT",
    "Qcode": "FATT",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Other",
    "Subject": "Aerodrome",
    "Modifier": "AIRAC AIP Amendments",
    "message": "TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR005 EFFECTIVE DATE 23 MAY \n2019. INCLUDED IN THIS AMENDMENT IS THE REVISED EIDL AD 2.24 CHARTS \nRELATED TO AN AERODROME AND CHART AD 2.24-15 VISUAL APPROACH CHART.\nCREATED: 16 Apr 2019 13:13:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-23T00:00:00.000Z",
    "enddate": "2019-06-06T23:59:00.000Z",
    "all": "A1345/19 NOTAMN\nQ) EISN/QFATT/IV/BO /A /000/999/5503N00820W005\nA) EIDL B) 1905230000 C) 1906062359\nE) TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR005 EFFECTIVE DATE 23 MAY \n2019. INCLUDED IN THIS AMENDMENT IS THE REVISED EIDL AD 2.24 CHARTS \nRELATED TO AN AERODROME AND CHART AD 2.24-15 VISUAL APPROACH CHART.\nCREATED: 16 Apr 2019 13:13:00 \nSOURCE: EUECYIYN",
    "location": "EIDL",
    "isICAO": true,
    "Created": "2019-04-16T13:13:00.000Z",
    "key": "A1345/19-EIDL",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb708",
    "id": "B0538/19",
    "entity": "AC",
    "status": "AH",
    "Qcode": "ACAH",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Availability",
    "Subject": "Control zone",
    "Modifier": "Hours of service",
    "message": "DONEGAL CTR HOURS OF SERVICE ARE NOW\nMON 0640-1910\nTUE 0640-1910\nWED 0640-1910\nTHU 0640-1910\nFRI 0640-1910\nSAT 0640-1910\nSUN 0910-1910\nCREATED: 18 May 2019 08:55:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T06:40:00.000Z",
    "enddate": "2019-05-26T19:10:00.000Z",
    "all": "B0538/19 NOTAMN\nQ) EISN/QACAH/IV/NBO/AE/000/050/5503N00820W011\nA) EIDL B) 1905200640 C) 1905261910\nE) DONEGAL CTR HOURS OF SERVICE ARE NOW\nMON 0640-1910\nTUE 0640-1910\nWED 0640-1910\nTHU 0640-1910\nFRI 0640-1910\nSAT 0640-1910\nSUN 0910-1910\nCREATED: 18 May 2019 08:55:00 \nSOURCE: EUECYIYN",
    "location": "EIDL",
    "isICAO": true,
    "Created": "2019-05-18T08:55:00.000Z",
    "key": "B0538/19-EIDL",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d484ab233e8499cdb709",
    "id": "B0493/19",
    "entity": "PH",
    "status": "XX",
    "Qcode": "PHXX",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Other",
    "Subject": "Holding procedure",
    "Modifier": "Plain language",
    "message": "HOLDING PROCEDURE - RATE OF DESCENT\nA STANDARD RATE OF DESCENT OF 1000FT PER MIN IN HOLDING PATTERNS \nWILL BE USED UNLESS OTHERWISE INSTRUCTED BY ATC.\nPILOTS MUST ADVISE ATC IF UNABLE TO COMPLY WITH THE STANDARD \nRATE OF DESCENT.\nCREATED: 08 May 2019 09:09:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-08T09:05:00.000Z",
    "enddate": "2019-08-08T23:59:00.000Z",
    "all": "B0493/19 NOTAMN\nQ) EISN/QPHXX/IV/BO /AE/000/010/5503N00820W025\nA) EIDL B) 1905080905 C) 1908082359 EST\nE) HOLDING PROCEDURE - RATE OF DESCENT\nA STANDARD RATE OF DESCENT OF 1000FT PER MIN IN HOLDING PATTERNS \nWILL BE USED UNLESS OTHERWISE INSTRUCTED BY ATC.\nPILOTS MUST ADVISE ATC IF UNABLE TO COMPLY WITH THE STANDARD \nRATE OF DESCENT.\nCREATED: 08 May 2019 09:09:00 \nSOURCE: EUECYIYN",
    "location": "EIDL",
    "isICAO": true,
    "Created": "2019-05-08T09:09:00.000Z",
    "key": "B0493/19-EIDL",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf52d",
    "id": "A1794/19",
    "entity": "FA",
    "status": "TT",
    "Qcode": "FATT",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Other",
    "Subject": "Aerodrome",
    "Modifier": "AIRAC AIP Amendments",
    "message": "TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR 006 EFFECTIVE DATE 20 \nJUN 2019. INCLUDED IN THIS AMENDMENT IS THE REVISED EINN AD 2.3 \nOPERATIONAL HOURS, AD 2.4 HANDLING SERVICES AND FACILITIES, AD 2.5 \nPASSENGER FACILITIES AND AD 2.6 RESCUE AND FIRE FIGHTING SERVICES.\nCREATED: 17 May 2019 17:28:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-06-20T00:00:00.000Z",
    "enddate": "2019-07-04T23:59:00.000Z",
    "all": "A1794/19 NOTAMN\nQ) EISN/QFATT/IV/BO /A /000/999/5242N00855W005\nA) EINN B) 1906200000 C) 1907042359\nE) TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR 006 EFFECTIVE DATE 20 \nJUN 2019. INCLUDED IN THIS AMENDMENT IS THE REVISED EINN AD 2.3 \nOPERATIONAL HOURS, AD 2.4 HANDLING SERVICES AND FACILITIES, AD 2.5 \nPASSENGER FACILITIES AND AD 2.6 RESCUE AND FIRE FIGHTING SERVICES.\nCREATED: 17 May 2019 17:28:00 \nSOURCE: EUECYIYN",
    "location": "EINN",
    "isICAO": true,
    "Created": "2019-05-17T17:28:00.000Z",
    "key": "A1794/19-EINN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf52e",
    "id": "A1778/19",
    "entity": "PI",
    "status": "AU",
    "Qcode": "PIAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Instrument approach procedure",
    "Modifier": "Not available",
    "message": "RWY 06 RNP IAP NOT AVAILABLE\nREF AIP EINN AD 2.24-9.1\nCREATED: 17 May 2019 10:40:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-17T10:39:00.000Z",
    "enddate": "2019-08-17T23:59:00.000Z",
    "all": "A1778/19 NOTAMN\nQ) EISN/QPIAU/I /NBO/A /000/999/5242N00855W005\nA) EINN B) 1905171039 C) 1908172359 EST\nE) RWY 06 RNP IAP NOT AVAILABLE\nREF AIP EINN AD 2.24-9.1\nCREATED: 17 May 2019 10:40:00 \nSOURCE: EUECYIYN",
    "location": "EINN",
    "isICAO": true,
    "Created": "2019-05-17T10:40:00.000Z",
    "key": "A1778/19-EINN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf52f",
    "id": "A1624/19",
    "entity": "MX",
    "status": "LC",
    "Qcode": "MXLC",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Limitations",
    "Subject": "Taxiway(s)",
    "Modifier": "Closed",
    "message": "TWY D2 CLOSED BETWEEN TWY H1 AND H2 INTERSECTION TO THE IMMEDIATE \nHOLDING POSITION ON TWY D2. THE WORKS AREA WILL BE DEMARCATED BY \nMARKERS/BARRIERS.\nREF AIP SUP NR 09/18\nCREATED: 07 May 2019 13:57:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-13T16:00:00.000Z",
    "enddate": "2019-06-21T04:00:00.000Z",
    "all": "A1624/19 NOTAMN\nQ) EISN/QMXLC/IV/M  /A /000/999/5242N00855W005\nA) EINN B) 1905131600 C) 1906210400\nD) MON 1600 - TUE 0400, TUE 1600 - WED 0400, WED 1600 - THU 0400, \nTHU 1600 - FRI 0400, FRI 1600 - SAT 0400\nE) TWY D2 CLOSED BETWEEN TWY H1 AND H2 INTERSECTION TO THE IMMEDIATE \nHOLDING POSITION ON TWY D2. THE WORKS AREA WILL BE DEMARCATED BY \nMARKERS/BARRIERS.\nREF AIP SUP NR 09/18\nCREATED: 07 May 2019 13:57:00 \nSOURCE: EUECYIYN",
    "location": "EINN",
    "isICAO": true,
    "Created": "2019-05-07T13:57:00.000Z",
    "key": "A1624/19-EINN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf530",
    "id": "A1547/19",
    "entity": "FA",
    "status": "XX",
    "Qcode": "FAXX",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Other",
    "Subject": "Aerodrome",
    "Modifier": "Plain language",
    "message": "NO TAKE-OFFS PERMITTED BELOW 125M RVR\nCREATED: 01 May 2019 06:51:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-01T06:50:00.000Z",
    "enddate": "2019-05-31T23:59:00.000Z",
    "all": "A1547/19 NOTAMR A1113/19\nQ) EISN/QFAXX/IV/NBO/A /000/999/5242N00855W005\nA) EINN B) 1905010650 C) 1905312359 EST\nE) NO TAKE-OFFS PERMITTED BELOW 125M RVR\nCREATED: 01 May 2019 06:51:00 \nSOURCE: EUECYIYN",
    "location": "EINN",
    "isICAO": true,
    "Created": "2019-05-01T06:51:00.000Z",
    "key": "A1547/19-EINN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf531",
    "id": "A1407/19",
    "entity": "PD",
    "status": "AU",
    "Qcode": "PDAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Standard instrument departure",
    "Modifier": "Not available",
    "message": "SID ABAGU3A\nSID AGINI3A\nSID DIGAN3A\nSID ERABI3A\nSID KURUM3A\nSID LUNIG3A\nSID MOMIN3A\nSID TOMTO3A\nSID UNBEG3A \nRWY06 SIDS NOT AVBL\nCREATED: 20 Apr 2019 19:11:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-20T19:10:00.000Z",
    "enddate": "2019-07-20T23:59:00.000Z",
    "all": "A1407/19 NOTAMR A0224/19\nQ) EISN/QPDAU/I /NBO/A /000/999/5242N00855W005\nA) EINN B) 1904201910 C) 1907202359 EST\nE) SID ABAGU3A\nSID AGINI3A\nSID DIGAN3A\nSID ERABI3A\nSID KURUM3A\nSID LUNIG3A\nSID MOMIN3A\nSID TOMTO3A\nSID UNBEG3A \nRWY06 SIDS NOT AVBL\nCREATED: 20 Apr 2019 19:11:00 \nSOURCE: EUECYIYN",
    "location": "EINN",
    "isICAO": true,
    "Created": "2019-04-20T19:11:00.000Z",
    "key": "A1407/19-EINN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf532",
    "id": "A1406/19",
    "entity": "PD",
    "status": "AU",
    "Qcode": "PDAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Standard instrument departure",
    "Modifier": "Not available",
    "message": "SID ABAGU3B\nSID AGINI3B\nSID BUNON3B\nSID ERABI3B\nSID LUNIG3B\nSID KURUM3B\nSID LUPOR3B\nSID MOMIN3B\nSID OSGAR3B\nSID PELIG3B\nSID UNBEG3B\nRWY 24 SID NOT AVBL\nCREATED: 20 Apr 2019 19:10:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-20T19:07:00.000Z",
    "enddate": "2019-07-20T23:59:00.000Z",
    "all": "A1406/19 NOTAMR A0223/19\nQ) EISN/QPDAU/I /NBO/A /000/999/5242N00855W005\nA) EINN B) 1904201907 C) 1907202359 EST\nE) SID ABAGU3B\nSID AGINI3B\nSID BUNON3B\nSID ERABI3B\nSID LUNIG3B\nSID KURUM3B\nSID LUPOR3B\nSID MOMIN3B\nSID OSGAR3B\nSID PELIG3B\nSID UNBEG3B\nRWY 24 SID NOT AVBL\nCREATED: 20 Apr 2019 19:10:00 \nSOURCE: EUECYIYN",
    "location": "EINN",
    "isICAO": true,
    "Created": "2019-04-20T19:10:00.000Z",
    "key": "A1406/19-EINN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf533",
    "id": "A1237/19",
    "entity": "MX",
    "status": "LT",
    "Qcode": "MXLT",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Limitations",
    "Subject": "Taxiway(s)",
    "Modifier": "Limited to",
    "message": "TAXIWAY CHARLIE WILL BE RESTRICTED TO HOURS OF DAYLIGHT OPERATIONS\nCREATED: 05 Apr 2019 16:37:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-05T19:00:00.000Z",
    "enddate": "2019-07-05T19:00:00.000Z",
    "all": "A1237/19 NOTAMN\nQ) EISN/QMXLT/IV/M  /A /000/999/5242N00855W005\nA) EINN B) 1904051900 C) 1907051900 EST\nE) TAXIWAY CHARLIE WILL BE RESTRICTED TO HOURS OF DAYLIGHT OPERATIONS\nCREATED: 05 Apr 2019 16:37:00 \nSOURCE: EUECYIYN",
    "location": "EINN",
    "isICAO": true,
    "Created": "2019-04-05T16:37:00.000Z",
    "key": "A1237/19-EINN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf534",
    "id": "A1236/19",
    "entity": "IO",
    "status": "AS",
    "Qcode": "IOAS",
    "Area": "CNS",
    "SubArea": "Instrument and microwave landing systems",
    "Condition": "Availability",
    "Subject": "Outer marker (ILS)",
    "Modifier": "Unserviceable",
    "message": "ILS OM RWY 24 OUT OF SERVICE \nREF AIP EINN AD 2.19\nCREATED: 05 Apr 2019 15:05:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-05T15:01:00.000Z",
    "enddate": "2019-07-12T17:00:00.000Z",
    "all": "A1236/19 NOTAMR A0152/19\nQ) EISN/QIOAS/I /BO /A /000/999/5242N00855W005\nA) EINN B) 1904051501 C) 1907121700 EST\nE) ILS OM RWY 24 OUT OF SERVICE \nREF AIP EINN AD 2.19\nCREATED: 05 Apr 2019 15:05:00 \nSOURCE: EUECYIYN",
    "location": "EINN",
    "isICAO": true,
    "Created": "2019-04-05T15:05:00.000Z",
    "key": "A1236/19-EINN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf535",
    "id": "B1142/15",
    "entity": "PH",
    "status": "CH",
    "Qcode": "PHCH",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Changes",
    "Subject": "Holding procedure",
    "Modifier": "Changed",
    "message": "HOLDING PROCEDURE CHANGED. RATE OF DESCENT IN HOLDING PATTERNS \nAMENDED TO: A STANDARD RATE OF DESCENT OF 1000FT PER MIN IN HOLDING \nPATTERNS WILL BE USED UNLESS OTHERWISE INSTRUCTED BY ATC. PILOTS \nMUST ADVISE ATC IF UNABLE TO COMPLY WITH THE STANDARD RATE OF \nDESCENT.\nREF AIP EINN AD 2.22 SECTION 9\nCREATED: 01 May 2017 11:24:00 \nSOURCE: EUECYIYN",
    "startdate": "2015-10-02T18:07:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B1142/15 NOTAMN\nQ) EISN/QPHCH/IV/BO /AE/000/999/5242N00855W025\nA) EINN B) 1510021807 C) PERM\nE) HOLDING PROCEDURE CHANGED. RATE OF DESCENT IN HOLDING PATTERNS \nAMENDED TO: A STANDARD RATE OF DESCENT OF 1000FT PER MIN IN HOLDING \nPATTERNS WILL BE USED UNLESS OTHERWISE INSTRUCTED BY ATC. PILOTS \nMUST ADVISE ATC IF UNABLE TO COMPLY WITH THE STANDARD RATE OF \nDESCENT.\nREF AIP EINN AD 2.22 SECTION 9\nCREATED: 01 May 2017 11:24:00 \nSOURCE: EUECYIYN",
    "location": "EINN",
    "isICAO": true,
    "Created": "2017-05-01T11:24:00.000Z",
    "key": "B1142/15-EINN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf536",
    "id": "A1141/19",
    "entity": "FA",
    "status": "XX",
    "Qcode": "FAXX",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Other",
    "Subject": "Aerodrome",
    "Modifier": "Plain language",
    "message": "NO TAKE-OFFS PERMITTED BELOW 125M RVR\nCREATED: 02 Apr 2019 12:04:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-02T12:03:00.000Z",
    "enddate": "2019-05-30T17:00:00.000Z",
    "all": "A1141/19 NOTAMR A0167/19\nQ) EISN/QFAXX/IV/NBO/A /000/999/5150N00829W005\nA) EICK B) 1904021203 C) 1905301700\nE) NO TAKE-OFFS PERMITTED BELOW 125M RVR\nCREATED: 02 Apr 2019 12:04:00 \nSOURCE: EUECYIYN",
    "location": "EICK",
    "isICAO": true,
    "Created": "2019-04-02T12:04:00.000Z",
    "key": "A1141/19-EICK",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf537",
    "id": "B1137/15",
    "entity": "PH",
    "status": "CH",
    "Qcode": "PHCH",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Changes",
    "Subject": "Holding procedure",
    "Modifier": "Changed",
    "message": "HOLDING PROCEDURE CHANGED. RATE OF DESCENT IN HOLDING PATTERNS \nAMENDED TO: A STANDARD RATE OF DESCENT OF 1000FT PER MIN IN HOLDING \nPATTERNS WILL BE USED UNLESS OTHERWISE INSTRUCTED BY ATC. PILOTS \nMUST ADVISE ATC IF UNABLE TO COMPLY WITH THE STANDARD RATE OF \nDESCENT. \nREF AIP EICK AD 2.22 SECTION 6\nCREATED: 01 May 2017 11:24:00 \nSOURCE: EUECYIYN",
    "startdate": "2015-10-02T17:42:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B1137/15 NOTAMN\nQ) EISN/QPHCH/IV/BO /AE/000/999/5150N00829W025\nA) EICK B) 1510021742 C) PERM\nE) HOLDING PROCEDURE CHANGED. RATE OF DESCENT IN HOLDING PATTERNS \nAMENDED TO: A STANDARD RATE OF DESCENT OF 1000FT PER MIN IN HOLDING \nPATTERNS WILL BE USED UNLESS OTHERWISE INSTRUCTED BY ATC. PILOTS \nMUST ADVISE ATC IF UNABLE TO COMPLY WITH THE STANDARD RATE OF \nDESCENT. \nREF AIP EICK AD 2.22 SECTION 6\nCREATED: 01 May 2017 11:24:00 \nSOURCE: EUECYIYN",
    "location": "EICK",
    "isICAO": true,
    "Created": "2017-05-01T11:24:00.000Z",
    "key": "B1137/15-EICK",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf538",
    "id": "A1482/19",
    "entity": "FA",
    "status": "AH",
    "Qcode": "FAAH",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Availability",
    "Subject": "Aerodrome",
    "Modifier": "Hours of service",
    "message": "AERODROME HOURS OF SERVICE \nMON,WED,FRI 0500-1915\nTUE,THU,SAT 0600-1915\nSUN         0730-1915\nEXCEPT\nMON 06 MAY  0600-1915\nMON 03 JUN  0600-1915\nCREATED: 26 Apr 2019 13:38:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-29T05:00:00.000Z",
    "enddate": "2019-06-30T19:15:00.000Z",
    "all": "A1482/19 NOTAMN\nQ) EISN/QFAAH/IV/NBO/A /000/999/5211N00931W005\nA) EIKY B) 1904290500 C) 1906301915\nE) AERODROME HOURS OF SERVICE \nMON,WED,FRI 0500-1915\nTUE,THU,SAT 0600-1915\nSUN         0730-1915\nEXCEPT\nMON 06 MAY  0600-1915\nMON 03 JUN  0600-1915\nCREATED: 26 Apr 2019 13:38:00 \nSOURCE: EUECYIYN",
    "location": "EIKY",
    "isICAO": true,
    "Created": "2019-04-26T13:38:00.000Z",
    "key": "A1482/19-EIKY",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf539",
    "id": "B0465/19",
    "entity": "AC",
    "status": "AH",
    "Qcode": "ACAH",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Availability",
    "Subject": "Control zone",
    "Modifier": "Hours of service",
    "message": "KERRY CTR HOURS OF SERVICE \nMON, WED, FRI 0500-1915\nTUE, THU, SAT 0600-1915\nSUN           0730-1915\nEXCEPT\nMON 06 MAY 0600-1915 \nMON 03 JUN 0600-1915\nCREATED: 30 Apr 2019 17:43:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-30T17:41:00.000Z",
    "enddate": "2019-06-30T19:15:00.000Z",
    "all": "B0465/19 NOTAMN\nQ) EISN/QACAH/IV/NBO/AE/000/050/5211N00931W011\nA) EIKY B) 1904301741 C) 1906301915\nE) KERRY CTR HOURS OF SERVICE \nMON, WED, FRI 0500-1915\nTUE, THU, SAT 0600-1915\nSUN           0730-1915\nEXCEPT\nMON 06 MAY 0600-1915 \nMON 03 JUN 0600-1915\nCREATED: 30 Apr 2019 17:43:00 \nSOURCE: EUECYIYN",
    "location": "EIKY",
    "isICAO": true,
    "Created": "2019-04-30T17:43:00.000Z",
    "key": "B0465/19-EIKY",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf53a",
    "id": "A1814/19",
    "entity": "MO",
    "status": "XX",
    "Qcode": "MOXX",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Other",
    "Subject": "Stopbar",
    "Modifier": "Plain language",
    "message": "STOPBAR ON TAXIWAY A UNSERVICEABLE.\nREF AIP SUPPLEMENT NR 004/19 RUNWAY 08/26 TAXIWAY A AND TAXIWAY B\nREHABILITATION WORKS.\nCREATED: 20 May 2019 09:28:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-21T08:05:00.000Z",
    "enddate": "2019-05-24T08:10:00.000Z",
    "all": "A1814/19 NOTAMN\nQ) EISN/QMOXX/IV/NBO/A /000/999/5355N00849W005\nA) EIKN B) 1905210805 C) 1905240810\nE) STOPBAR ON TAXIWAY A UNSERVICEABLE.\nREF AIP SUPPLEMENT NR 004/19 RUNWAY 08/26 TAXIWAY A AND TAXIWAY B\nREHABILITATION WORKS.\nCREATED: 20 May 2019 09:28:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-05-20T09:28:00.000Z",
    "key": "A1814/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf53b",
    "id": "A1758/19",
    "entity": "FA",
    "status": "XX",
    "Qcode": "FAXX",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Other",
    "Subject": "Aerodrome",
    "Modifier": "Plain language",
    "message": "AIRFIELD WORKS WILL TAKE PLACE WITHIN THE FLIGHT STRIP.\n24HR PPR STRICTLY ENFORCED FOR THE PERIOD OF WORKS\nCREATED: 16 May 2019 11:42:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-16T11:41:00.000Z",
    "enddate": "2019-05-31T17:00:00.000Z",
    "all": "A1758/19 NOTAMN\nQ) EISN/QFAXX/IV/NBO/A /000/999/5355N00849W005\nA) EIKN B) 1905161141 C) 1905311700\nD) MON-FRI 0700-1700\nE) AIRFIELD WORKS WILL TAKE PLACE WITHIN THE FLIGHT STRIP.\n24HR PPR STRICTLY ENFORCED FOR THE PERIOD OF WORKS\nCREATED: 16 May 2019 11:42:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-05-16T11:42:00.000Z",
    "key": "A1758/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf53c",
    "id": "A1609/19",
    "entity": "IC",
    "status": "AS",
    "Qcode": "ICAS",
    "Area": "CNS",
    "SubArea": "Instrument and microwave landing systems",
    "Condition": "Availability",
    "Subject": "Instrument landing system",
    "Modifier": "Unserviceable",
    "message": "ILS RWY 26 U/S\nCREATED: 04 May 2019 09:36:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-07T08:00:00.000Z",
    "enddate": "2019-06-07T18:00:00.000Z",
    "all": "A1609/19 NOTAMN\nQ) EISN/QICAS/I /NBO/A /000/999/5355N00849W005\nA) EIKN B) 1905070800 C) 1906071800\nE) ILS RWY 26 U/S\nCREATED: 04 May 2019 09:36:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-05-04T09:36:00.000Z",
    "key": "A1609/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf53d",
    "id": "A1607/19",
    "entity": "PI",
    "status": "AU",
    "Qcode": "PIAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Instrument approach procedure",
    "Modifier": "Not available",
    "message": "EIKN IAP NDB APPROACHES RWY 26 UNAVAILABLE \nREF AIP EIKN AD 2.24-12.1, EIKN AD 2.24-13.1\nCREATED: 03 May 2019 18:06:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-03T18:05:00.000Z",
    "enddate": "2019-06-07T18:00:00.000Z",
    "all": "A1607/19 NOTAMN\nQ) EISN/QPIAU/I /NBO/A /000/999/5355N00849W005\nA) EIKN B) 1905031805 C) 1906071800\nE) EIKN IAP NDB APPROACHES RWY 26 UNAVAILABLE \nREF AIP EIKN AD 2.24-12.1, EIKN AD 2.24-13.1\nCREATED: 03 May 2019 18:06:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-05-03T18:06:00.000Z",
    "key": "A1607/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf53e",
    "id": "A1606/19",
    "entity": "PI",
    "status": "AU",
    "Qcode": "PIAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Instrument approach procedure",
    "Modifier": "Not available",
    "message": "EIKN IAP ILS A AND B CAT I AND II APPROACHES RWY 26 UNAVAILABLE\nREF EIKN AD 2.24-9.1, EIKN AD 2.24-10.1\nCREATED: 03 May 2019 18:02:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-07T08:00:00.000Z",
    "enddate": "2019-06-07T18:00:00.000Z",
    "all": "A1606/19 NOTAMN\nQ) EISN/QPIAU/I /NBO/A /000/999/5355N00849W005\nA) EIKN B) 1905070800 C) 1906071800\nE) EIKN IAP ILS A AND B CAT I AND II APPROACHES RWY 26 UNAVAILABLE\nREF EIKN AD 2.24-9.1, EIKN AD 2.24-10.1\nCREATED: 03 May 2019 18:02:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-05-03T18:02:00.000Z",
    "key": "A1606/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf53f",
    "id": "A1472/19",
    "entity": "FA",
    "status": "AH",
    "Qcode": "FAAH",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Availability",
    "Subject": "Aerodrome",
    "Modifier": "Hours of service",
    "message": "EIKN AD HR OF  SER\nMON 0810-1905\nTUE 0805-1840\nWED 0815-1840\nTHU 0815-1840 EXC 02 0700-1840\nFRI 0810-1840\nSAT 0650-1650\nSUN 0810-1840 EXC 26 0610-1945\nCREATED: 26 Apr 2019 09:44:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-01T08:15:00.000Z",
    "enddate": "2019-05-31T18:40:00.000Z",
    "all": "A1472/19 NOTAMN\nQ) EISN/QFAAH/IV/NBO/A /000/999/5355N00849W005\nA) EIKN B) 1905010815 C) 1905311840\nE) EIKN AD HR OF  SER\nMON 0810-1905\nTUE 0805-1840\nWED 0815-1840\nTHU 0815-1840 EXC 02 0700-1840\nFRI 0810-1840\nSAT 0650-1650\nSUN 0810-1840 EXC 26 0610-1945\nCREATED: 26 Apr 2019 09:44:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-04-26T09:44:00.000Z",
    "key": "A1472/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf540",
    "id": "A0687/19",
    "entity": "IO",
    "status": "AS",
    "Qcode": "IOAS",
    "Area": "CNS",
    "SubArea": "Instrument and microwave landing systems",
    "Condition": "Availability",
    "Subject": "Outer marker (ILS)",
    "Modifier": "Unserviceable",
    "message": "ILS OM 75MHZ RWY 26 OUT OF SERVICE\nCREATED: 03 Mar 2019 10:52:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-03-03T10:48:00.000Z",
    "enddate": "2019-06-03T16:00:00.000Z",
    "all": "A0687/19 NOTAMR A3025/18\nQ) EISN/QIOAS/I /BO /A /000/999/5355N00849W005\nA) EIKN B) 1903031048 C) 1906031600\nE) ILS OM 75MHZ RWY 26 OUT OF SERVICE\nCREATED: 03 Mar 2019 10:52:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-03-03T10:52:00.000Z",
    "key": "A0687/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf541",
    "id": "B0477/19",
    "entity": "LX",
    "status": "AS",
    "Qcode": "LXAS",
    "Area": "AGA",
    "SubArea": "Lighting facilities",
    "Condition": "Availability",
    "Subject": "Taxiway centre line lights",
    "Modifier": "Unserviceable",
    "message": "TWY A AND B CENTRE LINE LIGHTING U/S DUE TO REHABILITATION WORKS.\nREF AIP SUP 004/2019\nCREATED: 02 May 2019 15:19:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-08T07:00:00.000Z",
    "enddate": "2019-08-15T20:00:00.000Z",
    "all": "B0477/19 NOTAMN\nQ) EISN/QLXAS/IV/M  /A /000/999/5355N00849W005\nA) EIKN B) 1905080700 C) 1908152000\nE) TWY A AND B CENTRE LINE LIGHTING U/S DUE TO REHABILITATION WORKS.\nREF AIP SUP 004/2019\nCREATED: 02 May 2019 15:19:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-05-02T15:19:00.000Z",
    "key": "B0477/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf542",
    "id": "B0476/19",
    "entity": "LZ",
    "status": "AS",
    "Qcode": "LZAS",
    "Area": "AGA",
    "SubArea": "Lighting facilities",
    "Condition": "Availability",
    "Subject": "Runway touchdown zone lights",
    "Modifier": "Unserviceable",
    "message": "RWY 08/26 TDZ (TOUCH DOWN ZONE) LIGHTING U/S DUE TO \nREHABILITATION WORKS.\nREF AIP SUP 004/2019\nCREATED: 02 May 2019 15:17:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-08T07:00:00.000Z",
    "enddate": "2019-08-15T20:00:00.000Z",
    "all": "B0476/19 NOTAMN\nQ) EISN/QLZAS/I /BO /A /000/999/5355N00849W005\nA) EIKN B) 1905080700 C) 1908152000\nE) RWY 08/26 TDZ (TOUCH DOWN ZONE) LIGHTING U/S DUE TO \nREHABILITATION WORKS.\nREF AIP SUP 004/2019\nCREATED: 02 May 2019 15:17:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-05-02T15:17:00.000Z",
    "key": "B0476/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf543",
    "id": "B0475/19",
    "entity": "LC",
    "status": "AS",
    "Qcode": "LCAS",
    "Area": "AGA",
    "SubArea": "Lighting facilities",
    "Condition": "Availability",
    "Subject": "Runway centre line lights",
    "Modifier": "Unserviceable",
    "message": "RWY 08/26 RCL (CENTRE LINE) LIGHTING U/S DUE TO REHABILITATION \nWORKS.\nREF AIP SUP 004/2019\nCREATED: 02 May 2019 15:16:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-08T07:00:00.000Z",
    "enddate": "2019-08-15T20:00:00.000Z",
    "all": "B0475/19 NOTAMN\nQ) EISN/QLCAS/I /BO /A /000/999/5355N00849W005\nA) EIKN B) 1905080700 C) 1908152000\nE) RWY 08/26 RCL (CENTRE LINE) LIGHTING U/S DUE TO REHABILITATION \nWORKS.\nREF AIP SUP 004/2019\nCREATED: 02 May 2019 15:16:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-05-02T15:16:00.000Z",
    "key": "B0475/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf544",
    "id": "B0474/19",
    "entity": "LR",
    "status": "AS",
    "Qcode": "LRAS",
    "Area": "AGA",
    "SubArea": "Lighting facilities",
    "Condition": "Availability",
    "Subject": "All landing area lighting facilities",
    "Modifier": "Unserviceable",
    "message": "LANDING AREA LIGHTING - SUPPLEMENTARY AND TURNPAD LEAD \nLIGHTING RWY 08/26 U/S DUE TO REHABILITATION WORKS\nREF AIP SUP 004/2019\nCREATED: 02 May 2019 15:15:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-08T07:00:00.000Z",
    "enddate": "2019-08-15T20:00:00.000Z",
    "all": "B0474/19 NOTAMN\nQ) EISN/QLRAS/IV/NBO/A /000/999/5355N00849W005\nA) EIKN B) 1905080700 C) 1908152000\nE) LANDING AREA LIGHTING - SUPPLEMENTARY AND TURNPAD LEAD \nLIGHTING RWY 08/26 U/S DUE TO REHABILITATION WORKS\nREF AIP SUP 004/2019\nCREATED: 02 May 2019 15:15:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-05-02T15:15:00.000Z",
    "key": "B0474/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf545",
    "id": "B0445/19",
    "entity": "AC",
    "status": "AH",
    "Qcode": "ACAH",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Availability",
    "Subject": "Control zone",
    "Modifier": "Hours of service",
    "message": "EIKN CTR HR OF SER\nMON 0755-1920\nTUE 0750-1855\nWED 0800-1855\nTHU 0800-1855 EXC 02 0645-1855\nFRI 0755-1855\nSAT 0635-1705\nSUN 0755-1855 EXC 26 0555-2000\nCREATED: 26 Apr 2019 09:47:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-01T08:00:00.000Z",
    "enddate": "2019-05-31T18:55:00.000Z",
    "all": "B0445/19 NOTAMN\nQ) EISN/QACAH/IV/NBO/AE/000/050/5355N00849W011\nA) EIKN B) 1905010800 C) 1905311855\nE) EIKN CTR HR OF SER\nMON 0755-1920\nTUE 0750-1855\nWED 0800-1855\nTHU 0800-1855 EXC 02 0645-1855\nFRI 0755-1855\nSAT 0635-1705\nSUN 0755-1855 EXC 26 0555-2000\nCREATED: 26 Apr 2019 09:47:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-04-26T09:47:00.000Z",
    "key": "B0445/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf546",
    "id": "B0427/19",
    "entity": "NB",
    "status": "AS",
    "Qcode": "NBAS",
    "Area": "CNS",
    "SubArea": "Terminal and en-route navigation facilities",
    "Condition": "Availability",
    "Subject": "Non-directional radio beacon",
    "Modifier": "Unserviceable",
    "message": "NDB OK/CONNAUGHT FREQ 398 KHZ U/S\nCREATED: 23 Apr 2019 12:22:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-23T12:22:00.000Z",
    "enddate": "2019-05-23T19:00:00.000Z",
    "all": "B0427/19 NOTAMN\nQ) EISN/QNBAS/IV/BO /AE/000/999/5355N00842W025\nA) EIKN B) 1904231222 C) 1905231900\nE) NDB OK/CONNAUGHT FREQ 398 KHZ U/S\nCREATED: 23 Apr 2019 12:22:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-04-23T12:22:00.000Z",
    "key": "B0427/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf547",
    "id": "N0069/19",
    "entity": "OB",
    "status": "CE",
    "Qcode": "OBCE",
    "Area": "Other Information",
    "SubArea": "Other Information",
    "Condition": "Changes",
    "Subject": "Obstacle",
    "Modifier": "Erected",
    "message": "TEMPORARY CRANE OPERATION AT EIKN POSITION 535438.49N 0084823.69W \nMAX ELEVATION 222M (25M AGL) JIB RADIUS 15M. CRANE UNLIGHTED, WILL \nBE LOWERED FOR ARRIVAL AND DEPARTURES\nCREATED: 21 May 2019 15:58:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-22T07:00:00.000Z",
    "enddate": "2019-05-22T18:00:00.000Z",
    "all": "N0069/19 NOTAMN\nQ) EISN/QOBCE/IV/M  /AE/000/008/5355N00849W001\nA) EIKN B) 1905220700 C) 1905221800\nE) TEMPORARY CRANE OPERATION AT EIKN POSITION 535438.49N 0084823.69W \nMAX ELEVATION 222M (25M AGL) JIB RADIUS 15M. CRANE UNLIGHTED, WILL \nBE LOWERED FOR ARRIVAL AND DEPARTURES\nCREATED: 21 May 2019 15:58:00 \nSOURCE: EUECYIYN",
    "location": "EIKN",
    "isICAO": true,
    "Created": "2019-05-21T15:58:00.000Z",
    "key": "N0069/19-EIKN",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf548",
    "id": "A1832/19",
    "entity": "PA",
    "status": "AU",
    "Qcode": "PAAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Standard instrument arrival",
    "Modifier": "Not available",
    "message": "STAR ABLIN1X\nSTAR BAGSO1X\nSTAR BAMLI1X\nSTAR BOYNE1X\nSTAR BUNED1X\nSTAR NIMAT1X\nSTAR OLAPO1X\nSTAR OSGAR1X\nSTAR SUTEX1X\nSTAR VATRY1X \nNOT AVAILABLE\nREF AIP EIDW AD 2.24-17.4\nCREATED: 20 May 2019 17:05:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T18:30:00.000Z",
    "enddate": "2019-05-25T03:30:00.000Z",
    "all": "A1832/19 NOTAMN\nQ) EISN/QPAAU/I /NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905201830 C) 1905250330\nD) DAILY 1830-0330\nE) STAR ABLIN1X\nSTAR BAGSO1X\nSTAR BAMLI1X\nSTAR BOYNE1X\nSTAR BUNED1X\nSTAR NIMAT1X\nSTAR OLAPO1X\nSTAR OSGAR1X\nSTAR SUTEX1X\nSTAR VATRY1X \nNOT AVAILABLE\nREF AIP EIDW AD 2.24-17.4\nCREATED: 20 May 2019 17:05:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T17:05:00.000Z",
    "key": "A1832/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf549",
    "id": "A1831/19",
    "entity": "PI",
    "status": "AU",
    "Qcode": "PIAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Instrument approach procedure",
    "Modifier": "Not available",
    "message": "RWY 34 VOR IAP NOT AVAILABLE \nREF EIDW AD 2.24-33.1\nCREATED: 20 May 2019 17:05:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T18:30:00.000Z",
    "enddate": "2019-05-25T03:30:00.000Z",
    "all": "A1831/19 NOTAMN\nQ) EISN/QPIAU/I /NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905201830 C) 1905250330\nD) DAILY 1830-0330\nE) RWY 34 VOR IAP NOT AVAILABLE \nREF EIDW AD 2.24-33.1\nCREATED: 20 May 2019 17:05:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T17:05:00.000Z",
    "key": "A1831/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf54a",
    "id": "A1830/19",
    "entity": "PI",
    "status": "AU",
    "Qcode": "PIAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Instrument approach procedure",
    "Modifier": "Not available",
    "message": "RWY 34 RNP LNAV IAP NOT AVAILABLE\nREF AIP EIDW AD 2.24-32.1\nCREATED: 20 May 2019 17:01:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T18:30:00.000Z",
    "enddate": "2019-05-25T03:30:00.000Z",
    "all": "A1830/19 NOTAMN\nQ) EISN/QPIAU/I /NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905201830 C) 1905250330\nD) DAILY 1830-0330\nE) RWY 34 RNP LNAV IAP NOT AVAILABLE\nREF AIP EIDW AD 2.24-32.1\nCREATED: 20 May 2019 17:01:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T17:01:00.000Z",
    "key": "A1830/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf54b",
    "id": "A1829/19",
    "entity": "PI",
    "status": "AU",
    "Qcode": "PIAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Instrument approach procedure",
    "Modifier": "Not available",
    "message": "RWY 28 ILS CAT I, CAT II OR LOC NOT AVAILABLE\nREF EIDW AD 2.24-23.1\nCREATED: 20 May 2019 17:01:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T18:30:00.000Z",
    "enddate": "2019-05-25T03:30:00.000Z",
    "all": "A1829/19 NOTAMN\nQ) EISN/QPIAU/I /NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905201830 C) 1905250330\nD) DAILY 1830-0330\nE) RWY 28 ILS CAT I, CAT II OR LOC NOT AVAILABLE\nREF EIDW AD 2.24-23.1\nCREATED: 20 May 2019 17:01:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T17:01:00.000Z",
    "key": "A1829/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf54c",
    "id": "A1828/19",
    "entity": "PI",
    "status": "AU",
    "Qcode": "PIAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Instrument approach procedure",
    "Modifier": "Not available",
    "message": "RWY 28 VOR T IAP NOT AVAILABLE\nREF EIDW AD 2.24-34.1\nCREATED: 20 May 2019 16:59:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-21T03:30:00.000Z",
    "enddate": "2019-05-27T18:30:00.000Z",
    "all": "A1828/19 NOTAMN\nQ) EISN/QPIAU/I /NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905210330 C) 1905271830\nD) TUE 0330-1830, WED 0330-1830, THU 0330-1830, FRI 0330-1830,SAT \n0330-2359,SUN 0000-2359, MON 0000-1830\nE) RWY 28 VOR T IAP NOT AVAILABLE\nREF EIDW AD 2.24-34.1\nCREATED: 20 May 2019 16:59:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T16:59:00.000Z",
    "key": "A1828/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf54d",
    "id": "A1827/19",
    "entity": "PI",
    "status": "AU",
    "Qcode": "PIAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Instrument approach procedure",
    "Modifier": "Not available",
    "message": "RWY 28 RNP IAP NOT AVAILABLE\nREF AIP EIDW AD 2.24-22.1\nCREATED: 20 May 2019 16:58:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T18:30:00.000Z",
    "enddate": "2019-05-25T03:30:00.000Z",
    "all": "A1827/19 NOTAMN\nQ) EISN/QPIAU/I /NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905201830 C) 1905250330\nD) DAILY 1830-0330\nE) RWY 28 RNP IAP NOT AVAILABLE\nREF AIP EIDW AD 2.24-22.1\nCREATED: 20 May 2019 16:58:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T16:58:00.000Z",
    "key": "A1827/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf54e",
    "id": "A1826/19",
    "entity": "PI",
    "status": "AU",
    "Qcode": "PIAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Instrument approach procedure",
    "Modifier": "Not available",
    "message": "RWY 28 VOR IAP NOT AVAILABLE\nREF AIP EIDW AD 2.24-24.1\nCREATED: 20 May 2019 16:55:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T16:54:00.000Z",
    "enddate": "2019-05-27T18:30:00.000Z",
    "all": "A1826/19 NOTAMR A1787/19\nQ) EISN/QPIAU/I /NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905201654 C) 1905271830 EST\nE) RWY 28 VOR IAP NOT AVAILABLE\nREF AIP EIDW AD 2.24-24.1\nCREATED: 20 May 2019 16:55:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T16:55:00.000Z",
    "key": "A1826/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf54f",
    "id": "A1825/19",
    "entity": "MX",
    "status": "LT",
    "Qcode": "MXLT",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Limitations",
    "Subject": "Taxiway(s)",
    "Modifier": "Limited to",
    "message": "TAXIWAY A LIMITED TO AIRCRAFT WITH A MAXIMUM WINGSPAN OF\n36M (118FT) ONLY.\nCREATED: 20 May 2019 16:02:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T15:59:00.000Z",
    "enddate": "2019-08-20T12:00:00.000Z",
    "all": "A1825/19 NOTAMR A0561/19\nQ) EISN/QMXLT/IV/M  /A /000/999/5325N00616W005\nA) EIDW B) 1905201559 C) 1908201200 EST\nE) TAXIWAY A LIMITED TO AIRCRAFT WITH A MAXIMUM WINGSPAN OF\n36M (118FT) ONLY.\nCREATED: 20 May 2019 16:02:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T16:02:00.000Z",
    "key": "A1825/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf550",
    "id": "A1821/19",
    "entity": "MR",
    "status": "XX",
    "Qcode": "MRXX",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Other",
    "Subject": "Runway",
    "Modifier": "Plain language",
    "message": "RWY 10 REDUCED LENGTH (LDA 2141M) AVAILABLE WITH A TAIL WIND NOT \nEXCEEDING 4KTS \nREF AIP SUPPLEMENT NR007/19 \nRWY 16/34 REHABILITATION PHASE 1 - AREA 3\nCREATED: 20 May 2019 15:48:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T18:30:00.000Z",
    "enddate": "2019-05-24T03:15:00.000Z",
    "all": "A1821/19 NOTAMN\nQ) EISN/QMRXX/IV/NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905201830 C) 1905240315\nD) DAILY 1830-0315\nE) RWY 10 REDUCED LENGTH (LDA 2141M) AVAILABLE WITH A TAIL WIND NOT \nEXCEEDING 4KTS \nREF AIP SUPPLEMENT NR007/19 \nRWY 16/34 REHABILITATION PHASE 1 - AREA 3\nCREATED: 20 May 2019 15:48:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T15:48:00.000Z",
    "key": "A1821/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf551",
    "id": "A1819/19",
    "entity": "MR",
    "status": "XX",
    "Qcode": "MRXX",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Other",
    "Subject": "Runway",
    "Modifier": "Plain language",
    "message": "RWY 10 AND RWY 28 OPERATING ON REDUCED LENGTH\nAREA 3 AND ALL RESTRICTIONS ACTIVE INCLUDING REDUCED RUNWAY \nLENGTHS.REF AIP SUPPLEMENT 07/2019 PHASE 1 AREA 3\nCREATED: 20 May 2019 12:01:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T18:30:00.000Z",
    "enddate": "2019-05-24T03:30:00.000Z",
    "all": "A1819/19 NOTAMN\nQ) EISN/QMRXX/IV/NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905201830 C) 1905240330\nD) DAILY 1830-0330\nE) RWY 10 AND RWY 28 OPERATING ON REDUCED LENGTH\nAREA 3 AND ALL RESTRICTIONS ACTIVE INCLUDING REDUCED RUNWAY \nLENGTHS.REF AIP SUPPLEMENT 07/2019 PHASE 1 AREA 3\nCREATED: 20 May 2019 12:01:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T12:01:00.000Z",
    "key": "A1819/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf552",
    "id": "A1818/19",
    "entity": "MR",
    "status": "XX",
    "Qcode": "MRXX",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Other",
    "Subject": "Runway",
    "Modifier": "Plain language",
    "message": "REINSTATING RWY 10/28 TO FULL LENGTH.\nEXPECT DELAYS OF APPROXIMATELY 20 MINUTES DUE RUNWAY LENGTH CHANGE.\nREF AIP SUP NR 007/19 RWY 16/34 REHABILITATION - PHASE 1\nCREATED: 20 May 2019 09:54:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-21T03:00:00.000Z",
    "enddate": "2019-05-24T03:30:00.000Z",
    "all": "A1818/19 NOTAMN\nQ) EISN/QMRXX/IV/NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905210300 C) 1905240330\nD) DAILY 0300-0330\nE) REINSTATING RWY 10/28 TO FULL LENGTH.\nEXPECT DELAYS OF APPROXIMATELY 20 MINUTES DUE RUNWAY LENGTH CHANGE.\nREF AIP SUP NR 007/19 RWY 16/34 REHABILITATION - PHASE 1\nCREATED: 20 May 2019 09:54:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T09:54:00.000Z",
    "key": "A1818/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf553",
    "id": "A1817/19",
    "entity": "MR",
    "status": "XX",
    "Qcode": "MRXX",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Other",
    "Subject": "Runway",
    "Modifier": "Plain language",
    "message": "REINSTATING RWY 16/34 TO FULL LENGTH.\nEXPECT DELAYS OF APPROXIMATELY 20 MINUTES DUE RUNWAY LENGTH CHANGE.\nREF AIP SUP NR 007/19 RWY 16/34 REHABILITATION - PHASE 1\nCREATED: 20 May 2019 09:53:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-21T03:00:00.000Z",
    "enddate": "2019-05-24T03:30:00.000Z",
    "all": "A1817/19 NOTAMN\nQ) EISN/QMRXX/IV/NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905210300 C) 1905240330\nD) DAILY 0300-0330\nE) REINSTATING RWY 16/34 TO FULL LENGTH.\nEXPECT DELAYS OF APPROXIMATELY 20 MINUTES DUE RUNWAY LENGTH CHANGE.\nREF AIP SUP NR 007/19 RWY 16/34 REHABILITATION - PHASE 1\nCREATED: 20 May 2019 09:53:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T09:53:00.000Z",
    "key": "A1817/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf554",
    "id": "A1816/19",
    "entity": "MT",
    "status": "XX",
    "Qcode": "MTXX",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Other",
    "Subject": "Threshold",
    "Modifier": "Plain language",
    "message": "INSTALLING RUNWAY 28 DISPLACED THRESHOLD.\nEXPECT DELAYS OF APPROXIMATELY 20 MINUTES DUE RUNWAY LENGTH CHANGE.\nREF AIP SUP NR 007/2019 RUNWAY 16/34 REHABILITATION - PHASE 1\nCREATED: 20 May 2019 09:51:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T18:30:00.000Z",
    "enddate": "2019-05-23T19:00:00.000Z",
    "all": "A1816/19 NOTAMN\nQ) EISN/QMTXX/IV/NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905201830 C) 1905231900\nD) DAILY 1830-1900\nE) INSTALLING RUNWAY 28 DISPLACED THRESHOLD.\nEXPECT DELAYS OF APPROXIMATELY 20 MINUTES DUE RUNWAY LENGTH CHANGE.\nREF AIP SUP NR 007/2019 RUNWAY 16/34 REHABILITATION - PHASE 1\nCREATED: 20 May 2019 09:51:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T09:51:00.000Z",
    "key": "A1816/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf555",
    "id": "A1815/19",
    "entity": "MX",
    "status": "LC",
    "Qcode": "MXLC",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Limitations",
    "Subject": "Taxiway(s)",
    "Modifier": "Closed",
    "message": "TWY E1 CLOSED\nREF AIP SUP NR 007/2019 RUNWAY 16/34 REHABILITATION - PHASE 1\nCREATED: 20 May 2019 09:49:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T18:30:00.000Z",
    "enddate": "2019-05-24T03:30:00.000Z",
    "all": "A1815/19 NOTAMN\nQ) EISN/QMXLC/IV/M  /A /000/999/5325N00616W005\nA) EIDW B) 1905201830 C) 1905240330\nD) DAILY 1830-0330\nE) TWY E1 CLOSED\nREF AIP SUP NR 007/2019 RUNWAY 16/34 REHABILITATION - PHASE 1\nCREATED: 20 May 2019 09:49:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T09:49:00.000Z",
    "key": "A1815/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf556",
    "id": "A1795/19",
    "entity": "FA",
    "status": "TT",
    "Qcode": "FATT",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Other",
    "Subject": "Aerodrome",
    "Modifier": "AIRAC AIP Amendments",
    "message": "TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR 006 EFFECTIVE DATE 20 \nJUN 2019. INCLUDED IN THIS AMENDMENT IS THE REVISED EIDW AD CHARTS \n2.24 RELATED TO AERODROME.\nCREATED: 17 May 2019 17:30:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-06-20T00:00:00.000Z",
    "enddate": "2019-07-04T23:59:00.000Z",
    "all": "A1795/19 NOTAMN\nQ) EISN/QFATT/IV/BO /A /000/999/5325N00616W005\nA) EIDW B) 1906200000 C) 1907042359\nE) TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR 006 EFFECTIVE DATE 20 \nJUN 2019. INCLUDED IN THIS AMENDMENT IS THE REVISED EIDW AD CHARTS \n2.24 RELATED TO AERODROME.\nCREATED: 17 May 2019 17:30:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-17T17:30:00.000Z",
    "key": "A1795/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf557",
    "id": "A1781/19",
    "entity": "PI",
    "status": "AU",
    "Qcode": "PIAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Instrument approach procedure",
    "Modifier": "Not available",
    "message": "EIDW LPV IAP RWY 34 NOT AVAILABLE\nREF AIP EIDW AD 2.24-32.1\nCREATED: 17 May 2019 10:50:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-17T10:49:00.000Z",
    "enddate": "2019-08-17T23:59:00.000Z",
    "all": "A1781/19 NOTAMR A1528/19\nQ) EISN/QPIAU/I /NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905171049 C) 1908172359 EST\nE) EIDW LPV IAP RWY 34 NOT AVAILABLE\nREF AIP EIDW AD 2.24-32.1\nCREATED: 17 May 2019 10:50:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-17T10:50:00.000Z",
    "key": "A1781/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf558",
    "id": "A1780/19",
    "entity": "PI",
    "status": "AU",
    "Qcode": "PIAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Instrument approach procedure",
    "Modifier": "Not available",
    "message": "EIDW RWY 16 RNP IAPS NOT AVAILABLE\nREF EIDW AD 2.24-28.1\nCREATED: 17 May 2019 10:48:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-17T10:48:00.000Z",
    "enddate": "2019-08-17T23:59:00.000Z",
    "all": "A1780/19 NOTAMR A1530/19\nQ) EISN/QPIAU/I /NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905171048 C) 1908172359 EST\nE) EIDW RWY 16 RNP IAPS NOT AVAILABLE\nREF EIDW AD 2.24-28.1\nCREATED: 17 May 2019 10:48:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-17T10:48:00.000Z",
    "key": "A1780/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf559",
    "id": "A1779/19",
    "entity": "PI",
    "status": "AU",
    "Qcode": "PIAU",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Availability",
    "Subject": "Instrument approach procedure",
    "Modifier": "Not available",
    "message": "EIDW LPV IAP RWY 10 NOT AVBL. \nREF AIP EIDW AD 2.24-25.1\nCREATED: 17 May 2019 10:41:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-17T10:40:00.000Z",
    "enddate": "2019-08-17T23:59:00.000Z",
    "all": "A1779/19 NOTAMR A1527/19\nQ) EISN/QPIAU/I /NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905171040 C) 1908172359 EST\nE) EIDW LPV IAP RWY 10 NOT AVBL. \nREF AIP EIDW AD 2.24-25.1\nCREATED: 17 May 2019 10:41:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-17T10:41:00.000Z",
    "key": "A1779/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf55a",
    "id": "A1593/19",
    "entity": "FA",
    "status": "XX",
    "Qcode": "FAXX",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Other",
    "Subject": "Aerodrome",
    "Modifier": "Plain language",
    "message": "NO TAKE-OFFS PERMITTED BELOW 125M RVR\nCREATED: 03 May 2019 16:15:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-03T16:12:00.000Z",
    "enddate": "2019-05-31T18:00:00.000Z",
    "all": "A1593/19 NOTAMR A0495/19\nQ) EISN/QFAXX/IV/NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905031612 C) 1905311800 EST\nE) NO TAKE-OFFS PERMITTED BELOW 125M RVR\nCREATED: 03 May 2019 16:15:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-03T16:15:00.000Z",
    "key": "A1593/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf55b",
    "id": "A1571/19",
    "entity": "FA",
    "status": "HX",
    "Qcode": "FAHX",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Hazard Conditions",
    "Subject": "Aerodrome",
    "Modifier": "Concentration of birds",
    "message": "BIRD HAZARD. THERE MAY BE AN INCREASED PRESENCE OF BIRDS AT THE \nMIDPOINT OF RWY 16/34 AT DUBLIN AIRPORT\nCREATED: 03 May 2019 09:11:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-03T09:11:00.000Z",
    "enddate": "2019-08-01T12:00:00.000Z",
    "all": "A1571/19 NOTAMR A0339/19\nQ) EISN/QFAHX/IV/NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905030911 C) 1908011200 EST\nE) BIRD HAZARD. THERE MAY BE AN INCREASED PRESENCE OF BIRDS AT THE \nMIDPOINT OF RWY 16/34 AT DUBLIN AIRPORT\nCREATED: 03 May 2019 09:11:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-03T09:11:00.000Z",
    "key": "A1571/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf55c",
    "id": "A1480/19",
    "entity": "MR",
    "status": "XX",
    "Qcode": "MRXX",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Other",
    "Subject": "Runway",
    "Modifier": "Plain language",
    "message": "CAUTION RUNWAY 16/34 240M OF UNGROOVED RUNWAY SURFACE BETWEEN \nTAXIWAY H1 AND TAXIWAY A. REF AIP SUPPLEMENT 007/2019.\nCREATED: 26 Apr 2019 12:00:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-26T12:00:00.000Z",
    "enddate": "2019-05-31T23:59:00.000Z",
    "all": "A1480/19 NOTAMN\nQ) EISN/QMRXX/IV/NBO/A /000/999/5325N00616W005\nA) EIDW B) 1904261200 C) 1905312359\nE) CAUTION RUNWAY 16/34 240M OF UNGROOVED RUNWAY SURFACE BETWEEN \nTAXIWAY H1 AND TAXIWAY A. REF AIP SUPPLEMENT 007/2019.\nCREATED: 26 Apr 2019 12:00:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-04-26T12:00:00.000Z",
    "key": "A1480/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf55d",
    "id": "A1344/19",
    "entity": "FA",
    "status": "TT",
    "Qcode": "FATT",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Other",
    "Subject": "Aerodrome",
    "Modifier": "AIRAC AIP Amendments",
    "message": "TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR005 EFFECTIVE DATE 23 MAY \n2019. INCLUDED IN THIS AMENDMENT IS THE REVISED EIDW AD 2.18 ATS \nCOMMUNICATION FACILITIES.\nCREATED: 16 Apr 2019 13:11:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-23T00:00:00.000Z",
    "enddate": "2019-06-06T23:59:00.000Z",
    "all": "A1344/19 NOTAMN\nQ) EISN/QFATT/IV/BO /A /000/999/5325N00616W005\nA) EIDW B) 1905230000 C) 1906062359\nE) TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR005 EFFECTIVE DATE 23 MAY \n2019. INCLUDED IN THIS AMENDMENT IS THE REVISED EIDW AD 2.18 ATS \nCOMMUNICATION FACILITIES.\nCREATED: 16 Apr 2019 13:11:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-04-16T13:11:00.000Z",
    "key": "A1344/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf55e",
    "id": "A1299/19",
    "entity": "IM",
    "status": "AS",
    "Qcode": "IMAS",
    "Area": "CNS",
    "SubArea": "Instrument and microwave landing systems",
    "Condition": "Availability",
    "Subject": "Middle marker (ILS)",
    "Modifier": "Unserviceable",
    "message": "ILS MM RWY 10 FREQ 75MHZ U/S\nCREATED: 11 Apr 2019 15:39:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-11T15:38:00.000Z",
    "enddate": "2019-07-11T17:30:00.000Z",
    "all": "A1299/19 NOTAMR A0633/19\nQ) EISN/QIMAS/I /BO /A /000/999/5325N00618W005\nA) EIDW B) 1904111538 C) 1907111730\nE) ILS MM RWY 10 FREQ 75MHZ U/S\nCREATED: 11 Apr 2019 15:39:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-04-11T15:39:00.000Z",
    "key": "A1299/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf55f",
    "id": "A1298/19",
    "entity": "IO",
    "status": "AS",
    "Qcode": "IOAS",
    "Area": "CNS",
    "SubArea": "Instrument and microwave landing systems",
    "Condition": "Availability",
    "Subject": "Outer marker (ILS)",
    "Modifier": "Unserviceable",
    "message": "ILS OM RWY 10 FREQ 75MHZ U/S\nCREATED: 11 Apr 2019 15:38:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-11T15:36:00.000Z",
    "enddate": "2019-07-11T17:30:00.000Z",
    "all": "A1298/19 NOTAMR A0632/19\nQ) EISN/QIOAS/I /BO /A /000/999/5326N00625W005\nA) EIDW B) 1904111536 C) 1907111730\nE) ILS OM RWY 10 FREQ 75MHZ U/S\nCREATED: 11 Apr 2019 15:38:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-04-11T15:38:00.000Z",
    "key": "A1298/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf560",
    "id": "A1297/19",
    "entity": "IM",
    "status": "AS",
    "Qcode": "IMAS",
    "Area": "CNS",
    "SubArea": "Instrument and microwave landing systems",
    "Condition": "Availability",
    "Subject": "Middle marker (ILS)",
    "Modifier": "Unserviceable",
    "message": "ILS MM RWY 28 FREQ 75MHZ U/S\nCREATED: 11 Apr 2019 15:34:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-11T15:34:00.000Z",
    "enddate": "2019-07-11T17:30:00.000Z",
    "all": "A1297/19 NOTAMR A0631/19\nQ) EISN/QIMAS/I /BO /A /000/999/5325N00614W005\nA) EIDW B) 1904111534 C) 1907111730\nE) ILS MM RWY 28 FREQ 75MHZ U/S\nCREATED: 11 Apr 2019 15:34:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-04-11T15:34:00.000Z",
    "key": "A1297/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf561",
    "id": "A1296/19",
    "entity": "IO",
    "status": "AS",
    "Qcode": "IOAS",
    "Area": "CNS",
    "SubArea": "Instrument and microwave landing systems",
    "Condition": "Availability",
    "Subject": "Outer marker (ILS)",
    "Modifier": "Unserviceable",
    "message": "ILS OM RWY 28 FREQ 75MHZ U/S\nCREATED: 11 Apr 2019 15:31:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-11T15:30:00.000Z",
    "enddate": "2019-07-11T17:30:00.000Z",
    "all": "A1296/19 NOTAMR A0630/19\nQ) EISN/QIOAS/I /BO /A /000/999/5325N00608W005\nA) EIDW B) 1904111530 C) 1907111730\nE) ILS OM RWY 28 FREQ 75MHZ U/S\nCREATED: 11 Apr 2019 15:31:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-04-11T15:31:00.000Z",
    "key": "A1296/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf562",
    "id": "A1108/19",
    "entity": "MX",
    "status": "XX",
    "Qcode": "MXXX",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Other",
    "Subject": "Taxiway(s)",
    "Modifier": "Plain language",
    "message": "TWY A WILL BE OPEN FOR DUAL RUNWAY OPERATIONS AT DUBLIN BETWEEN \n0530 - 0700 UTC.\nTWY B2 WILL BE CLOSED WHEN TWY A IS OPEN.\nTWY B2 WILL BE OPEN WHEN TWY A IS CLOSED.\nFOR MORE INFO CONTACT DUBLIN AIRPORT DUTY OFFICE TEL: +353 (087) \n2892222.\nCREATED: 30 Mar 2019 09:55:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-03-30T09:52:00.000Z",
    "enddate": "2019-06-30T23:59:00.000Z",
    "all": "A1108/19 NOTAMR A0680/19\nQ) EISN/QMXXX/IV/M  /A /000/999/5325N00616W005\nA) EIDW B) 1903300952 C) 1906302359\nE) TWY A WILL BE OPEN FOR DUAL RUNWAY OPERATIONS AT DUBLIN BETWEEN \n0530 - 0700 UTC.\nTWY B2 WILL BE CLOSED WHEN TWY A IS OPEN.\nTWY B2 WILL BE OPEN WHEN TWY A IS CLOSED.\nFOR MORE INFO CONTACT DUBLIN AIRPORT DUTY OFFICE TEL: +353 (087) \n2892222.\nCREATED: 30 Mar 2019 09:55:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-03-30T09:55:00.000Z",
    "key": "A1108/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf563",
    "id": "A0653/19",
    "entity": "FA",
    "status": "HX",
    "Qcode": "FAHX",
    "Area": "AGA",
    "SubArea": "Facilities and services",
    "Condition": "Hazard Conditions",
    "Subject": "Aerodrome",
    "Modifier": "Concentration of birds",
    "message": "BIRD HAZARD. THERE IS A CLEAR EMERGING TREND OF BIRD STRIKES \nOCCURRING AT A HIGH ALTITUDE UP TO AND ABOVE 1000FT IN BOTH THE \nAPPROACH AND CLIMB-OUT ZONES AT DUBLIN AIRPORT, ALSO THERE IS AN \nINCREASED CONCENTRATION OF BIRD ACTIVITY AT DUBLIN AIRPORT DUE TO \nCURRENT WEATHER CONDITIONS.\nCREATED: 01 Mar 2019 10:59:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-03-01T10:57:00.000Z",
    "enddate": "2019-06-01T23:59:00.000Z",
    "all": "A0653/19 NOTAMR A3010/18\nQ) EISN/QFAHX/IV/NBO/A /000/999/5325N00616W005\nA) EIDW B) 1903011057 C) 1906012359 EST\nE) BIRD HAZARD. THERE IS A CLEAR EMERGING TREND OF BIRD STRIKES \nOCCURRING AT A HIGH ALTITUDE UP TO AND ABOVE 1000FT IN BOTH THE \nAPPROACH AND CLIMB-OUT ZONES AT DUBLIN AIRPORT, ALSO THERE IS AN \nINCREASED CONCENTRATION OF BIRD ACTIVITY AT DUBLIN AIRPORT DUE TO \nCURRENT WEATHER CONDITIONS.\nCREATED: 01 Mar 2019 10:59:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-03-01T10:59:00.000Z",
    "key": "A0653/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf564",
    "id": "A0562/19",
    "entity": "MA",
    "status": "XX",
    "Qcode": "MAXX",
    "Area": "AGA",
    "SubArea": "Movement and landing area",
    "Condition": "Other",
    "Subject": "Movement area",
    "Modifier": "Plain language",
    "message": "TAXIWAY Z NOT AVAILABLE FOR AIRCRAFT WITH A WINGSPAN GREATER THAN \n36M (118FT) DUE SAFETY, \nAS PUBLISHED IN IRELAND AIP EIDW AD 2.20.1.3\nCREATED: 22 Feb 2019 09:13:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-02-22T09:12:00.000Z",
    "enddate": "2019-05-22T12:00:00.000Z",
    "all": "A0562/19 NOTAMR A3009/18\nQ) EISN/QMAXX/IV/NBO/A /000/999/5325N00616W005\nA) EIDW B) 1902220912 C) 1905221200 EST\nE) TAXIWAY Z NOT AVAILABLE FOR AIRCRAFT WITH A WINGSPAN GREATER THAN \n36M (118FT) DUE SAFETY, \nAS PUBLISHED IN IRELAND AIP EIDW AD 2.20.1.3\nCREATED: 22 Feb 2019 09:13:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-02-22T09:13:00.000Z",
    "key": "A0562/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf565",
    "id": "B0545/19",
    "entity": "LK",
    "status": "AS",
    "Qcode": "LKAS",
    "Area": "AGA",
    "SubArea": "Lighting facilities",
    "Condition": "Availability",
    "Subject": "Category II components of approach lighting system",
    "Modifier": "Unserviceable",
    "message": "RWY 10 CAT II APPROACH LIGHTING NOT AVAILABLE.\nREF AIP SUP NR 007/2019 RUNWAY 16/34 REHABILITATION - PHASE 1\nCREATED: 20 May 2019 09:59:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T18:30:00.000Z",
    "enddate": "2019-05-24T03:30:00.000Z",
    "all": "B0545/19 NOTAMN\nQ) EISN/QLKAS/I /BO /A /000/999/5325N00616W005\nA) EIDW B) 1905201830 C) 1905240330\nD) DAILY 1830-0330\nE) RWY 10 CAT II APPROACH LIGHTING NOT AVAILABLE.\nREF AIP SUP NR 007/2019 RUNWAY 16/34 REHABILITATION - PHASE 1\nCREATED: 20 May 2019 09:59:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T09:59:00.000Z",
    "key": "B0545/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf566",
    "id": "B0544/19",
    "entity": "LA",
    "status": "AS",
    "Qcode": "LAAS",
    "Area": "AGA",
    "SubArea": "Lighting facilities",
    "Condition": "Availability",
    "Subject": "Approach lighting system",
    "Modifier": "Unserviceable",
    "message": "RWY 10 CAT III APPROACH LIGHTING NOT AVAILABLE.\nREF AIP SUP NR 007/2019 RWY 16/34 REHABILITATION - PHASE 1\nCREATED: 20 May 2019 09:56:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-20T18:30:00.000Z",
    "enddate": "2019-05-24T03:30:00.000Z",
    "all": "B0544/19 NOTAMN\nQ) EISN/QLAAS/IV/NBO/A /000/999/5325N00616W005\nA) EIDW B) 1905201830 C) 1905240330\nD) DAILY 1830-0330\nE) RWY 10 CAT III APPROACH LIGHTING NOT AVAILABLE.\nREF AIP SUP NR 007/2019 RWY 16/34 REHABILITATION - PHASE 1\nCREATED: 20 May 2019 09:56:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-20T09:56:00.000Z",
    "key": "B0544/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf567",
    "id": "B0115/19",
    "entity": "CA",
    "status": "XX",
    "Qcode": "CAXX",
    "Area": "CNS",
    "SubArea": "Communications and surveillance facilities",
    "Condition": "Other",
    "Subject": "Air/ground facility",
    "Modifier": "Plain language",
    "message": "DUBLIN DELIVERY, NON 8.33KHZ EQUIPPED ACFT SHALL CONTACT 121.800MHZ \nFOR ATC CLEARANCE MINIMUM 15 MINUTES PRIOR TO REQUESTED START UP. \nREF EIDW AD 2.18\nCREATED: 07 Feb 2019 14:50:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-02-07T14:50:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B0115/19 NOTAMN\nQ) EISN/QCAXX/IV/B  /AE/000/999/5325N00616W005\nA) EIDW B) 1902071450 C) PERM\nE) \nDUBLIN DELIVERY, NON 8.33KHZ EQUIPPED ACFT SHALL CONTACT 121.800MHZ \nFOR ATC CLEARANCE MINIMUM 15 MINUTES PRIOR TO REQUESTED START UP. \nREF EIDW AD 2.18\nCREATED: 07 Feb 2019 14:50:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-02-07T14:50:00.000Z",
    "key": "B0115/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf568",
    "id": "B0110/19",
    "entity": "AT",
    "status": "CH",
    "Qcode": "ATCH",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Changes",
    "Subject": "Terminal control area",
    "Modifier": "Changed",
    "message": "MINIMUM SECTOR ALTITUDE RWY 34 RNAV STANDARD DEPARTURE CHARTS \nINSTRUMENT (SIDS) AND TRANSITION TO 8.33KHZ CHANNEL SPACING THE \nFOLLOWING WILL APPLY\nCORRECTION: MINIMUM SECTOR ALTITUDE (MSA) 25 NM FROM DUB VOR/DME \nINCREASED TO 2400 INBOUND BEARING 333 DEGREES ANTICLOCKWISE TO \nINBOUND BEARING 049 DEGREES DUB VOR/DME\nDUBLIN CHANNELS CHANGED AS FOLLOWS:\nDUBLIN ACC LOWER NORTH: 132.580\nDUBLIN ACC LOWER SOUTH:  120.755\nATIS: 124.530\nTHE FOLLOWING CHARTS HAVE BEEN AFFECTED \nREF EIDW AD 2.24-15 AND EIDW AD 2.24-16\nCREATED: 02 Feb 2019 13:35:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-02-02T13:34:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B0110/19 NOTAMR B0105/19\nQ) EISN/QATCH/IV/NBO/AE/000/999/5325N00616W025\nA) EIDW B) 1902021334 C) PERM\nE) MINIMUM SECTOR ALTITUDE RWY 34 RNAV STANDARD DEPARTURE CHARTS \nINSTRUMENT (SIDS) AND TRANSITION TO 8.33KHZ CHANNEL SPACING THE \nFOLLOWING WILL APPLY\nCORRECTION: MINIMUM SECTOR ALTITUDE (MSA) 25 NM FROM DUB VOR/DME \nINCREASED TO 2400 INBOUND BEARING 333 DEGREES ANTICLOCKWISE TO \nINBOUND BEARING 049 DEGREES DUB VOR/DME\nDUBLIN CHANNELS CHANGED AS FOLLOWS:\nDUBLIN ACC LOWER NORTH: 132.580\nDUBLIN ACC LOWER SOUTH:  120.755\nATIS: 124.530\nTHE FOLLOWING CHARTS HAVE BEEN AFFECTED \nREF EIDW AD 2.24-15 AND EIDW AD 2.24-16\nCREATED: 02 Feb 2019 13:35:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-02-02T13:35:00.000Z",
    "key": "B0110/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf569",
    "id": "B0109/19",
    "entity": "AT",
    "status": "CH",
    "Qcode": "ATCH",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Changes",
    "Subject": "Terminal control area",
    "Modifier": "Changed",
    "message": "MINIMUM SECTOR ALTITUDE RWY 28 RNAV STANDARD DEPARTURE CHARTS \nINSTRUMENT (SIDS) AND  TRANSITION TO 8.33KHZ CHANNELS THE FOLLOWING \nWILL APPLY\nCORRECTION: MINIMUM SECTOR ALTITUDE (MSA) 25 NM FROM DUB VOR/DME \nINCREASED TO 2400 INBOUND BEARING 333 DEGREES ANTICLOCKWISE TO \nINBOUND BEARING 049 DEGREES DUB VOR/DME\nDUBLIN CHANNELS CHANGED AS FOLLOWS:\nDUBLIN ACC LOWER NORTH: 132.580\nDUBLIN ACC LOWER SOUTH: 120.755\nATIS: 124.530\nTHE FOLLOWING CHARTS HAVE BEEN AFFECTED \nREF EIDW AD 2.24-9 AND EIDW AD 2.24-10\nCREATED: 02 Feb 2019 13:34:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-02-02T13:31:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B0109/19 NOTAMR B0104/19\nQ) EISN/QATCH/IV/NBO/AE/000/999/5325N00616W025\nA) EIDW B) 1902021331 C) PERM\nE) MINIMUM SECTOR ALTITUDE RWY 28 RNAV STANDARD DEPARTURE CHARTS \nINSTRUMENT (SIDS) AND  TRANSITION TO 8.33KHZ CHANNELS THE FOLLOWING \nWILL APPLY\nCORRECTION: MINIMUM SECTOR ALTITUDE (MSA) 25 NM FROM DUB VOR/DME \nINCREASED TO 2400 INBOUND BEARING 333 DEGREES ANTICLOCKWISE TO \nINBOUND BEARING 049 DEGREES DUB VOR/DME\nDUBLIN CHANNELS CHANGED AS FOLLOWS:\nDUBLIN ACC LOWER NORTH: 132.580\nDUBLIN ACC LOWER SOUTH: 120.755\nATIS: 124.530\nTHE FOLLOWING CHARTS HAVE BEEN AFFECTED \nREF EIDW AD 2.24-9 AND EIDW AD 2.24-10\nCREATED: 02 Feb 2019 13:34:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-02-02T13:34:00.000Z",
    "key": "B0109/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf56a",
    "id": "B0108/19",
    "entity": "CA",
    "status": "CF",
    "Qcode": "CACF",
    "Area": "CNS",
    "SubArea": "Communications and surveillance facilities",
    "Condition": "Changes",
    "Subject": "Air/ground facility",
    "Modifier": "Operating frequency(ies) changed to",
    "message": "VISUAL APPROACH CHART - ICAO\nTRANSITION TO 8.33KHZ CHANNELS \nTHE FOLLOWING WILL APPLY \nDUBLIN CHANNELS CHANGED AS FOLLOWS:\nRADAR:  132.580\n        120.755\n\nATIS:  124.530\nCREATED: 02 Feb 2019 03:08:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-02-02T03:07:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B0108/19 NOTAMN\nQ) EISN/QCACF/IV/B  /AE/000/999/5325N00616W999\nA) EIDW B) 1902020307 C) PERM\nE) VISUAL APPROACH CHART - ICAO\nTRANSITION TO 8.33KHZ CHANNELS \nTHE FOLLOWING WILL APPLY \nDUBLIN CHANNELS CHANGED AS FOLLOWS:\nRADAR:  132.580\n        120.755\n\nATIS:  124.530\nCREATED: 02 Feb 2019 03:08:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-02-02T03:08:00.000Z",
    "key": "B0108/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf56b",
    "id": "B0107/19",
    "entity": "AT",
    "status": "CH",
    "Qcode": "ATCH",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Changes",
    "Subject": "Terminal control area",
    "Modifier": "Changed",
    "message": "MINIMUM SECTOR ALTITUDE RWY 34 RNAV STANDARD ARRIVAL CHART \nINSTRUMENT(STAR) AND TRANSITION TO 8.33KHZ CHANNELS THE FOLLOWING \nWILL APPLY \nCORRECTION: MINIMUM SECTOR ALTITUDE (MSA) 25 NM FROM DUB VOR/DME \nINCREASED TO 2400 INBOUND BEARING 333 DEGREES ANTICLOCKWISE TO \nINBOUND BEARING 049 DEGREES DUB VOR/DME\nDUBLIN CHANNELS CHANGED AS FOLLOWS:\nDUBLIN ACC LOWER NORTH: 132.580\nDUBLIN ACC UPPER NORTH: 129.180\nDUBLIN ACC LOWER SOUTH: 120.755\nDUBLIN ACC UPPER SOUTH: 135.655\nATIS: 124.530\nTHE FOLLOWING CHARTS HAVE BEEN AFFECTED \nREF EIDW AD 2.24-21\nCREATED: 02 Feb 2019 03:02:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-02-02T03:01:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B0107/19 NOTAMN\nQ) EISN/QATCH/IV/NBO/AE/000/999/5325N00616W025\nA) EIDW B) 1902020301 C) PERM\nE) MINIMUM SECTOR ALTITUDE RWY 34 RNAV STANDARD ARRIVAL CHART \nINSTRUMENT(STAR) AND TRANSITION TO 8.33KHZ CHANNELS THE FOLLOWING \nWILL APPLY \nCORRECTION: MINIMUM SECTOR ALTITUDE (MSA) 25 NM FROM DUB VOR/DME \nINCREASED TO 2400 INBOUND BEARING 333 DEGREES ANTICLOCKWISE TO \nINBOUND BEARING 049 DEGREES DUB VOR/DME\nDUBLIN CHANNELS CHANGED AS FOLLOWS:\nDUBLIN ACC LOWER NORTH: 132.580\nDUBLIN ACC UPPER NORTH: 129.180\nDUBLIN ACC LOWER SOUTH: 120.755\nDUBLIN ACC UPPER SOUTH: 135.655\nATIS: 124.530\nTHE FOLLOWING CHARTS HAVE BEEN AFFECTED \nREF EIDW AD 2.24-21\nCREATED: 02 Feb 2019 03:02:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-02-02T03:02:00.000Z",
    "key": "B0107/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf56c",
    "id": "B0106/19",
    "entity": "CA",
    "status": "CF",
    "Qcode": "CACF",
    "Area": "CNS",
    "SubArea": "Communications and surveillance facilities",
    "Condition": "Changes",
    "Subject": "Air/ground facility",
    "Modifier": "Operating frequency(ies) changed to",
    "message": "RWY 28 RNAV STANDARD ARRIVAL CHART INSTRUMENT (STAR) (WITH LATERAL \nHOLDING/POINT MERGE) AND TRANSITION TO 8.33KHZ CHANNELS THE \nFOLLOWING WILL APPLY \nCORRECTION:\n\nDUBLIN CHANNELS CHANGED AS FOLLOWS:\n\nDUBLIN ACC LOWER SOUTH:  120.755\nDUBLIN ACC UPPER SOUTH:  135.655\nDUBLIN ACC LOWER NORTH:  132.580\nDUBLIN ACC UPPER NORTH:  129.180\nTHE FOLLOWING CHARTS HAVE BEEN AFFECTED \nREF EIDW AD 2.24-17.1\nCREATED: 02 Feb 2019 02:58:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-02-02T02:57:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B0106/19 NOTAMN\nQ) EISN/QCACF/IV/B  /AE/000/999/5325N00616W999\nA) EIDW B) 1902020257 C) PERM\nE) \nRWY 28 RNAV STANDARD ARRIVAL CHART INSTRUMENT (STAR) (WITH LATERAL \nHOLDING/POINT MERGE) AND TRANSITION TO 8.33KHZ CHANNELS THE \nFOLLOWING WILL APPLY \nCORRECTION:\n\nDUBLIN CHANNELS CHANGED AS FOLLOWS:\n\nDUBLIN ACC LOWER SOUTH:  120.755\nDUBLIN ACC UPPER SOUTH:  135.655\nDUBLIN ACC LOWER NORTH:  132.580\nDUBLIN ACC UPPER NORTH:  129.180\nTHE FOLLOWING CHARTS HAVE BEEN AFFECTED \nREF EIDW AD 2.24-17.1\nCREATED: 02 Feb 2019 02:58:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-02-02T02:58:00.000Z",
    "key": "B0106/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf56d",
    "id": "B1138/15",
    "entity": "PH",
    "status": "CH",
    "Qcode": "PHCH",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Changes",
    "Subject": "Holding procedure",
    "Modifier": "Changed",
    "message": "HOLDING PROCEDURE CHANGED. RATE OF DESCENT IN HOLDING PATTERNS \nAMENDED TO: A STANDARD RATE OF DESCENT OF 1000FT PER MIN IN HOLDING \nPATTERNS WILL BE USED UNLESS OTHERWISE INSTRUCTED BY ATC. PILOTS \nMUST ADVISE ATC IF UNABLE TO COMPLY WITH THE STANDARD RATE OF \nDESCENT. \nREF AIP EIDW AD 2.22 SECTION 8\nCREATED: 01 May 2017 11:24:00 \nSOURCE: EUECYIYN",
    "startdate": "2015-10-02T17:47:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B1138/15 NOTAMN\nQ) EISN/QPHCH/IV/BO /AE/000/999/5325N00616W025\nA) EIDW B) 1510021747 C) PERM\nE) HOLDING PROCEDURE CHANGED. RATE OF DESCENT IN HOLDING PATTERNS \nAMENDED TO: A STANDARD RATE OF DESCENT OF 1000FT PER MIN IN HOLDING \nPATTERNS WILL BE USED UNLESS OTHERWISE INSTRUCTED BY ATC. PILOTS \nMUST ADVISE ATC IF UNABLE TO COMPLY WITH THE STANDARD RATE OF \nDESCENT. \nREF AIP EIDW AD 2.22 SECTION 8\nCREATED: 01 May 2017 11:24:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2017-05-01T11:24:00.000Z",
    "key": "B1138/15-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf56e",
    "id": "N0064/19",
    "entity": "OB",
    "status": "CE",
    "Qcode": "OBCE",
    "Area": "Other Information",
    "SubArea": "Other Information",
    "Condition": "Changes",
    "Subject": "Obstacle",
    "Modifier": "Erected",
    "message": "OBSTACLE ERECTED - TEMPORARY CRANE ELEVATION 384FT AMSL \nLOCATION 532350.46N 0061552.98W LIGHTED.\nCREATED: 17 May 2019 11:17:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-17T19:00:00.000Z",
    "enddate": "2019-08-17T19:00:00.000Z",
    "all": "N0064/19 NOTAMN\nQ) EISN/QOBCE/IV/M  /AE/000/004/5323N00615W001\nA) EIDW B) 1905171900 C) 1908171900\nE) OBSTACLE ERECTED - TEMPORARY CRANE ELEVATION 384FT AMSL \nLOCATION 532350.46N 0061552.98W LIGHTED.\nCREATED: 17 May 2019 11:17:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-17T11:17:00.000Z",
    "key": "N0064/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d487ab233e8499cdf56f",
    "id": "N0053/19",
    "entity": "OL",
    "status": "AS",
    "Qcode": "OLAS",
    "Area": "Other Information",
    "SubArea": "Other Information",
    "Condition": "Availability",
    "Subject": "Obstacle lights on",
    "Modifier": "Unserviceable",
    "message": "OBSTACLE LIGHTS ON PIGEON HOUSE CHIMNEYS, COUNTY DUBLIN OUT OF \nSERVICE. POSITION 532025N 0061124W, ELEVATION 694FT\nCREATED: 01 May 2019 17:11:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-01T17:11:00.000Z",
    "enddate": "2019-08-01T12:00:00.000Z",
    "all": "N0053/19 NOTAMR N0010/19\nQ) EISN/QOLAS/IV/M  /AE/000/007/5320N00611W005\nA) EIDW B) 1905011711 C) 1908011200 EST\nE) OBSTACLE LIGHTS ON PIGEON HOUSE CHIMNEYS, COUNTY DUBLIN OUT OF \nSERVICE. POSITION 532025N 0061124W, ELEVATION 694FT\nCREATED: 01 May 2019 17:11:00 \nSOURCE: EUECYIYN",
    "location": "EIDW",
    "isICAO": true,
    "Created": "2019-05-01T17:11:00.000Z",
    "key": "N0053/19-EIDW",
    "type": "airport",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45e4",
    "id": "A1549/19",
    "entity": "KK",
    "status": "KK",
    "Qcode": "KKKK",
    "Area": "Other Information",
    "SubArea": "NOTAM Checklist",
    "Condition": "",
    "Subject": "NOTAM Checklist",
    "Modifier": "",
    "message": "CHECKLIST\nYEAR=2019 0339 0495 0540 0541 0561 0562 0653 0675 0676 0687 0953 0954\n          1091 1092 1094 1107 1108 1141 1236 1237 1245 1246 1296 1297\n          1298 1299 1305 1344 1345 1406 1407 1414 1415 1416 1417 1450\n          1452 1453 1455 1469 1472 1480 1482 1510 1512 1513 1514 1527\n          1528 1530 1533 1536 1537 1539 1540 1541 1547\n \nLATEST PUBLICATIONS\nAIP AIRAC AMDT IFR 005/2019 EFFECTIVE DATE 23 MAY 19\nAIP AIRAC SUP IFR 011/2019 EFFECTIVE DATE 25 APR 19\nAIP SUP IFR 001/2019 EFFECTIVE DATE 31 JAN 19\nAIC IFR 007/2019 EFFECTIVE DATE 28 MAR 19\nCREATED: 01 May 2019 09:28:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-01T09:27:00.000Z",
    "enddate": "2019-06-01T09:27:00.000Z",
    "all": "A1549/19 NOTAMR A1117/19\nQ) EISN/QKKKK/K /K  /K /000/999/5257N01045W999\nA) EISN B) 1905010927 C) 1906010927 EST\nE) CHECKLIST\nYEAR=2019 0339 0495 0540 0541 0561 0562 0653 0675 0676 0687 0953 0954\n          1091 1092 1094 1107 1108 1141 1236 1237 1245 1246 1296 1297\n          1298 1299 1305 1344 1345 1406 1407 1414 1415 1416 1417 1450\n          1452 1453 1455 1469 1472 1480 1482 1510 1512 1513 1514 1527\n          1528 1530 1533 1536 1537 1539 1540 1541 1547\n \nLATEST PUBLICATIONS\nAIP AIRAC AMDT IFR 005/2019 EFFECTIVE DATE 23 MAY 19\nAIP AIRAC SUP IFR 011/2019 EFFECTIVE DATE 25 APR 19\nAIP SUP IFR 001/2019 EFFECTIVE DATE 31 JAN 19\nAIC IFR 007/2019 EFFECTIVE DATE 28 MAR 19\nCREATED: 01 May 2019 09:28:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-05-01T09:28:00.000Z",
    "key": "A1549/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45e5",
    "id": "B0534/19",
    "entity": "AF",
    "status": "TT",
    "Qcode": "AFTT",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Other",
    "Subject": "Flight information region",
    "Modifier": "AIRAC AIP Amendments",
    "message": "TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR 006 EFFECTIVE DATE 20 \nJUN 2019. INCLUDED IN THIS AMENDMENT IS THE REVISED ENR 1.11 \nADDRESSING OF FLIGHT PLAN MESSAGES, ENR 3.1 LOWER ATS ROUTES \nDELETION, ENR 3.3 AREA NAVIGATION (RNAV) ROUTES INSERTION NEW \nSECTION, REVISED ENR 4.4 NAME CODE DESIGNATORS FOR SIGNIFICANT \nPOINTS AND REVISED ENR 6-1 LOWER ATS ROUTES CHART.\nCREATED: 17 May 2019 17:24:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-06-20T00:00:00.000Z",
    "enddate": "2019-07-04T23:59:00.000Z",
    "all": "B0534/19 NOTAMN\nQ) EISN/QAFTT/IV/BO /E /000/999/5257N01045W999\nA) EISN B) 1906200000 C) 1907042359\nE) TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR 006 EFFECTIVE DATE 20 \nJUN 2019. INCLUDED IN THIS AMENDMENT IS THE REVISED ENR 1.11 \nADDRESSING OF FLIGHT PLAN MESSAGES, ENR 3.1 LOWER ATS ROUTES \nDELETION, ENR 3.3 AREA NAVIGATION (RNAV) ROUTES INSERTION NEW \nSECTION, REVISED ENR 4.4 NAME CODE DESIGNATORS FOR SIGNIFICANT \nPOINTS AND REVISED ENR 6-1 LOWER ATS ROUTES CHART.\nCREATED: 17 May 2019 17:24:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-05-17T17:24:00.000Z",
    "key": "B0534/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45e6",
    "id": "B0533/19",
    "entity": "AF",
    "status": "TT",
    "Qcode": "AFTT",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Other",
    "Subject": "Flight information region",
    "Modifier": "AIRAC AIP Amendments",
    "message": "TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR006 EFFECTIVE \nDATE 20 JUN 2019.\nINCLUDED IN THIS AMENDMENT IS THE REVISED GEN 3.2 \nAERONAUTICAL CHARTS.\nCREATED: 17 May 2019 16:42:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-06-20T00:00:00.000Z",
    "enddate": "2019-07-04T23:59:00.000Z",
    "all": "B0533/19 NOTAMN\nQ) EISN/QAFTT/IV/BO /E /000/999/5257N01045W999\nA) EISN B) 1906200000 C) 1907042359\nE) TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR006 EFFECTIVE \nDATE 20 JUN 2019.\nINCLUDED IN THIS AMENDMENT IS THE REVISED GEN 3.2 \nAERONAUTICAL CHARTS.\nCREATED: 17 May 2019 16:42:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-05-17T16:42:00.000Z",
    "key": "B0533/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45e7",
    "id": "B0532/19",
    "entity": "AF",
    "status": "TT",
    "Qcode": "AFTT",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Other",
    "Subject": "Flight information region",
    "Modifier": "AIRAC AIP Amendments",
    "message": "SUPPLEMENT TRIGGER NOTAM IRELAND AIRAC SUP NR 012/19 AND SUP \n013/19, EFFECTIVE DATE 20 JUN 2019 REFERS TO AIRAC NR 006/19.\nSUPPLEMENT NR 012/19 REFERS TO ENR 3.3 AREA NAVIGATION (RNAV) ROUTES.\nSUPPLEMENT NR 013/19 REFERS TO ENR 4.4 NAME CODE DESIGNATORS FOR \nSIGNIFICANT POINTS. THE INFORMATION CONTAINED IN BOTH THESE SUP'S IS \nEFFECTIVE UNTIL THE 11TH SEP 2019 AT 2359.\nCREATED: 17 May 2019 16:29:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-06-20T00:00:00.000Z",
    "enddate": "2019-07-04T23:59:00.000Z",
    "all": "B0532/19 NOTAMN\nQ) EISN/QAFTT/IV/BO /E /000/999/5257N01045W999\nA) EISN B) 1906200000 C) 1907042359\nE) SUPPLEMENT TRIGGER NOTAM IRELAND AIRAC SUP NR 012/19 AND SUP \n013/19, EFFECTIVE DATE 20 JUN 2019 REFERS TO AIRAC NR 006/19.\nSUPPLEMENT NR 012/19 REFERS TO ENR 3.3 AREA NAVIGATION (RNAV) ROUTES.\nSUPPLEMENT NR 013/19 REFERS TO ENR 4.4 NAME CODE DESIGNATORS FOR \nSIGNIFICANT POINTS. THE INFORMATION CONTAINED IN BOTH THESE SUP'S IS \nEFFECTIVE UNTIL THE 11TH SEP 2019 AT 2359.\nCREATED: 17 May 2019 16:29:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-05-17T16:29:00.000Z",
    "key": "B0532/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45e8",
    "id": "B0518/19",
    "entity": "AF",
    "status": "XX",
    "Qcode": "AFXX",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Other",
    "Subject": "Flight information region",
    "Modifier": "Plain language",
    "message": "DEVIATIONS FROM ATC CLEARANCE BY EASTBOUND AIRCRAFT.\nFLIGHT CREW ARE REMINDED OF THE FOLLOWING, EASTBOUND ROUTE \nCLEARANCES ISSUED BY SHANNON CONTROL FOR AIRCRAFT EXITING OCEANIC \nAIRSPACE APPLY FROM AGORI, SUNOT, BILTO, PIKIL, ETARI, RESNO, VENER, \nDOGAL, NEBIN, MALOT, TOBOR, LIMRI, ADARA, DINIM, RODEL, SOMAX, \nKOGAD, BEDRA, OMOKO, TAMEL AND LASNO. FLIGHTS SHALL NOT TURN BEFORE \nTHESE POINTS. REF AIP ENR 2-2\nCREATED: 15 May 2019 09:07:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-15T12:00:00.000Z",
    "enddate": "2019-08-14T11:59:00.000Z",
    "all": "B0518/19 NOTAMN\nQ) EISN/QAFXX/IV/NBO/E /000/999/5257N01045W999\nA) EISN B) 1905151200 C) 1908141159\nE) DEVIATIONS FROM ATC CLEARANCE BY EASTBOUND AIRCRAFT.\nFLIGHT CREW ARE REMINDED OF THE FOLLOWING, EASTBOUND ROUTE \nCLEARANCES ISSUED BY SHANNON CONTROL FOR AIRCRAFT EXITING OCEANIC \nAIRSPACE APPLY FROM AGORI, SUNOT, BILTO, PIKIL, ETARI, RESNO, VENER, \nDOGAL, NEBIN, MALOT, TOBOR, LIMRI, ADARA, DINIM, RODEL, SOMAX, \nKOGAD, BEDRA, OMOKO, TAMEL AND LASNO. FLIGHTS SHALL NOT TURN BEFORE \nTHESE POINTS. REF AIP ENR 2-2\nCREATED: 15 May 2019 09:07:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-05-15T09:07:00.000Z",
    "key": "B0518/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45e9",
    "id": "B0501/19",
    "entity": "AF",
    "status": "XX",
    "Qcode": "AFXX",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Other",
    "Subject": "Flight information region",
    "Modifier": "Plain language",
    "message": "FURTHER TO NOTAM B0366/19 ISSUED BY THE IRISH AVIATION AUTHORITY \n(THE IAA) WITH EFFECT FROM 3 PM ON 12TH APRIL 2019 AND IN PURSUANCE \nOF SECTIONS 58 AND SECTION 60 OF THE IRISH AVIATION ACT 1993 (AS \nAMENDED) S.I. NO.324 OF 1996 (AS AMENDED), S.I. NO. 107 OF 2015, \nS.I. NO. 61 OF 2006 AND S.I. 355 OF 2008 THE IAA HEREBY DIRECTS THE \nCONTINUED TEMPORARY SUSPENSION OF FLIGHTS BY ALL BOEING 737 MAX \nAIRCRAFT REGISTERED IN IRELAND WHEREVER THEY ARE LOCATED AND \nPROHIBITS SUCH AIRCRAFT REGISTERED IN OTHER JURISDICTIONS FROM \nENTERING IRISH AIRSPACE.\nTHE IAA CONSIDERS THIS IS A NECESSARY AND EXPEDIENT SAFETY MEASURE \nTO ENSURE THE SAFETY OF THE PUBLIC, PASSENGERS, CREW AND AIRCRAFT.\nCREATED: 10 May 2019 12:52:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-10T15:00:00.000Z",
    "enddate": "2019-06-07T15:00:00.000Z",
    "all": "B0501/19 NOTAMN\nQ) EISN/QAFXX/I /NBO/E /000/999/5257N01045W999\nA) EISN B) 1905101500 C) 1906071500\nE) FURTHER TO NOTAM B0366/19 ISSUED BY THE IRISH AVIATION AUTHORITY \n(THE IAA) WITH EFFECT FROM 3 PM ON 12TH APRIL 2019 AND IN PURSUANCE \nOF SECTIONS 58 AND SECTION 60 OF THE IRISH AVIATION ACT 1993 (AS \nAMENDED) S.I. NO.324 OF 1996 (AS AMENDED), S.I. NO. 107 OF 2015, \nS.I. NO. 61 OF 2006 AND S.I. 355 OF 2008 THE IAA HEREBY DIRECTS THE \nCONTINUED TEMPORARY SUSPENSION OF FLIGHTS BY ALL BOEING 737 MAX \nAIRCRAFT REGISTERED IN IRELAND WHEREVER THEY ARE LOCATED AND \nPROHIBITS SUCH AIRCRAFT REGISTERED IN OTHER JURISDICTIONS FROM \nENTERING IRISH AIRSPACE.\nTHE IAA CONSIDERS THIS IS A NECESSARY AND EXPEDIENT SAFETY MEASURE \nTO ENSURE THE SAFETY OF THE PUBLIC, PASSENGERS, CREW AND AIRCRAFT.\nCREATED: 10 May 2019 12:52:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-05-10T12:52:00.000Z",
    "key": "B0501/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45ea",
    "id": "B0471/19",
    "entity": "AF",
    "status": "XX",
    "Qcode": "AFXX",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Other",
    "Subject": "Flight information region",
    "Modifier": "Plain language",
    "message": "FLIGHT IN EGYPT AIRSPACE, CAIRO FLIGHT INFORMATION REGION (FIR \nHECC), SINAI PENINSULA.\nTHE IRISH AVIATION AUTHORITY HAS BEEN DIRECTED BY THE MINISTER FOR \nTRANSPORT, TOURISM AND SPORT UNDER SECTION 7 (1) (B) OF THE IRISH \nAVIATION ACT 1993, TO ISSUE A DIRECTION TO IRISH AOC CARRIERS THAT \nIRISH AOC AIRCRAFT SHOULD NOT FLY BELOW 2600OFT IN EGYPT AIRSPACE, \nCAIRO FLIGHT INFORMATION REGION (FIR HECC), SINAI PENINSULA.\nTHE IRISH AVIATION AUTHORITY, IN PURSUANCE OF ARTICLES 4,7 AND 25 OF \nTHE IRISH AVIATION AUTHORITY (OPERATIONS) ORDER (S.I. NO.61 OF \n2006), (HEREINAFTER REFERRED TO AS THE ORDER), HEREBY DIRECTS THAT \nALL IRISH AOC AIRCRAFT SHOULD NOT FLY BELOW 26000FT IN EGYPT'S \nAIRSPACE CAIRO FLIGHT INFORMATION REGION (FIR HECC), SINAI PENINSULA.\nCREATED: 01 May 2019 15:58:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-01T15:55:00.000Z",
    "enddate": "2019-08-01T17:00:00.000Z",
    "all": "B0471/19 NOTAMR B0078/19\nQ) EISN/QAFXX/IV/NBO/E /000/999/5257N01045W999\nA) EISN B) 1905011555 C) 1908011700 EST\nE) FLIGHT IN EGYPT AIRSPACE, CAIRO FLIGHT INFORMATION REGION (FIR \nHECC), SINAI PENINSULA.\nTHE IRISH AVIATION AUTHORITY HAS BEEN DIRECTED BY THE MINISTER FOR \nTRANSPORT, TOURISM AND SPORT UNDER SECTION 7 (1) (B) OF THE IRISH \nAVIATION ACT 1993, TO ISSUE A DIRECTION TO IRISH AOC CARRIERS THAT \nIRISH AOC AIRCRAFT SHOULD NOT FLY BELOW 2600OFT IN EGYPT AIRSPACE, \nCAIRO FLIGHT INFORMATION REGION (FIR HECC), SINAI PENINSULA.\nTHE IRISH AVIATION AUTHORITY, IN PURSUANCE OF ARTICLES 4,7 AND 25 OF \nTHE IRISH AVIATION AUTHORITY (OPERATIONS) ORDER (S.I. NO.61 OF \n2006), (HEREINAFTER REFERRED TO AS THE ORDER), HEREBY DIRECTS THAT \nALL IRISH AOC AIRCRAFT SHOULD NOT FLY BELOW 26000FT IN EGYPT'S \nAIRSPACE CAIRO FLIGHT INFORMATION REGION (FIR HECC), SINAI PENINSULA.\nCREATED: 01 May 2019 15:58:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-05-01T15:58:00.000Z",
    "key": "B0471/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45eb",
    "id": "B0468/19",
    "entity": "KK",
    "status": "KK",
    "Qcode": "KKKK",
    "Area": "Other Information",
    "SubArea": "NOTAM Checklist",
    "Condition": "",
    "Subject": "NOTAM Checklist",
    "Modifier": "",
    "message": "CHECKLIST\nYEAR=2013 0022\nYEAR=2014 NIL\nYEAR=2015 1137 1138 1140 1142 1434\nYEAR=2016 NIL\nYEAR=2017 0334 0335\nYEAR=2018 NIL\nYEAR=2019 0057 0078 0106 0107 0108 0109 0110 0115 0227 0228 0251 0267\n          0268 0366 0390 0391 0427 0428 0429 0439 0445 0446 0447 0465\n \nLATEST PUBLICATIONS\nAIP AIRAC AMDT IFR 005/2019 EFFECTIVE DATE 23 MAY 19\nAIP AIRAC SUP IFR 011/2019 EFFECTIVE DATE 25 APR 19\nAIP SUP IFR 001/2019 EFFECTIVE DATE 31 JAN 19\nAIC IFR 007/2019 EFFECTIVE DATE 28 MAR 19\nCREATED: 01 May 2019 09:29:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-01T09:28:00.000Z",
    "enddate": "2019-06-01T09:28:00.000Z",
    "all": "B0468/19 NOTAMR B0318/19\nQ) EISN/QKKKK/K /K  /K /000/999/5257N01045W999\nA) EISN B) 1905010928 C) 1906010928 EST\nE) CHECKLIST\nYEAR=2013 0022\nYEAR=2014 NIL\nYEAR=2015 1137 1138 1140 1142 1434\nYEAR=2016 NIL\nYEAR=2017 0334 0335\nYEAR=2018 NIL\nYEAR=2019 0057 0078 0106 0107 0108 0109 0110 0115 0227 0228 0251 0267\n          0268 0366 0390 0391 0427 0428 0429 0439 0445 0446 0447 0465\n \nLATEST PUBLICATIONS\nAIP AIRAC AMDT IFR 005/2019 EFFECTIVE DATE 23 MAY 19\nAIP AIRAC SUP IFR 011/2019 EFFECTIVE DATE 25 APR 19\nAIP SUP IFR 001/2019 EFFECTIVE DATE 31 JAN 19\nAIC IFR 007/2019 EFFECTIVE DATE 28 MAR 19\nCREATED: 01 May 2019 09:29:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-05-01T09:29:00.000Z",
    "key": "B0468/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45ec",
    "id": "B0429/19",
    "entity": "CA",
    "status": "AS",
    "Qcode": "CAAS",
    "Area": "CNS",
    "SubArea": "Communications and surveillance facilities",
    "Condition": "Availability",
    "Subject": "Air/ground facility",
    "Modifier": "Unserviceable",
    "message": "SHANWICK OCA VHF RADIO CHECKS:\nNEW CHANNEL 128.360 AS INTRODUCED IN IRISH AIC 001/2019 TEMPORARILY \nOUT OF SERVICE FROM 28TH MARCH PENDING ADDITIONAL ASSURANCE WORK.\nDURING THE OUTAGE FLIGHT CREWS ROUTING ON T9 WILL NOT BE ROUTINELY \nINSTRUCTED TO MONITOR THE FREQUENCY BUT MAY BE INSTRUCTED BY \nSHANWICK RADIO TO CARRY OUT RADIO CHECKS ON THIS CHANNEL TO ALLOW \nFOR EVALUATION OF THE RT CHANNEL.\nUPON COMPLETION OF ASSURANCE WORK CREWS WILL THEN BE INSTRUCTED TO \nMONITOR 128.360 BY SHANWICK RADIO IN ACCORDANCE WITH IRISH AIC \n001/2019. THERE ARE NO OTHER CHANGES TO COMMUNICATION AND MONITORING \nPROCEDURES WHILE OPERATING IN THE SHANWICK AREA.\nREF AIP GEN 3.4\nCREATED: 24 Apr 2019 11:48:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-04-24T11:46:00.000Z",
    "enddate": "2019-05-23T23:59:00.000Z",
    "all": "B0429/19 NOTAMR B0300/19\nQ) EISN/QCAAS/IV/B  /E /000/999/5257N01045W250\nA) EISN B) 1904241146 C) 1905232359\nE) SHANWICK OCA VHF RADIO CHECKS:\nNEW CHANNEL 128.360 AS INTRODUCED IN IRISH AIC 001/2019 TEMPORARILY \nOUT OF SERVICE FROM 28TH MARCH PENDING ADDITIONAL ASSURANCE WORK.\nDURING THE OUTAGE FLIGHT CREWS ROUTING ON T9 WILL NOT BE ROUTINELY \nINSTRUCTED TO MONITOR THE FREQUENCY BUT MAY BE INSTRUCTED BY \nSHANWICK RADIO TO CARRY OUT RADIO CHECKS ON THIS CHANNEL TO ALLOW \nFOR EVALUATION OF THE RT CHANNEL.\nUPON COMPLETION OF ASSURANCE WORK CREWS WILL THEN BE INSTRUCTED TO \nMONITOR 128.360 BY SHANWICK RADIO IN ACCORDANCE WITH IRISH AIC \n001/2019. THERE ARE NO OTHER CHANGES TO COMMUNICATION AND MONITORING \nPROCEDURES WHILE OPERATING IN THE SHANWICK AREA.\nREF AIP GEN 3.4\nCREATED: 24 Apr 2019 11:48:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-04-24T11:48:00.000Z",
    "key": "B0429/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45ed",
    "id": "B0391/19",
    "entity": "AF",
    "status": "TT",
    "Qcode": "AFTT",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Other",
    "Subject": "Flight information region",
    "Modifier": "AIRAC AIP Amendments",
    "message": "TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR005 EFFECTIVE DATE 23 MAY \n2019. INCLUDED IN THIS AMENDMENT IS THE REVISED ENR 5.5 AERIAL \nSPORTING AND RECREATIONAL ACTIVITIES.\nCREATED: 16 Apr 2019 13:08:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-23T00:00:00.000Z",
    "enddate": "2019-06-06T23:59:00.000Z",
    "all": "B0391/19 NOTAMN\nQ) EISN/QAFTT/IV/BO /E /000/999/5257N01045W999\nA) EISN B) 1905230000 C) 1906062359\nE) TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR005 EFFECTIVE DATE 23 MAY \n2019. INCLUDED IN THIS AMENDMENT IS THE REVISED ENR 5.5 AERIAL \nSPORTING AND RECREATIONAL ACTIVITIES.\nCREATED: 16 Apr 2019 13:08:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-04-16T13:08:00.000Z",
    "key": "B0391/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45ee",
    "id": "B0390/19",
    "entity": "AF",
    "status": "TT",
    "Qcode": "AFTT",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Other",
    "Subject": "Flight information region",
    "Modifier": "AIRAC AIP Amendments",
    "message": "TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR005 EFFECTIVE DATE 23 MAY \n2019. INCLUDED IN THIS AMENDMENT IS THE REVISED GEN 3.2 AERONAUTICAL \nCHARTS.\nCREATED: 16 Apr 2019 13:06:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-23T00:00:00.000Z",
    "enddate": "2019-06-06T23:59:00.000Z",
    "all": "B0390/19 NOTAMN\nQ) EISN/QAFTT/IV/BO /E /000/999/5257N01045W999\nA) EISN B) 1905230000 C) 1906062359\nE) TRIGGER NOTAM IRELAND AIRAC AMENDMENT NR005 EFFECTIVE DATE 23 MAY \n2019. INCLUDED IN THIS AMENDMENT IS THE REVISED GEN 3.2 AERONAUTICAL \nCHARTS.\nCREATED: 16 Apr 2019 13:06:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-04-16T13:06:00.000Z",
    "key": "B0390/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45ef",
    "id": "B0251/19",
    "entity": "ND",
    "status": "AS",
    "Qcode": "NDAS",
    "Area": "CNS",
    "SubArea": "Terminal and en-route navigation facilities",
    "Condition": "Availability",
    "Subject": "Distance measuring equipment",
    "Modifier": "Unserviceable",
    "message": "GORMANSTON DME GMN FREQ 112.9MHZ CH76X OUT OF SERVICE DUE MAINTENANCE\nCREATED: 14 Mar 2019 17:27:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-03-14T17:26:00.000Z",
    "enddate": "2019-06-14T23:59:00.000Z",
    "all": "B0251/19 NOTAMR B0954/18\nQ) EISN/QNDAS/IV/BO /E /000/999/5338N00614W025\nA) EISN B) 1903141726 C) 1906142359 EST\nE) \nGORMANSTON DME GMN FREQ 112.9MHZ CH76X OUT OF SERVICE DUE MAINTENANCE\nCREATED: 14 Mar 2019 17:27:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-03-14T17:27:00.000Z",
    "key": "B0251/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45f0",
    "id": "B1434/15",
    "entity": "AP",
    "status": "XX",
    "Qcode": "APXX",
    "Area": "ATM",
    "SubArea": "Airspace organization",
    "Condition": "Other",
    "Subject": "Reporting point",
    "Modifier": "Plain language",
    "message": "THE FOLLOWING WAYPOINTS CORRECTIONS ARE APPLICABLE FOR \nOVERFLIGHT/ENTRY/EXIT FLIGHT PLANNING OF SHANNON UIR, NOTA AND SOTA:\nWAYPOINT DUNLO WITHDRAWN\nWAYPOINTS KUGUR, ARKIL, MORAG, SAMON, TURLU CONDITIONS AS NIGHT \nROUTE ONLY WITHDRAWN \nREF AIP IRELAND ENR 1.10 SECTION 6.3.1 TABLE 1\nCREATED: 01 May 2017 11:24:00 \nSOURCE: EUECYIYN",
    "startdate": "2015-12-15T12:38:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B1434/15 NOTAMR B1079/15\nQ) EISN/QAPXX/IV/NBO/E /000/999/5305N01045W350\nA) EISN B) 1512151238 C) PERM\nE) THE FOLLOWING WAYPOINTS CORRECTIONS ARE APPLICABLE FOR \nOVERFLIGHT/ENTRY/EXIT FLIGHT PLANNING OF SHANNON UIR, NOTA AND SOTA:\nWAYPOINT DUNLO WITHDRAWN\nWAYPOINTS KUGUR, ARKIL, MORAG, SAMON, TURLU CONDITIONS AS NIGHT \nROUTE ONLY WITHDRAWN \nREF AIP IRELAND ENR 1.10 SECTION 6.3.1 TABLE 1\nCREATED: 01 May 2017 11:24:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2017-05-01T11:24:00.000Z",
    "key": "B1434/15-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45f1",
    "id": "B1140/15",
    "entity": "PH",
    "status": "CH",
    "Qcode": "PHCH",
    "Area": "ATM",
    "SubArea": "Air traffic procedures",
    "Condition": "Changes",
    "Subject": "Holding procedure",
    "Modifier": "Changed",
    "message": "HOLDING PROCEDURE CHANGED. RATE OF DESCENT IN HOLDING PATTERNS \nAMENDED TO: A STANDARD RATE OF DESCENT OF 1000FT PER \nMIN IN HOLDING PATTERNS WILL BE USED UNLESS OTHERWISE INSTRUCTED BY \nATC. PILOTS MUST ADVISE ATC IF UNABLE TO COMPLY WITH THE STANDARD \nRATE OF DESCENT.\nREF AIP ENR 1.5 SECTION 1.3\nCREATED: 01 May 2017 11:24:00 \nSOURCE: EUECYIYN",
    "startdate": "2015-10-02T17:50:00.000Z",
    "enddate": "2100-02-01T00:00:00.000Z",
    "all": "B1140/15 NOTAMN\nQ) EISN/QPHCH/IV/BO /E /000/999/5257N01045W025\nA) EISN B) 1510021750 C) PERM\nE) HOLDING PROCEDURE CHANGED. RATE OF DESCENT IN HOLDING PATTERNS \nAMENDED TO: A STANDARD RATE OF DESCENT OF 1000FT PER \nMIN IN HOLDING PATTERNS WILL BE USED UNLESS OTHERWISE INSTRUCTED BY \nATC. PILOTS MUST ADVISE ATC IF UNABLE TO COMPLY WITH THE STANDARD \nRATE OF DESCENT.\nREF AIP ENR 1.5 SECTION 1.3\nCREATED: 01 May 2017 11:24:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2017-05-01T11:24:00.000Z",
    "key": "B1140/15-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45f2",
    "id": "D0071/19",
    "entity": "RD",
    "status": "CA",
    "Qcode": "RDCA",
    "Area": "Navigation Warnings",
    "SubArea": "Airspace restrictions",
    "Condition": "Changes",
    "Subject": "Danger area",
    "Modifier": "Activated",
    "message": "DANGER AREA EID5 GLEN OF IMAAL, CO. WICKLOW ACTIVATED",
    "startdate": "2019-05-27T09:00:00.000Z",
    "enddate": "2019-05-27T15:00:00.000Z",
    "all": "D0071/19 NOTAMN\nQ) EISN/QRDCA/IV/BO /W /000/060/5259N00628W005\nA) EISN B) 1905270900 C) 1905271500\nE) DANGER AREA EID5 GLEN OF IMAAL, CO. WICKLOW ACTIVATED\nF) SFC G) 6000FT AMSL\nCREATED: 17 May 2019 09:36:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "",
    "key": "D0071/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45f3",
    "id": "D0067/19",
    "entity": "RD",
    "status": "CA",
    "Qcode": "RDCA",
    "Area": "Navigation Warnings",
    "SubArea": "Airspace restrictions",
    "Condition": "Changes",
    "Subject": "Danger area",
    "Modifier": "Activated",
    "message": "DANGER AREA EID13 SEA/COASTAL AREA SSW OF CORK ACTIVATED",
    "startdate": "2019-05-28T08:00:00.000Z",
    "enddate": "2019-05-30T17:00:00.000Z",
    "all": "D0067/19 NOTAMN\nQ) EISN/QRDCA/IV/BO /W /000/340/5126N00846W010\nA) EISN B) 1905280800 C) 1905301700\nD) DAILY 0800-1700\nE) DANGER AREA EID13 SEA/COASTAL AREA SSW OF CORK ACTIVATED\nF) GND G) 34000FT AMSL\nCREATED: 02 May 2019 11:08:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "",
    "key": "D0067/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45f4",
    "id": "D0066/19",
    "entity": "KK",
    "status": "KK",
    "Qcode": "KKKK",
    "Area": "Other Information",
    "SubArea": "NOTAM Checklist",
    "Condition": "",
    "Subject": "NOTAM Checklist",
    "Modifier": "",
    "message": "CHECKLIST\nYEAR=2019 0053 0054 0055 0056 0057 0058 0059 0060 0061 0062 0063 0064\n          0065\n \nLATEST PUBLICATIONS\nAIP AIRAC AMDT IFR 005/2019 EFFECTIVE DATE 23 MAY 19\nAIP AIRAC SUP IFR 011/2019 EFFECTIVE DATE 25 APR 19\nAIP SUP IFR 001/2019 EFFECTIVE DATE 31 JAN 19\nAIC IFR 007/2019 EFFECTIVE DATE 28 MAR 19\nCREATED: 01 May 2019 09:31:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-01T09:30:00.000Z",
    "enddate": "2019-06-01T09:30:00.000Z",
    "all": "D0066/19 NOTAMR D0051/19\nQ) EISN/QKKKK/K /K  /K /000/999/5257N01045W999\nA) EISN B) 1905010930 C) 1906010930 EST\nE) CHECKLIST\nYEAR=2019 0053 0054 0055 0056 0057 0058 0059 0060 0061 0062 0063 0064\n          0065\n \nLATEST PUBLICATIONS\nAIP AIRAC AMDT IFR 005/2019 EFFECTIVE DATE 23 MAY 19\nAIP AIRAC SUP IFR 011/2019 EFFECTIVE DATE 25 APR 19\nAIP SUP IFR 001/2019 EFFECTIVE DATE 31 JAN 19\nAIC IFR 007/2019 EFFECTIVE DATE 28 MAR 19\nCREATED: 01 May 2019 09:31:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-05-01T09:31:00.000Z",
    "key": "D0066/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45f5",
    "id": "D0065/19",
    "entity": "RD",
    "status": "CA",
    "Qcode": "RDCA",
    "Area": "Navigation Warnings",
    "SubArea": "Airspace restrictions",
    "Condition": "Changes",
    "Subject": "Danger area",
    "Modifier": "Activated",
    "message": "DANGER AREA EID6 KILWORTH, CO. CORK ACTIVATED",
    "startdate": "2019-05-01T06:00:00.000Z",
    "enddate": "2019-06-01T06:00:00.000Z",
    "all": "D0065/19 NOTAMN\nQ) EISN/QRDCA/IV/BO /W /000/080/5214N00813W002\nA) EISN B) 1905010600 C) 1906010600\nE) DANGER AREA EID6 KILWORTH, CO. CORK ACTIVATED\nF) GND G) 8000FT AMSL\nCREATED: 23 Apr 2019 12:48:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "",
    "key": "D0065/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45f6",
    "id": "D0064/19",
    "entity": "RD",
    "status": "CA",
    "Qcode": "RDCA",
    "Area": "Navigation Warnings",
    "SubArea": "Airspace restrictions",
    "Condition": "Changes",
    "Subject": "Danger area",
    "Modifier": "Activated",
    "message": "DANGER AREA EID5 GLEN OF IMAAL, CO. WICKLOW ACTIVATED",
    "startdate": "2019-05-30T09:00:00.000Z",
    "enddate": "2019-05-30T23:59:00.000Z",
    "all": "D0064/19 NOTAMN\nQ) EISN/QRDCA/IV/BO /W /000/060/5259N00628W005\nA) EISN B) 1905300900 C) 1905302359\nE) DANGER AREA EID5 GLEN OF IMAAL, CO. WICKLOW ACTIVATED\nF) SFC G) 6000FT AMSL\nCREATED: 15 Apr 2019 09:08:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "",
    "key": "D0064/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45f7",
    "id": "D0063/19",
    "entity": "RD",
    "status": "CA",
    "Qcode": "RDCA",
    "Area": "Navigation Warnings",
    "SubArea": "Airspace restrictions",
    "Condition": "Changes",
    "Subject": "Danger area",
    "Modifier": "Activated",
    "message": "DANGER AREA EID5 GLEN OF IMAAL, CO. WICKLOW ACTIVATED",
    "startdate": "2019-05-29T09:00:00.000Z",
    "enddate": "2019-05-29T22:59:00.000Z",
    "all": "D0063/19 NOTAMN\nQ) EISN/QRDCA/IV/BO /W /000/060/5259N00628W005\nA) EISN B) 1905290900 C) 1905292259\nE) DANGER AREA EID5 GLEN OF IMAAL, CO. WICKLOW ACTIVATED\nF) SFC G) 6000FT AMSL\nCREATED: 15 Apr 2019 09:02:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "",
    "key": "D0063/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45f8",
    "id": "D0062/19",
    "entity": "RD",
    "status": "CA",
    "Qcode": "RDCA",
    "Area": "Navigation Warnings",
    "SubArea": "Airspace restrictions",
    "Condition": "Changes",
    "Subject": "Danger area",
    "Modifier": "Activated",
    "message": "DANGER AREA EID5 GLEN OF IMAAL, CO. WICKLOW ACTIVATED",
    "startdate": "2019-05-28T09:00:00.000Z",
    "enddate": "2019-05-28T22:59:00.000Z",
    "all": "D0062/19 NOTAMN\nQ) EISN/QRDCA/IV/BO /W /000/060/5259N00628W005\nA) EISN B) 1905280900 C) 1905282259\nE) DANGER AREA EID5 GLEN OF IMAAL, CO. WICKLOW ACTIVATED\nF) SFC G) 6000FT AMSL\nCREATED: 15 Apr 2019 09:02:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "",
    "key": "D0062/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45f9",
    "id": "D0061/19",
    "entity": "RD",
    "status": "CA",
    "Qcode": "RDCA",
    "Area": "Navigation Warnings",
    "SubArea": "Airspace restrictions",
    "Condition": "Changes",
    "Subject": "Danger area",
    "Modifier": "Activated",
    "message": "DANGER AREA EID5 GLEN OF IMAAL, CO. WICKLOW ACTIVATED",
    "startdate": "2019-05-23T09:00:00.000Z",
    "enddate": "2019-05-23T17:00:00.000Z",
    "all": "D0061/19 NOTAMN\nQ) EISN/QRDCA/IV/BO /W /000/060/5259N00628W005\nA) EISN B) 1905230900 C) 1905231700\nE) DANGER AREA EID5 GLEN OF IMAAL, CO. WICKLOW ACTIVATED\nF) SFC G) 6000FT AMSL\nCREATED: 15 Apr 2019 09:01:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "",
    "key": "D0061/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45fa",
    "id": "D0060/19",
    "entity": "RD",
    "status": "CA",
    "Qcode": "RDCA",
    "Area": "Navigation Warnings",
    "SubArea": "Airspace restrictions",
    "Condition": "Changes",
    "Subject": "Danger area",
    "Modifier": "Activated",
    "message": "DANGER AREA EID5 GLEN OF IMAAL, CO. WICKLOW ACTIVATED",
    "startdate": "2019-05-22T09:00:00.000Z",
    "enddate": "2019-05-22T22:59:00.000Z",
    "all": "D0060/19 NOTAMN\nQ) EISN/QRDCA/IV/BO /W /000/100/5259N00628W005\nA) EISN B) 1905220900 C) 1905222259\nE) DANGER AREA EID5 GLEN OF IMAAL, CO. WICKLOW ACTIVATED\nF) SFC G) 10000FT AMSL\nCREATED: 15 Apr 2019 09:00:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "",
    "key": "D0060/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45fb",
    "id": "H0046/19",
    "entity": "WP",
    "status": "LW",
    "Qcode": "WPLW",
    "Area": "Navigation Warnings",
    "SubArea": "Warnings",
    "Condition": "Limitations",
    "Subject": "Parachute jumping exercise, paragliding or hang gliding",
    "Modifier": "Will take place",
    "message": "ACCELERATED FREE FALL (AF",
    "startdate": "2019-05-22T10:00:00.000Z",
    "enddate": "2019-05-22T15:30:00.000Z",
    "all": "H0046/19 NOTAMN\nQ) EISN/QWPLW/IV/M  /W /000/100/5309N00650W003\nA) EISN B) 1905221000 C) 1905221530\nE) ACCELERATED FREE FALL (AFF) AND MILITARY FREE FALL (MFF) \nPARACHUTE OPERATIONS WILL TAKE PLACE WITHIN A 3NM RADIUS FROM THE \nCENTRE OF EIP10 CURRAGH CAMP, COUNTY KILDARE, POSITION 530915.40N \n0065028.20W\nNO MORE THAN 2 DROPS\nF) SFC G) 10000FT AMSL\nCREATED: 18 May 2019 12:04:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "",
    "key": "H0046/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45fc",
    "id": "H0044/19",
    "entity": "WP",
    "status": "LW",
    "Qcode": "WPLW",
    "Area": "Navigation Warnings",
    "SubArea": "Warnings",
    "Condition": "Limitations",
    "Subject": "Parachute jumping exercise, paragliding or hang gliding",
    "Modifier": "Will take place",
    "message": "HIGH ALTITUDE LOW OPENING (HALO) PARACHUTE OPS WILL TAKE PLACE \nWITHIN 5NM RADIUS OF DANGER AREA EID6 KILWORTH MILITARY RANGE, \nKILWORTH CO CORK POSITION 521254N 0081603W",
    "startdate": "2019-05-24T08:00:00.000Z",
    "enddate": "2019-05-24T16:00:00.000Z",
    "all": "H0044/19 NOTAMN\nQ) EISN/QWPLW/IV/M  /W /000/080/5212N00816W005\nA) EISN B) 1905240800 C) 1905241600\nE) HIGH ALTITUDE LOW OPENING (HALO) PARACHUTE OPS WILL TAKE PLACE \nWITHIN 5NM RADIUS OF DANGER AREA EID6 KILWORTH MILITARY RANGE, \nKILWORTH CO CORK POSITION 521254N 0081603W\nF) SFC G) 8000FT AMSL\nCREATED: 18 May 2019 11:10:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "",
    "key": "H0044/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45fd",
    "id": "H0040/19",
    "entity": "KK",
    "status": "KK",
    "Qcode": "KKKK",
    "Area": "Other Information",
    "SubArea": "NOTAM Checklist",
    "Condition": "",
    "Subject": "NOTAM Checklist",
    "Modifier": "",
    "message": "CHECKLIST\nYEAR=2019 0028 0030 0035 0037 0038\n \nLATEST PUBLICATIONS\nAIP AIRAC AMDT IFR 005/2019 EFFECTIVE DATE 23 MAY 19\nAIP AIRAC SUP IFR 011/2019 EFFECTIVE DATE 25 APR 19\nAIP SUP IFR 001/2019 EFFECTIVE DATE 31 JAN 19\nAIC IFR 007/2019 EFFECTIVE DATE 28 MAR 19\nCREATED: 01 May 2019 09:31:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-01T09:30:00.000Z",
    "enddate": "2019-06-01T09:30:00.000Z",
    "all": "H0040/19 NOTAMR H0027/19\nQ) EISN/QKKKK/K /K  /K /000/999/5257N01045W999\nA) EISN B) 1905010930 C) 1906010930 EST\nE) CHECKLIST\nYEAR=2019 0028 0030 0035 0037 0038\n \nLATEST PUBLICATIONS\nAIP AIRAC AMDT IFR 005/2019 EFFECTIVE DATE 23 MAY 19\nAIP AIRAC SUP IFR 011/2019 EFFECTIVE DATE 25 APR 19\nAIP SUP IFR 001/2019 EFFECTIVE DATE 31 JAN 19\nAIC IFR 007/2019 EFFECTIVE DATE 28 MAR 19\nCREATED: 01 May 2019 09:31:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-05-01T09:31:00.000Z",
    "key": "H0040/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45fe",
    "id": "J0010/19",
    "entity": "RM",
    "status": "CA",
    "Qcode": "RMCA",
    "Area": "Navigation Warnings",
    "SubArea": "Airspace restrictions",
    "Condition": "Changes",
    "Subject": "Military operating area",
    "Modifier": "Activated",
    "message": "NAVIGATION WARNING\nONGOING MILITARY HELICOPTER OPERATIONS IN THE VICINITY OF CUSTUME \nBARRACKS, ATHLONE, CO. ROSCOMMON. OPERATIONS WILL NORMALLY OCCUR \nDURING DAYLIGHT AND INCLUDE SHORT NOTICE EMERGENCY DEPARTURES AND \nMANOEUVRES THAT MAY NOT BE COMPLIANT WITH THE RULES OF THE AIR.\nCAUTION SHOULD BE EXERCISED BY AIRCREWS WHEN OPERATING WITHIN 1NM OF \nN5325.5 W00756.85 (CUSTUME BARRACKS) BELOW 1500FT",
    "startdate": "2019-05-08T16:05:00.000Z",
    "enddate": "2019-08-08T18:00:00.000Z",
    "all": "J0010/19 NOTAMR J0003/19\nQ) EISN/QRMCA/IV/BO /W /000/015/5325N00756W001\nA) EISN B) 1905081605 C) 1908081800 EST\nE) NAVIGATION WARNING\nONGOING MILITARY HELICOPTER OPERATIONS IN THE VICINITY OF CUSTUME \nBARRACKS, ATHLONE, CO. ROSCOMMON. OPERATIONS WILL NORMALLY OCCUR \nDURING DAYLIGHT AND INCLUDE SHORT NOTICE EMERGENCY DEPARTURES AND \nMANOEUVRES THAT MAY NOT BE COMPLIANT WITH THE RULES OF THE AIR.\nCAUTION SHOULD BE EXERCISED BY AIRCREWS WHEN OPERATING WITHIN 1NM OF \nN5325.5 W00756.85 (CUSTUME BARRACKS) BELOW 1500FT\nF) SFC G) 1500FT AMSL\nCREATED: 08 May 2019 16:06:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "",
    "key": "J0010/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce45ff",
    "id": "J0009/19",
    "entity": "KK",
    "status": "KK",
    "Qcode": "KKKK",
    "Area": "Other Information",
    "SubArea": "NOTAM Checklist",
    "Condition": "",
    "Subject": "NOTAM Checklist",
    "Modifier": "",
    "message": "CHECKLIST\nYEAR=2019 0003\n \nLATEST PUBLICATIONS\nAIP AIRAC AMDT IFR 005/2019 EFFECTIVE DATE 23 MAY 19\nAIP AIRAC SUP IFR 011/2019 EFFECTIVE DATE 25 APR 19\nAIP SUP IFR 001/2019 EFFECTIVE DATE 31 JAN 19\nAIC IFR 007/2019 EFFECTIVE DATE 28 MAR 19\nCREATED: 01 May 2019 09:32:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-01T09:31:00.000Z",
    "enddate": "2019-06-01T09:31:00.000Z",
    "all": "J0009/19 NOTAMR J0006/19\nQ) EISN/QKKKK/K /K  /K /000/999/5257N01045W999\nA) EISN B) 1905010931 C) 1906010931 EST\nE) CHECKLIST\nYEAR=2019 0003\n \nLATEST PUBLICATIONS\nAIP AIRAC AMDT IFR 005/2019 EFFECTIVE DATE 23 MAY 19\nAIP AIRAC SUP IFR 011/2019 EFFECTIVE DATE 25 APR 19\nAIP SUP IFR 001/2019 EFFECTIVE DATE 31 JAN 19\nAIC IFR 007/2019 EFFECTIVE DATE 28 MAR 19\nCREATED: 01 May 2019 09:32:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-05-01T09:32:00.000Z",
    "key": "J0009/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce4600",
    "id": "N0060/19",
    "entity": "OL",
    "status": "AS",
    "Qcode": "OLAS",
    "Area": "Other Information",
    "SubArea": "Other Information",
    "Condition": "Availability",
    "Subject": "Obstacle lights on",
    "Modifier": "Unserviceable",
    "message": "OBST LIGHTS ON WIND TURBINE AT BEAL HILL WIND FARM, BALLYBUNION, \nCO \nKERRY OUT OF SERVICE.  POSITION 523339.082N 0093738.442W.  MAX \nELEVATION 180M AMSL.\nCREATED: 13 May 2019 10:35:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-13T10:33:00.000Z",
    "enddate": "2019-08-13T23:59:00.000Z",
    "all": "N0060/19 NOTAMR N0015/19\nQ) EISN/QOLAS/IV/M  /E /000/006/5233N00937W001\nA) EISN B) 1905131033 C) 1908132359 EST\nE) OBST LIGHTS ON WIND TURBINE AT BEAL HILL WIND FARM, BALLYBUNION, \nCO \nKERRY OUT OF SERVICE.  POSITION 523339.082N 0093738.442W.  MAX \nELEVATION 180M AMSL.\nCREATED: 13 May 2019 10:35:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-05-13T10:35:00.000Z",
    "key": "N0060/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce4601",
    "id": "N0050/19",
    "entity": "KK",
    "status": "KK",
    "Qcode": "KKKK",
    "Area": "Other Information",
    "SubArea": "NOTAM Checklist",
    "Condition": "",
    "Subject": "NOTAM Checklist",
    "Modifier": "",
    "message": "CHECKLIST\nYEAR=2019 0005 0010 0014 0015 0018 0021 0027 0030 0032 0034 0048 0049\n \nLATEST PUBLICATIONS\nAIP AIRAC AMDT IFR 005/2019 EFFECTIVE DATE 23 MAY 19\nAIP AIRAC SUP IFR 011/2019 EFFECTIVE DATE 25 APR 19\nAIP SUP IFR 001/2019 EFFECTIVE DATE 31 JAN 19\nAIC IFR 007/2019 EFFECTIVE DATE 28 MAR 19\nCREATED: 01 May 2019 09:32:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-05-01T09:31:00.000Z",
    "enddate": "2019-06-01T09:31:00.000Z",
    "all": "N0050/19 NOTAMR N0033/19\nQ) EISN/QKKKK/K /K  /K /000/999/5257N01045W999\nA) EISN B) 1905010931 C) 1906010931 EST\nE) CHECKLIST\nYEAR=2019 0005 0010 0014 0015 0018 0021 0027 0030 0032 0034 0048 0049\n \nLATEST PUBLICATIONS\nAIP AIRAC AMDT IFR 005/2019 EFFECTIVE DATE 23 MAY 19\nAIP AIRAC SUP IFR 011/2019 EFFECTIVE DATE 25 APR 19\nAIP SUP IFR 001/2019 EFFECTIVE DATE 31 JAN 19\nAIC IFR 007/2019 EFFECTIVE DATE 28 MAR 19\nCREATED: 01 May 2019 09:32:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-05-01T09:32:00.000Z",
    "key": "N0050/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce4602",
    "id": "N0032/19",
    "entity": "OB",
    "status": "CE",
    "Qcode": "OBCE",
    "Area": "Other Information",
    "SubArea": "Other Information",
    "Condition": "Changes",
    "Subject": "Obstacle",
    "Modifier": "Erected",
    "message": "BALLYKINAVA WIND FARM, CO. MAYO (EISN-2019-0007). WIND FARM POSITION \nCENTRED ON THE FOLLOWING LATERAL LIMITS: 534225.40N 0085529.46W \nWITHIN A RADIUS OF 1 NM, HEIGHT 512FT AGL ELEVATION 775FT AMSL. \nWINDFARM LIT. REF HAND AMENDMENTS TO VFR 1/500,000 EDITION 8 AND \n1/250,000 WEST EDITION 5 CHART\nCREATED: 26 Mar 2019 16:50:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-03-26T16:49:00.000Z",
    "enddate": "2019-05-22T23:59:00.000Z",
    "all": "N0032/19 NOTAMN\nQ) EISN/QOBCE/IV/M  /E /000/008/5342N00855W001\nA) EISN B) 1903261649 C) 1905222359\nE) \nBALLYKINAVA WIND FARM, CO. MAYO (EISN-2019-0007). WIND FARM POSITION \nCENTRED ON THE FOLLOWING LATERAL LIMITS: 534225.40N 0085529.46W \nWITHIN A RADIUS OF 1 NM, HEIGHT 512FT AGL ELEVATION 775FT AMSL. \nWINDFARM LIT. REF HAND AMENDMENTS TO VFR 1/500,000 EDITION 8 AND \n1/250,000 WEST EDITION 5 CHART\nCREATED: 26 Mar 2019 16:50:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-03-26T16:50:00.000Z",
    "key": "N0032/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce4603",
    "id": "N0030/19",
    "entity": "OB",
    "status": "CE",
    "Qcode": "OBCE",
    "Area": "Other Information",
    "SubArea": "Other Information",
    "Condition": "Changes",
    "Subject": "Obstacle",
    "Modifier": "Erected",
    "message": "MEENWAUN WIND FARM, BIRR, CO OFFALY (EISN-2019-0006). WIND FARM \nPOSITION CENTRED ON THE FOLLOWING LATERAL LIMITS: 531003.86N \n0075526.39W WITHIN A RADIUS OF 1 NM, HEIGHT 555FT AGL ELEVATION \n739FT AMSL. WINDFARM LIT. REF HAND AMENDMENTS TO VFR 1/500,000 \nEDITION 8 AND 1/250,000 EAST EDITION 5 CHARTS.\nCREATED: 26 Mar 2019 16:44:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-03-26T16:42:00.000Z",
    "enddate": "2019-05-22T23:59:00.000Z",
    "all": "N0030/19 NOTAMN\nQ) EISN/QOBCE/IV/M  /E /000/008/5310N00755W001\nA) EISN B) 1903261642 C) 1905222359\nE) MEENWAUN WIND FARM, BIRR, CO OFFALY (EISN-2019-0006). WIND FARM \nPOSITION CENTRED ON THE FOLLOWING LATERAL LIMITS: 531003.86N \n0075526.39W WITHIN A RADIUS OF 1 NM, HEIGHT 555FT AGL ELEVATION \n739FT AMSL. WINDFARM LIT. REF HAND AMENDMENTS TO VFR 1/500,000 \nEDITION 8 AND 1/250,000 EAST EDITION 5 CHARTS.\nCREATED: 26 Mar 2019 16:44:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-03-26T16:44:00.000Z",
    "key": "N0030/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  },
  {
    "_id": "5ce4d48cab233e8499ce4604",
    "id": "N0027/19",
    "entity": "OB",
    "status": "CE",
    "Qcode": "OBCE",
    "Area": "Other Information",
    "SubArea": "Other Information",
    "Condition": "Changes",
    "Subject": "Obstacle",
    "Modifier": "Erected",
    "message": "OBSTACLES ERECTED 2 CRANES, OPERATING NORTH OF GALWAY HOSPITAL \nHELIPAD, CO GALWAY, WITH A CENTROID POSITION 531719.92N 0090429.63W  \nDISTANCE OF 86M BETWEEN THE 2 CRANES, ELEVATION 73.8M/242FT AMSL, \nLIGHTED.\nCREATED: 21 Mar 2019 15:22:00 \nSOURCE: EUECYIYN",
    "startdate": "2019-03-25T07:00:00.000Z",
    "enddate": "2019-06-25T12:00:00.000Z",
    "all": "N0027/19 NOTAMN\nQ) EISN/QOBCE/IV/M  /E /000/003/5317N00904W001\nA) EISN B) 1903250700 C) 1906251200 EST\nE) OBSTACLES ERECTED 2 CRANES, OPERATING NORTH OF GALWAY HOSPITAL \nHELIPAD, CO GALWAY, WITH A CENTROID POSITION 531719.92N 0090429.63W  \nDISTANCE OF 86M BETWEEN THE 2 CRANES, ELEVATION 73.8M/242FT AMSL, \nLIGHTED.\nCREATED: 21 Mar 2019 15:22:00 \nSOURCE: EUECYIYN",
    "location": "EISN",
    "isICAO": true,
    "Created": "2019-03-21T15:22:00.000Z",
    "key": "N0027/19-EISN",
    "type": "airspace",
    "StateCode": "IRL",
    "StateName": "Ireland"
  }
]