import axios from 'axios';
import { message } from 'antd';

export default ({method, endpoint, headers, data, response_type, success, props, error}) => {
	const base_path = window.location.origin;
	// const base_path = 'http://localhost:8000';
	// const base_path = 'http://192.168.1.11:8000';
	let req = null;
	const auth = localStorage.getItem("auth");
	
	if(auth)
		headers["Authorization"] = auth;

	if(method === 'get') {
		req = axios({
			method: 'get',
			url: base_path+endpoint,
			headers: headers,
			params: data
		});
	}
	else {
		let formData = new FormData();
		for (const key in data) {
			formData.append(key, data[key]);
		}
		req = axios({
			method: 'post',
			url: base_path+endpoint,
			headers: headers,
			data: formData,
			responseType: response_type,
			// transformRequest: [
			// 	function(data, headers) {
			// 		const serializedData = []
			
			// 		for (const k in data) {
			// 			if (data[k]) {
			// 				serializedData.push(`${k}=${encodeURIComponent(data[k])}`)
			// 			}
			// 		}
			
			// 		return serializedData.join('&')
			// 	}
			// ],
		});
	}
	
	if(req !== null) {
		req.then(response => {
			
			success(props, response);
		}).catch(err => {
			console.log(err);
			error(props, err)
		});
	}
}
