const toDegreesMinutesAndSeconds = (coordinate) => {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

    return degrees + "" + minutes + "" + seconds;
}

export const convertDMS = (lat, lng) => {
    const latitude = toDegreesMinutesAndSeconds(lat);
    const latitudeCardinal = Math.sign(lat) >= 0 ? "N" : "S";

    const longitude = toDegreesMinutesAndSeconds(lng);
    const longitudeCardinal = Math.sign(lng) >= 0 ? "E" : "W";

    return latitude + "" + latitudeCardinal + " " + longitude + "" + longitudeCardinal;
}

export const degreesToRadians = (degrees) => {
  return degrees * Math.PI / 180;
}

export const distanceInKmBetweenEarthCoordinates = (lat1, lon1, lat2, lon2) => {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(lat2-lat1);
  const dLon = degreesToRadians(lon2-lon1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  // return distance in km
  return (earthRadiusKm * c);
}

// Converts from radians to degrees.
const radiansToDegrees = (radians) => {
return radians * 180 / Math.PI;
}


export const calculateBearing = (startLat, startLng, destLat, destLng) => {
	startLat = degreesToRadians(startLat);
	startLng = degreesToRadians(startLng);
	destLat = degreesToRadians(destLat);
	destLng = degreesToRadians(destLng);



	const y = Math.sin(destLng - startLng) * Math.cos(destLat);
	const x = Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
	let brng = Math.atan2(y, x);
  brng = radiansToDegrees(brng);
  brng = ((brng + 360) % 360) - 180 ;
  brng = brng.toFixed(0);

  if (Math.sign(brng) == -1){
    brng = Math.abs(Math.abs(brng) - 360);
  }
	return brng.toString().toString()+'\xB0';
}

export const formatDate = dateStr => {
  var months = [
    "Jan", "Feb", "Mar",
    "Apr", "May", "Jun", "Jul",
    "Aug", "Sep", "Oct",
    "Nov", "Dec"
  ];

  const dateObj = new Date(dateStr);
  const date = dateObj.getDate(); //0 to 31
  const monthIndex = dateObj.getMonth(); //0 to 11
  const year = dateObj.getFullYear(); //2019
  const hour = dateObj.getUTCHours(); //utc hours
  const minutes = dateObj.getUTCMinutes(); //utc minutes

  return `${date} ${months[monthIndex]} ${year} ${hour}:${minutes} GMT`;
}

export const latFromDms = (locationStr) => {
  //Parse latitude location
  var degreesLat = locationStr.substr(0, 2);
  var minutesLat = locationStr.substr(2, 2);
  var decimalLat = 0.0;
  
  //convert DMS to DD
  decimalLat = parseInt(degreesLat) + parseFloat(minutesLat) / 60;    
  //var fltLat = decimalLat;

  return decimalLat;
}


export const lngFromDms = (locationStr) => {
  
  //Parse longitude location
  var degreesLng = locationStr.substr(7, 1);
  var minutesLng = locationStr.substr(8, 2);
  var decimalLng = 0.0
  //convert DMS to DD
  decimalLng = parseInt(degreesLng) + parseFloat(minutesLng) / 60;    
  
  return -Math.abs(decimalLng);
}