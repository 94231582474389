import React from 'react';
import { Tabs, Row, Col, Icon, Button, Modal, message } from 'antd';
import './settings.scss';
import rest from '../common/network';
import EditCompany from '../form/editCompany.jsx';

const deletePilot = (props=null, response=null, pilot_id=null) => {
    console.log(response);
    if(pilot_id === null) {
        if(response.data.success === true) {
            message.success(response.data.message);
            props.updatePilotList(response.data.pilot_data);
        }
        else
            message.error(response.data.message)
    }
    else {
        const headers = {};
        const data = {
            pilot_id: pilot_id
        }
        Modal.confirm({
            title: 'Are you sure you want to delete this pilot? Once deleted cannot be undone and will removed from associated flight forms.',
            onOk() {
                rest({
                    method: 'post',
                    endpoint: `/flight/pilot/delete`,
                    headers: headers,
                    data: data,
                    response_type: "",
                    success: deletePilot,
                    props: props,
                    error: props.handleRequestError
                });
            },
            onCancel() {}
        });
    }
}

const getPilotList = (props) => {
    if(props.flight_form.pilots.options.length === 0)
        return(
            <div className="no-flights">You have not added any Pilots.</div>
        );

    return(
        props.flight_form.pilots.options.map((item, index) => (
            <Col className="form" xs={{span: 26}} key={item.key} >
                <Row className="header" >
                    <Col 
                        className="title"
                        xs={{span: 15}} 
                    >
                        #{index+1} {item.pilot_name}
                    </Col>
                    <Col 
                        className="edit"
                        xs={{span: 8}} 
                        style={{float: "right", textAlign: "right"}} 
                    >   
                        <span onClick={() => props.showEditModal('edit_pilots', {
                                pilot_id: item.key,
                                pilot_name: item.pilot_name,
                                phone1: item.phone1,
                                phone2: item.phone2,
                                pcc: item.pcc
                            })} >
                            <Icon type="edit" />&nbsp;Edit
                        </span>
                        <span onClick={() => deletePilot(
                                props,
                                null,
                                item.key
                            )} >
                            <Icon type="delete" />&nbsp;Delete
                        </span>
                    </Col>
                </Row>
            </Col>
        ))
    );
}

const deleteDrone = (props=null, response=null, drone_key=null) => {
    if(drone_key === null) {
        console.log(response);
        if(response.data.success === true) {
            message.success(response.data.message);
            props.updateDroneList(response.data.drone_data);
        }
        else
            message.error(response.data.message);
    }
    else {
        const headers = {};
        const data = {
            drone_key: drone_key
        }
        Modal.confirm({
            title: 'Are you sure you want to delete this drone? Once deleted cannot be undone and will removed from associated flight forms.',
            onOk() {
                rest({
                    method: 'post',
                    endpoint: `/flight/drone/delete`,
                    headers: headers,
                    data: data,
                    response_type: "",
                    success: deleteDrone,
                    props: props,
                    error: props.handleRequestError
                });
            },
            onCancel() {}
        });
    }
}

const getDroneList = (props) => {
    if(props.flight_form.drones.options.length === 0)
        return(
            <div className="no-flights">You have not added any drones.</div>
        );

    return(
        props.flight_form.drones.options.map((item, index) => (
            <Col className="form" xs={{span: 26}} key={item.key} >
                <Row className="header" >
                    <Col className="title" 
                        xs={{span: 15}} 
                    >
                        #{index+1} {item.drone_name}
                    </Col>
                    <Col 
                        className="edit"
                        xs={{span: 8}} 
                        style={{float: "right", textAlign: "right"}} 
                    >   
                        <span onClick={() => props.showEditModal('edit_drones', {
                                drone_key: item.key,
                                drone_id: item.drone_id,
                                drone_name: item.drone_name
                            })}>
                            <Icon type="edit" />&nbsp;Edit
                        </span>
                        <span onClick={() => deleteDrone(
                                props,
                                null,
                                item.key
                            )} >
                            <Icon type="delete" />&nbsp;Delete
                        </span>
                    </Col>
                </Row>
            </Col>
        ))
    );
}

export default(props) => {
    return (
        <Row id="settings">
            <Col xs={{span: 24, offset: 1}} md={{span: 16, offset: 5}} style={{padding: "0 50px"}} >
                {/* <div className="page-title" >
                    SETTINGS
                </div>
                <hr style={{borderTop: "1px solid #8080806e", borderLeft: "none"}} /> */}
                <Tabs style={{marginTop: "30px"}}>
                    <Tabs.TabPane tab="Pilots" key="1">
                        <Col xs={{span: 26}} style={{textAlign: "right"}}>
                            <Button 
                                type="primary" 
                                onClick={() => props.showModal("pilots")} >
                                <Icon type="plus" />&nbsp;Add Pilot
                            </Button>
                        </Col>
                        {getPilotList(props)}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Drones" key="2">
                        <Col xs={{span: 26}} style={{textAlign: "right"}}>
                            <Button 
                                type="primary" 
                                onClick={() => props.showModal("drones")} >
                                <Icon type="plus" />&nbsp;Add Drone
                            </Button>
                        </Col>
                        {getDroneList(props)}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Company" key="3">
                        <Col xs={{span: 26}} style={{textAlign: "left"}}>
                            <EditCompany 
                                toggleAsyncButton={props.toggleAsyncButton} 
                                confirmLoading={props.confirmLoading} 
                                data={props.company} 
                                handleRequestError={props.handleRequestError}
                                updateSOPData={props.updateSOPData} />
                        </Col>
                    </Tabs.TabPane>
                </Tabs>
            </Col>
        </Row>
    )
}