import React from 'react';
import { Input, Button } from 'antd';
import Script from 'react-load-script';

const handleScriptLoad = (props) => {
    // Declare Options For Autocomplete
    var options = {
      types: ['(regions)'],
    };

    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options,
    );

    // Fire Event when a suggested name is selected
    console.log(autocomplete)
    autocomplete.addListener('place_changed', () => handlePlaceSelect(props, autocomplete));
}

const handlePlaceSelect = (props, autocomplete) => {

    // Extract City From Address Object
    const addressObject = autocomplete.getPlace();
    const address = addressObject.address_components;

    // Check if address is valid
    if (address) {
      // Set State
      props.handleSearchSelect(address, addressObject);
    }
  }

// handle free text inside search box.
const handleChange = (e, props) => {
    props.handleSearchChange(e.target.value);
  }

export default (props) => {
	return (
			<div className="search-container">
				<Script 
					url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCZ_qJ9RUbIghGyHC_gWvRwq_EzFuyWgfg&libraries=places" 
					onLoad={() => handleScriptLoad(props)}
				/>
				<Input.Search id="autocomplete" className="search-box"
				  placeholder="Where do you want to fly?"
				  enterButton
				  value={props.mapState.query}
				  onChange={(e) => handleChange(e, props)}
				/>
				<span className="btn-container" >
					<Button type={props.mapState.map_btn} onClick={() => props.handleMapType(2)} >
						Map
					</Button>
					<Button type={props.mapState.sat_btn} onClick={() => props.handleMapType(1)} >
						Satellite
					</Button>
				</span>
			</div>
		)
}