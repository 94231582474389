import React from 'react';
import {
  Form, Icon, Input, Button, DatePicker, TimePicker, Select
} from 'antd';
import { message } from 'antd';
import rest from '../common/network';
import moment from 'moment';

let form_prop = null

const labelCol= {
	xs: { span: 8 }
}
const wrapperCol= {
	xs: { span: 14 }
}

const handleCompanyUpdate = (props, response) => {
	console.log("***")
	console.log(response);
	props.toggleAsyncButton();
	props.form.resetFields();
    if(response.data.success === true) {
        message.success(response.data.message);
        props.updateSOPData(response.data.sopData);
    }
    else
        message.error(response.data.message);
}

const handleOnSubmit = (e, props) => {
	e.preventDefault();
	form_prop.validateFields((err, values) => {
		console.log(values);
		if(!err) {
			let headers = {};
            props.toggleAsyncButton();
			const expiry = values["sop-expiry"];
            const data = {
            	sop_operator_name: values["sop-name"],
                sop_permission_number: values["sop-number"],
                sop_expiry_date: `${expiry.date()}-${expiry.month()+1}-${expiry.year()}`,
                type: "sop"
            };
            rest({
                method: 'post',
                endpoint: '/user/edit',
                headers: headers,
                data: data,
                response_type: "",
                success: handleCompanyUpdate,
                props: props,
                error: props.handleRequestError
            });
		}
	});
}

const comparePassword = (rule, value, callback) => {
    if (value && value !== form_prop.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

const fields = (item, getFieldDecorator) => {
	if(item.type === "date") {
		return (
			<Form.Item key={item.id} labelCol={labelCol} wrapperCol={wrapperCol} label={item.label} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							required: item.required,
							message: item.req_msg
						}]
				})(
				<DatePicker type={item.type} placeholder={item.placeholder} style={{width: "100%"}} />
				)}
			</Form.Item>
		);
	}
	else if(item.type === "time") {
		return (
			<Form.Item key={item.id} labelCol={labelCol} wrapperCol={wrapperCol} label={item.label} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							pattern: item.val_pattern,
							message: item.val_msg
						},
						{
							required: item.required,
							message: item.req_msg
						},
						{
							validator: item.id==="confirm-password" ? comparePassword : ""
						}]
				})(
				<TimePicker type={item.type} placeholder={item.placeholder} />
				)}
			</Form.Item>
		);
	}
	else if(item.type === "select") {
		return (
			<Form.Item key={item.id} labelCol={labelCol} wrapperCol={wrapperCol} label={item.label} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							pattern: item.val_pattern,
							message: item.val_msg
						},
						{
							required: item.required,
							message: item.req_msg
						},
						{
							validator: item.id==="confirm-password" ? comparePassword : ""
						}]
				})(
				<Select style={{ width: 120 }} >
			      	<Select.Option value="jack">Jack</Select.Option>
			      	<Select.Option value="lucy">Lucy</Select.Option>
			      	<Select.Option value="disabled" disabled>Disabled</Select.Option>
			      	<Select.Option value="Yiminghe">yiminghe</Select.Option>
			    </Select>
			    )}
			</Form.Item>
		);
	}
	else {
		return (
			<Form.Item key={item.id} labelCol={labelCol} wrapperCol={wrapperCol} label={item.label} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							pattern: item.val_pattern,
							message: item.val_msg
						},
						{
							required: item.required,
							message: item.req_msg
						},
						{
							validator: item.id==="confirm-password" ? comparePassword : ""
						}]
				})(
				<Input prefix={item.icon ? <Icon type={item.icon} style={{ color: 'rgba(0,0,0,.25)' }} /> : ""} type={item.type} placeholder={item.placeholder} />
				)}
			</Form.Item>
		);
	}
}

const company_form = (props) => {
	const { getFieldDecorator } = props.form;
    form_prop = props.form
	return (
        	<Form 
        		id={props.data.edit_drones_id} 
        		className={props.data.class} 
        		layout={props.data.layout}
        		onSubmit={(e) => handleOnSubmit(e, props)}
        	>
        		{
        			props.data.fields.map((item) => {
        				return fields(item, getFieldDecorator)			
        			})
        		}
                <Form.Item wrapperCol={{ span: 20, offset: 3 }} style={{textAlign:"center"}} >
		          	<Button type="primary" htmlType="submit" loading={props.confirmLoading} >
		          		Update
                    </Button>
		        </Form.Item>
      		</Form>
		);
}

export default Form.create({
	mapPropsToFields: (props) => ({
		"sop-name": Form.createFormField({
			...props.data.sop_name_value,
			value: props.data.sop_name_value
        }),
        "sop-number": Form.createFormField({
			...props.data.sop_number_value,
			value: props.data.sop_number_value
		}),
        "sop-expiry": Form.createFormField({
			...props.data.sop_expiry_value,
			value: props.data.sop_expiry_value
		})
	})
})(company_form);