//export const SATELLITE = "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY3Jvb3pldXN5YWhvbyIsImEiOiJjank4bWY4cjYwNXRjM25wbnQ4OW1iYmRjIn0.fYKeVkHk1HialeUavsdQ6w"
//export const MAP = "https://api.mapbox.com/styles/v1/croozeusyahoo/cjy8mjkst11aj1cpgyyggusof/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY3Jvb3pldXN5YWhvbyIsImEiOiJjank4bWY4cjYwNXRjM25wbnQ4OW1iYmRjIn0.fYKeVkHk1HialeUavsdQ6w"

export const SATELLITE = "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibGVlaGFubmlnYW4iLCJhIjoiY2ppYmc1aXpyMDA2MDNxbzU0ODZ2eTN6dyJ9.GA--o_dveREkGenhNLhWoA"
export const MAP = "https://api.mapbox.com/styles/v1/mapbox/outdoors-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibGVlaGFubmlnYW4iLCJhIjoiY2ppYmc1aXpyMDA2MDNxbzU0ODZ2eTN6dyJ9.GA--o_dveREkGenhNLhWoA"


export const AT_EMAIL = {
	"dublin cork shannon": ["For Dublin, Cork and Shannon, Completed Form must be sent to:", "suaairspace@iaa.ie"],
	"sligo": ["For Sligo Airport (EISG) Completed Form must be sent to:", "safetymanager@sligoairport.com"],
	"kerry": ["For Kerry Airport (EIKY) Completed Form must be sent to:", "atc@kerryairport.ie"],
	"waterford": ["For Waterford Airport (EIWF) Completed Form must be sent to:", "sua@waterfordairport.net"],
	"donegal": ["For Donegal Airport (EIDL) Completed Form must be sent to:", "sua@donegalairport.ie"],
	"knock": ["For Ireland West Airport (EIKN) Completed Form must be sent to:", "michaelconnolly@irelandwestairport.com"]
}
