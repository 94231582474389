import React from 'react';
import {Row, Col, Icon, Table, Checkbox, Divider} from 'antd';
import './flights.scss';
import rest from '../common/network';

const getFlightList = (props) => {
    if(props.my_flights.flight_list.length === 0)
        return(
            <div className="no-flights">You have no saved flights. Start adding flights in <a href="" >home page</a>.</div>
        );

    const columns = [{
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },{
      title: 'Date',
      dataIndex: 'date',
      key: 'date'
    },{
      title: 'Pilot name',
      dataIndex: 'pilot_name',
      key: 'pilot_name'
    },{
      title: 'Drone type',
      dataIndex: 'drone_type',
      key: 'drone_type'
    },{
      title: 'Risk assesment complete',
      dataIndex: 'risk_assesment',
      key: 'risk_assesment',
      width: 100,
      render: ({val, index, props}) => (
            <Checkbox onChange={(e) => props.handleCheckBox(e, index, "risk_assesment")} checked={val} ></Checkbox>
        )
    },{
      title: 'Sent to ATC',
      dataIndex: 'sent_to',
      key: 'sent_to',
      width: 100,
      render: ({val, index, props}) => (
            <Checkbox onChange={(e) => props.handleCheckBox(e, index, "sent_to_atc")} checked={val} ></Checkbox>
        )
    },{
      title: 'Approved by ATC',
      dataIndex: 'approved_by',
      key: 'approved_by',
      width: 100,
      render: ({val, index, props}) => (
            <Checkbox onChange={(e) => props.handleCheckBox(e, index, "approved_by_atc")} checked={val} ></Checkbox>
        )
    },{
      title: 'Completed',
      dataIndex: 'completed',
      key: 'completed',
      width: 100,
      render: ({val, index, props}) => (
            <Checkbox onChange={(e) => props.handleCheckBox(e, index, "completed")} checked={val} ></Checkbox>
        )
    },{
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: ({index, props}) => (
        <span>
          <a href="javascript:;" onClick={() => props.editMyFlights(null, null, index)}>Edit</a>
          <Divider type="vertical" />
          <a href="javascript:;" onClick={() => props.deleteMyFlights(null, null, index)}>Delete</a>
        </span>
      ),
    }]

    const data = []
    props.my_flights.flight_list.map((item, index) => {
        data.push({
            key: index,
            name: item.flight_name,
            date: item.date_from,
            pilot_name: item.pilot,
            drone_type: item.drone,
            risk_assesment: {val:item.risk_assesment, index, props},
            sent_to: {val:item.sent_to_atc, index, props},
            approved_by: {val:item.approved_by_atc, index, props},
            completed: {val:item.completed, index, props},
            action: {index: item.key, props}
        })
    });
    return(
        <Col className="form" xs={{span: 26}} >
            <Table columns={columns} dataSource={data} />
        </Col>
    );
}

export default(props) => {
    return (
        <Row id="my-flights">
            <Col xs={{span: 23, offset: 1}} md={{span: 20, offset: 3}} style={{padding: "0px 0px 50px 0px"}} >
                <div className="page-title" >
                    MY FLIGHTS
                </div>
                <hr style={{borderTop: "1px solid #8080806e", borderLeft: "none"}} />
                {getFlightList(props)}
            </Col>
        </Row>
    )
}