import React from 'react';
import {
  Form, Icon, Input, Button, DatePicker, TimePicker, Select
} from 'antd';
import { message } from 'antd';
import rest from '../common/network';

let form_prop = null

const handleDroneEdit = (props, response) => {
	props.toggleAsyncButton();
	props.form.resetFields();
    if(response.data.success === true) {
        message.success(response.data.message);
        props.close();
        props.updateDroneList(response.data.drone_data);
    }
    else
        message.error(response.data.message);
}

const handleOnSubmit = (e, props) => {
	e.preventDefault();
	form_prop.validateFields((err, values) => {
		console.log(values);
		if(!err) {
			let headers = {};
            props.toggleAsyncButton();
            const data = {
            	drone_key: props.data.drone_key_value,
                drone_name: values["drone-name"],
                drone_id: values["drone-id"],
            };
            rest({
                method: 'post',
                endpoint: '/flight/drone/edit',
                headers: headers,
                data: data,
                response_type: "",
                success: handleDroneEdit,
                props: props,
                error: props.handleRequestError
            });
		}
	});
}

const comparePassword = (rule, value, callback) => {
    if (value && value !== form_prop.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

const fields = (item, getFieldDecorator) => {
	if(item.type === "date") {
		return (
			<Form.Item key={item.id} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							pattern: item.val_pattern,
							message: item.val_msg
						},
						{
							required: item.required,
							message: item.req_msg
						},
						{
							validator: item.id==="confirm-password" ? comparePassword : ""
						}]
				})(
				<DatePicker type={item.type} placeholder={item.placeholder} />
				)}
			</Form.Item>
		);
	}
	else if(item.type === "time") {
		return (
			<Form.Item key={item.id} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							pattern: item.val_pattern,
							message: item.val_msg
						},
						{
							required: item.required,
							message: item.req_msg
						},
						{
							validator: item.id==="confirm-password" ? comparePassword : ""
						}]
				})(
				<TimePicker type={item.type} placeholder={item.placeholder} />
				)}
			</Form.Item>
		);
	}
	else if(item.type === "select") {
		return (
			<Form.Item key={item.id} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							pattern: item.val_pattern,
							message: item.val_msg
						},
						{
							required: item.required,
							message: item.req_msg
						},
						{
							validator: item.id==="confirm-password" ? comparePassword : ""
						}]
				})(
				<Select style={{ width: 120 }} >
			      	<Select.Option value="jack">Jack</Select.Option>
			      	<Select.Option value="lucy">Lucy</Select.Option>
			      	<Select.Option value="disabled" disabled>Disabled</Select.Option>
			      	<Select.Option value="Yiminghe">yiminghe</Select.Option>
			    </Select>
			    )}
			</Form.Item>
		);
	}
	else {
		return (
			<Form.Item key={item.id} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							pattern: item.val_pattern,
							message: item.val_msg
						},
						{
							required: item.required,
							message: item.req_msg
						},
						{
							validator: item.id==="confirm-password" ? comparePassword : ""
						}]
				})(
				<Input prefix={item.icon ? <Icon type={item.icon} style={{ color: 'rgba(0,0,0,.25)' }} /> : ""} type={item.type} placeholder={item.placeholder} />
				)}
			</Form.Item>
		);
	}
}

const drone_form = (props) => {
	const { getFieldDecorator } = props.form;
    form_prop = props.form
	return (
        	<Form 
        		id={props.data.edit_drones_id} 
        		className={props.data.class} 
        		layout={props.data.layout}
        		onSubmit={(e) => handleOnSubmit(e, props)}
        	>
        		{
        			props.data.fields.map((item) => {
        				return fields(item, getFieldDecorator)			
        			})
        		}
	        	
      		</Form>
		);
}

export default Form.create({
	mapPropsToFields: (props) => ({
		"drone-name": Form.createFormField({
			...props.data.drone_name_value,
			value: props.data.drone_name_value
        }),
        "drone-id": Form.createFormField({
			...props.data.drone_id_value,
			value: props.data.drone_id_value
		})
	})
})(drone_form);