import React from 'react';
import {
  Form, Icon, Input, Button, DatePicker, TimePicker, Select
} from 'antd';
import { message } from 'antd';
import rest from '../common/network';

let form_prop = null

const loginSuccess = (props, response) => {
	if(response.data.success === true) {
		localStorage.setItem('auth', response.config.headers.Authorization);
		message.success(response.data.message);
		props.close();
		props.toggleMenu(true, response.data.loginData.username);
	}
	else {
		// localStorage.setItem('auth', null);
		message.error(response.data.message);
	}
}

const signupSuccess = (props, response) => {
    if(response.data.success === true) {
		// localStorage.setItem('login', true);
		localStorage.setItem('auth', response.config.headers.Authorization);
        message.success(response.data.message);
		props.close();
		props.toggleMenu(true, response.data.signupData.username);
    }
    else {
		// localStorage.setItem('login', false);
		message.error(response.data.message);
	}
}

const forgotPasswordSuccess = (props, response) => {
	if(response.data.success === true) {
		message.success(response.data.message);
		props.close();
	}
	else {
		message.error(response.data.message);
	}
}

const handlePilotAdd = (props, response) => {
	props.toggleAsyncButton();
	props.form.resetFields();
    if(response.data.success === true) {
        message.success(response.data.message);
        props.close();
        props.updatePilotList(response.data.pilot_data);
    }
    else
        message.error(response.data.message);
}

const handleDroneAdd = (props, response) => {
	props.toggleAsyncButton();
	props.form.resetFields();
    if(response.data.success === true) {
        message.success(response.data.message);
        props.close();
        props.updateDroneList(response.data.drone_data);
    }
    else
        message.error(response.data.message);
}

const handleOnSubmit = (e, props) => {
	e.preventDefault();
	// const origin = window.location.origin;
	// const origin = "http://192.168.1.9:8000";
	form_prop.validateFields((err, values) => {
		
		if(!err) {
			let headers = {};
			if(props.data.id=== "login") {
				const auth = "Basic "+btoa(`${values['email']}:${values['password']}`);
				headers = {
					Authorization: auth
				};
				const data = {};
				rest({
					method: 'get',
					endpoint: '/user/login',
					headers: headers,
					data: data,
					response_type: "",
					success: loginSuccess,
					props: props,
					error: props.handleRequestError
				});
			}
			else if(props.data.id === "signup") {
				const auth = "Basic "+btoa(`${values['email']}:${values['password']}`);
				headers = {
					Authorization: auth
				};
                const data = {
                    first_name: values['first-name'],
                    last_name: values['last-name']
                };
                rest({
					method: 'post',
					endpoint: '/user/signup',
					headers: headers,
					data: data,
					response_type: "",
					success: signupSuccess,
					props: props,
					error: props.handleRequestError
				});
			}
			else if(props.data.id === "reset") {
				const data = {
					email: values['email']
				};
				rest({
					method: 'get',
					endpoint: '/user/forgot',
					headers: headers,
					data: data,
					response_type: "",
					success: forgotPasswordSuccess,
					props: props,
					error: props.handleRequestError
				});
			}
			else if(props.data.id === "pilots") {
				props.toggleAsyncButton();
				const date_pcc = values["pcc"];
				const data = {
					pilot_name: values["pilot-name"],
					phone1: values["phone1"],
					phone2: values["phone2"],
					pcc: `${date_pcc.date()}-${date_pcc.month()+1}-${date_pcc.year()}`,
				}
				rest({
					method: 'post',
					endpoint: '/flight/pilot/add',
					headers: headers,
					data: data,
					response_type: "",
					success: handlePilotAdd,
					props: props,
					error: props.handleRequestError
				});
			}
			else if(props.data.id === "drones") {
				props.toggleAsyncButton();
				const data = {
					drone_name: values["drone-name"],
					drone_id: values["drone-id"],
				};
				rest({
					method: 'post',
					endpoint: '/flight/drone/add',
					headers: headers,
					data: data,
					response_type: "",
					success: handleDroneAdd,
					props: props,
					error: props.handleRequestError
				});
			}
		}
	});
}

const comparePassword = (rule, value, callback) => {
    if (value && value !== form_prop.getFieldValue('password')) {
      callback('Both passwords should match.');
    } else {
      callback();
    }
  }

const formFooter = (props) => {
	if(props.data.id === "login") {
		return (
				<Form.Item>
					New user? <a onClick={props.signup} >register now!</a>
					<a className="login-form-forgot" onClick={props.reset} >Forgot password</a>
						<Button type="primary" htmlType="submit" className="login-form-button">
							Log in
						</Button>
				</Form.Item>
			)
	}
	else if(props.data.id === "signup") {
		return (
				<Form.Item>
					Already a memeber? <a className="already-member" onClick={props.login}>Log in</a>
						<Button type="primary" htmlType="submit" className="signup-form-button">
							Register
						</Button>
				</Form.Item>
			)
	}
	else if(props.data.id === "reset") {
		return (
			<Form.Item>
				Remember password? <a className="already-member" onClick={props.login}>Log in</a>
                <Button type="primary" htmlType="submit" className="reset-form-button">
                    Send
                </Button>
                {/* <Button type="primary" onClick={props.login} className="login-form-button">
                    Login
                </Button> */}
			</Form.Item>
		)
	}
}

const fields = (item, getFieldDecorator) => {
	if(item.type === "date") {
		return (
			<Form.Item key={item.id} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [
						{
							required: item.required,
							message: item.req_msg
						}]
				})(
				<DatePicker type={item.type} placeholder={item.placeholder} style={{"width": "100%"}} />
				)}
			</Form.Item>
		);
	}
	else if(item.type === "time") {
		return (
			<Form.Item key={item.id} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							pattern: item.val_pattern,
							message: item.val_msg
						},
						{
							required: item.required,
							message: item.req_msg
						},
						{
							validator: item.id==="confirm-password" ? comparePassword : ""
						}]
				})(
				<TimePicker type={item.type} placeholder={item.placeholder} />
				)}
			</Form.Item>
		);
	}
	else if(item.type === "select") {
		return (
			<Form.Item key={item.id} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							pattern: item.val_pattern,
							message: item.val_msg
						},
						{
							required: item.required,
							message: item.req_msg
						},
						{
							validator: item.id==="confirm-password" ? comparePassword : ""
						}]
				})(
				<Select style={{ width: 120 }} >
			      	<Select.Option value="jack">Jack</Select.Option>
			      	<Select.Option value="lucy">Lucy</Select.Option>
			      	<Select.Option value="disabled" disabled>Disabled</Select.Option>
			      	<Select.Option value="Yiminghe">yiminghe</Select.Option>
			    </Select>
			    )}
			</Form.Item>
		);
	}
	else {
		return (
			<Form.Item key={item.id} hasFeedback >
				{getFieldDecorator(item.id, {
					rules: [{
							pattern: item.val_pattern,
							message: item.val_msg
						},
						{
							required: item.required,
							message: item.req_msg
						},
						{
							validator: item.id==="confirm-password" ? comparePassword : ""
						}]
				})(
				<Input prefix={item.icon ? <Icon type={item.icon} style={{ color: 'rgba(0,0,0,.25)' }} /> : ""} type={item.type} placeholder={item.placeholder} />
				)}
			</Form.Item>
		);
	}
}

const form = (props) => {
	const { getFieldDecorator } = props.form;
    form_prop = props.form
    
	return (
        	<Form 
        		id={props.data.id} 
        		className={props.data.class} 
        		layout={props.data.layout}
        		onSubmit={(e) => handleOnSubmit(e, props)}
        	>
        		{
        			props.data.fields.map((item) => {
        				return fields(item, getFieldDecorator)			
        			})
        		}
        		{formFooter(props)}
	        	
      		</Form>
		);
}

export default Form.create({})(form);
